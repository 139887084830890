import { Injectable } from '@angular/core';
import { LoginApi } from '../api/login.api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { NavService } from 'src/app/@theme/services/nav.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private loginApi: LoginApi) {}

  isLoggedIn() {
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'));
    return isLoggedIn ? true : false;
  }

  isOnboardingCompleted() {
    const user = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'USER'));
    if (!user) {
      return false;
    }
    // this.navServices.lockSidebar = !user.flags.onboardingCompleted;
    return user.flags.onboardingCompleted ? true : false;
  }

  loginViaPhone(data: any): Observable<any> {
    return this.loginApi.loginViaPhone(data);
  }

  loginViaEmail(data: any): Observable<any> {
    return this.loginApi.loginViaEmail(data);
  }

  informUserIsInactive(data: any): Observable<any> {
    return this.loginApi.informUserIsInactive(data);
  }

  getRedirectToPageObj() {
    const user = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'USER'));
    // const numBillTouched = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'numBillTouched')) || 0;
    let redirectToPage = { path: 'fb', queryParams: {} };
    if (user) {
      redirectToPage.path = 'fb';
      if (!user.dob || !user.email) {
        redirectToPage.queryParams = {
          page: 'profile'
        }; // not completed profile
      } else {
        redirectToPage.path = 'fb/dashboard';
        redirectToPage.queryParams = {}; // default
      }

      // if (parseInt(user.onboardingType) >= 100 && parseInt(user.onboardingType) <= 110) {
      //   // came from facebook campaigns
      //   redirectToPage.path = 'fb';
      //   if (!user.dob) {
      //     redirectToPage.queryParams = {
      //       page: 'profile'
      //     }; // not completed profile
      //   } else if (user.creditCard.exists) {
      //     redirectToPage.queryParams = {
      //       page: 'billLinkingSuccess'
      //     }; // given cc
      //   } else if (user.last4SSN || numBillTouched > 0) {
      //     redirectToPage.queryParams = {
      //       page: 'addCard'
      //     }; // given a bill but no cc
      //   } else {
      //     redirectToPage.queryParams = {
      //       page: 'profile'
      //     }; // default
      //   }
      // } else {
      //   redirectToPage.path = 'get-started';
      // }
    }
    return redirectToPage;
  }
}
