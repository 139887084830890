
 <app-header [isback]=true  [backtext]="" ></app-header>
<div style="z-index:0" class="wrapper h-100">
 
 
  <div class="header-navbar">
    <div class="container-large text-center position-relative">
    
      <h3>
        {{
        providerName
            | titlecase
        }}
        <!-- {{billStatus|json}} -->
      </h3>
    </div>
  </div>
  <div class="cut-off-bg"></div>
  <div class="main-content">
    <div
    *ngIf="
    (customer?.pricingPlanDetails?.planId > '1000' && customer?.pricingPlanDetails?.planId < '2000') &&
    billStatus &&
    !['negotiating', 'outOfSync'].includes(
      billStatus
    )
  "
      class="d-flex flex-column align-items-center"
    >
      <div class="segmented-control">
        <div
          class="option"
          [ngClass]="{ active: activeTab === 'billPay', hide: !billPayId }"
          (click)="selectTab('billPay')"
        >
          Bill Pay
        </div>
        <div
          class="option"
          [ngClass]="{ active: activeTab === 'savings',hide: showSaving }"
          (click)="selectTab('savings')"
        >
          Savings
        </div>
      </div>
    </div>

    <div class="bill-details-card-cont details-width-desktop">
      <div class="bill-details-card container-large">
        <div class="bill-details-card-bill">
          <img
            src="../../assets/images/fb/bill-types/{{
              billPayDetails?.billTypes[0] || 'cellphone'
            }}.svg"
            alt="bill"
          />
        </div>
        <div *ngIf="activeTab === 'billPay'">
          <div class="text-center">
            <div class="billpay-amount" *ngIf="billPayDetails?.billAmount">
              ${{ billPayDetails?.billAmount }}
            </div>

            <div class="billpay-due" *ngIf="billPayDetails?.billDueDate">
              {{ billStatus == 'success' ? 'Paid' : isDueFuture(billPayDetails?.billDueDate) ?  'Due On' : 'Last Due On' }}

              {{ billPayDetails?.billDueDate | date: 'mediumDate' }}
            </div>
          </div>

          <ng-container *ngIf="billStatus == 'failed'">
            <div class="text-center">
              <div class="billpay-due">
                <img
                  src="../../assets/images/fb/red_error.svg"
                  class="payment-sub-img"
                />
                Failed • {{ billPayDetails.createdAt | date: 'mediumDate' }}
              </div>
            </div>
          </ng-container>
          
          <div *ngIf="billStatus != 'outOfSync'">
            <div class="bill-msgs-cont my-4 mx-4">
              <div class="d-flex-c">
                <div class="security_msg_img">
                  <img
                    src="../../assets/images/fb/security.svg"
                    alt="security"
                  />
                </div>
                <div class="security_msg">
                  <b>Billpay: </b>The {{ billPayDetails.billTypes[0] }} bill is as expected
                </div>
              </div>
              <div class="dotted-line"></div>
              <div class="bill-status-msgs">
                <div class="d-flex align-items-start py-2">
                  <img src="../../assets/images/fb/thumbs_up.svg" />
                  <span class="pl-2">No hidden charges found</span>
                </div>
                <div class="d-flex align-items-start py-2">
                  <img src="../../assets/images/fb/thumbs_up.svg" />
                  <span class="pl-2">Discounts applied as expected</span>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center py-3 mx-3 border-line"
              *ngIf="billPayDetails?.achDetails?.bankName"
            >
              <div>Payment via</div>
              <div class="bill-spec-val">
                {{ billPayDetails?.achDetails?.bankName }} -
                {{ billPayDetails?.achDetails?.mask }}
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center py-3 mx-3"
              *ngIf="billPayDetails?.withdrawDate"
            >
              <div>Withdrawal on</div>
              <div class="bill-spec-val">
                {{ billPayDetails?.withdrawDate | date: 'mediumDate' }}
              </div>
            </div>

            <div
              *ngIf="billPayDetails.pdf"
              class="d-flex justify-content-between align-items-center py-3 mx-3 border-top hand"
              (click)="openPDF()"
              >

              <div class="d-flex">
                <div class="pr-2">
                  <img src="../../assets/images/fb/pdf.svg" />
                </div>
                <div>Bill.pdf</div>
              </div>
              <div>
                <img
                  class="chevron"
                  src="../../assets/images/fb/chevron_right.svg"
                />
              </div>
            </div>
          </div>

          <div class="reLinkBillCont" *ngIf="billStatus == 'outOfSync' ">
            <div class="d-flex">
              <div class="px-2">
                <img src="../../assets/images/alert_warning.svg" />
              </div>
              <div>
                <div class="card-ttl">bill is out of sync</div>
                <div>
                  We’re facing trouble fetching your
                  {{ billStatus }} latest bill.<br /><br /> We will not be
                  able to continue to pay, monitor and get you any savings
                  unless this is fixed. Please click below to re-link it!
                </div>
                <div class="relink-bil d-flex" (click)="openLinkBill()">
                  <div>Re-link bill</div>
                  <div>
                    <img
                      class="chevron"
                      src="../../assets/images/fb/chevron_right.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="activeTab === 'savings'">
        


          <div>
            <div class="text-center"  *ngIf="billStatus === 'negotiating' ">
              <div class="billpay-amount" *ngIf="billPayDetails?.billAmount">
                ${{ billPayDetails?.billAmount }}
              </div>
              
              <div class="billpay-due" *ngIf="billPayDetails?.billDueDate">
                {{billStatus == "success" ? 'Paid' :  'Due' }}
                 
                {{ billPayDetails?.billDueDate | date: 'mediumDate' }}
              
              </div>
    
            </div>
          </div>
        
        
          <ng-container
            *ngIf="billStatus !== 'negotiating' "
          >
            <div class="text-center" *ngIf="billPayDetails?.recentSaving">
              <div *ngIf="billPayDetails?.recentSaving?.length>0; else showz" class="billpay-amount">
                Total savings ${{
                  billPayDetails?.recentSaving?.totalSavings | number: '1.2-2' || 0
                }}
              </div>
              <ng-template #showz>
                <div class="billpay-amount">
                  Total savings $0
                </div>
              </ng-template>
              
              <div class="billpay-due">
                <img
                  src="../../assets/images/fb/check_green.svg"
                  class="payment-sub-img"
                />
                Last Attempted On •
                {{
                  billPayDetails?.recentSaving.createdAt | date: 'mediumDate'
                }}
              </div>
            </div>

            <div class="bill-msgs-cont my-4 mx-4">
              <div class="d-flex-c">
                <div class="security_msg_img">
                  <img
                    src="../../assets/images/fb/security.svg"
                    alt="security"
                  />
                </div>
                <div class="security_msg">BillPay Enabled</div>
              </div>
              <div class="dotted-line"></div>
              <div class="bill-status-msgs">
                <div class="d-flex align-items-start py-2">
                  <img src="../../assets/images/fb/thumbs_up.svg" />
                  <span class="pl-2">Protection against hidden charges.</span>
                </div>
                <div class="d-flex align-items-start py-2">
                  <img src="../../assets/images/fb/thumbs_up.svg" />
                  <span class="pl-2"
                    >We monitor if the discounts were applied as expected.</span
                  >
                </div>
              </div>
            </div>

          <div *ngIf="billPayDetails && billPayDetails?.recentSaving">
            <div class="d-flex j-e my-4">
              <div>
                <div
                  class="font-amount text-center"
                  *ngIf="billPayDetails?.recentSaving?.length>0"
                >
                  ${{
                    billPayDetails.recentSaving.oldBillAmount -
                      billPayDetails.recentSaving.newBillAmount
                      | number: '1.2-2'
                  }}
                </div>
                <div class="negotiation-lbl">Monthly savings</div>
              </div>
              <div *ngIf="billPayDetails?.recentSaving.checkBackDate">
                <div class="font-amount text-center">
                  {{
                    billPayDetails?.recentSaving.checkBackDate
                      | date: 'mediumDate'
                  }}
                </div>
                <div class="negotiation-lbl">Discount ends</div>
              </div>
            </div>
            <div
              class="d-flex j-c font-bold my-4 hand"
              (click)="openModal(savingsBreakdownModal)"
            >
              <div class="savings_brk">SAVINGS BREAKDOWN</div>
              <div>
                <img
                  class="chevron hand"
                  src="../../assets/images/fb/chevron_right.svg"
                />
              </div>
            </div>
            <div
              class="d-flex align-items-center my-3 mx-3"
              *ngIf="billPayDetails.nextPaymentDate"
            >
              <div>
                <div class="negotiationRemainder">
                  Next negotiation reminder
                </div>
                <div class="negotiationRemainder-sub">
                  We will reach out to you before
                  {{
                    billPayDetails?.recentSaving.checkBackDate
                      | date: 'mediumDate'
                  }}
                </div>
              </div>
              <!-- <div class="negotiationRemainder-switch ml-auto">
                <label class="switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="emails"
                    id="negotiationRemainder"
                    [(ngModel)]="negotiationRemainderInp"
                  />
                  <span class="slider round hand"></span>
                </label>
              </div> -->
            </div>
            <!-- <div
            *ngIf="billPayDetails.pdf"
              class="d-flex justify-content-between align-items-center py-3 mx-3"
              (click)="openPDF()"
            >
              <div class="d-flex invoice-pdf">
                <div class="pr-2">
                  <img src="../../assets/images/fb/pdf.svg" />
                </div>
                <div>BillTrim Invoice</div>
              </div>
              <div><img src="../../assets/images/fb/chevron_right.svg" /></div>
            </div> -->
          </div>

        </ng-container>






          <div >
            <div
              class="negotiator-block d-flex align-items-center"
              *ngIf="billStatus === 'negotiating'"
            >
              <div class="d-flex justify-content-center">
                <img src="../../assets/images/fb/negotiator.svg" />
              </div>
              <div class="status-card-title">Negotiator assigned</div>
            </div>
            <div *ngIf="bill?.keyStatus === 'onBestDeal'">
              <h3 class="status-card-title">You are on the best plan</h3>
              <p class="mx-2 text-center">
                Alex and her team tried their best to get you savings and found
                you bill was already on best deal.
              </p>
              <hr />
            </div>

            <div *ngIf="bill?.keyStatus === 'customerError'">
              <div class="my-3 p-3">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <h6>Customer Error</h6>
                    <p class="mb-0">
                      {{ bill?.currentStatus }}
                    </p>
                  </div>
                  <div class="ml-auto">
                    <img src="../../assets/images/fb/pin_incorrect.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="bill?.keyStatus === 'inCorrectPin_old'">
              <div class="px-3">
                <div class="status-card-title">PIN is incorrect</div>
                <div class="justify-content-center text-center my-4">
                  <button
                    class="btn btn-primary mx-auto text-uppercase"
                    (click)="updatePin()"
                  >
                    Update your PIN
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <div *ngIf="bill?.keyStatus === 'inCorrectPin'">
              <div class="my-3 p-3">
                <div
                  class="d-flex align-items-center justify-content-between mb-4"
                >
                  <div>
                    <h6>Incorrect PIN</h6>
                    <p class="mb-0">
                      We could not speak to
                      {{ bill?.providerName || 'your provider' }} because the
                      PIN was incorrect.
                    </p>
                  </div>
                  <div class="ml-auto">
                    <img src="../../assets/images/fb/pin_incorrect.svg" />
                  </div>
                </div>
                <div class="justify-content-center text-center">
                  <button
                    class="btn btn-primary mx-auto text-uppercase"
                    (click)="updatePin()"
                  >
                    Update your PIN
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <div *ngIf="bill?.keyStatus === 'invalidCreds'">
              <div class="my-3 p-3">
                <div
                  class="d-flex align-items-center justify-content-between mb-4"
                >
                  <div>
                    <h6>Finish linking your bill</h6>
                    <p class="mb-0">
                      Additional verification is required to auto-fetch your
                      monitor your bills.
                    </p>
                  </div>
                  <div class="ml-auto">
                    <img src="../../assets/images/fb/pin_incorrect.svg" />
                  </div>
                </div>
                <div class="justify-content-center text-center">
                  <button
                    class="btn btn-primary mx-auto text-uppercase"
                    (click)="updatePin()"
                  >
                    AUTHENTICATE
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <div *ngIf="bill?.keyStatus === 'needCustomer2FA'">
              <div class="my-3 p-3">
                <div
                  class="d-flex align-items-center justify-content-between mb-4"
                >
                  <div>
                    <h6>Credentials outdated</h6>
                    <p class="mb-0">
                      Your {{ bill?.providerName || '' }} bill is out of sync as
                      the existing credentials do not work.
                    </p>
                  </div>
                  <div class="ml-auto">
                    <img src="../../assets/images/fb/pin_incorrect.svg" />
                  </div>
                </div>
                <div class="justify-content-center text-center">
                  <button
                    class="btn btn-primary mx-auto text-uppercase"
                    (click)="updatePin()"
                  >
                    UPDATE YOUR CREDENTIALS
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-large">
      <div
        class="my-3 bill-details-card-s p-3"
        *ngIf="activeTab === 'savings' && billStatus == 'negotiating'"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h6>Satisfaction Pledge</h6>
            <p class="mb-0">
              We’re provide you 5-star service. We don’t make a dime unless
              you’re happy.
            </p>
          </div>
          <div class="ml-auto">
            <img src="../../assets/images/satisfaction.svg" />
          </div>
        </div>
      </div>
      <div
        class="my-3 bill-details-card-s p-3"
        *ngIf="activeTab === 'savings' && billStatus == 'negotiating'"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h6>More bills, more savings</h6>
            <p>Customers with 3-4 bills save $540 on an average.</p>
          </div>
          <div class="ml-auto">
            <img
              src="../../assets/images/bill_stack.svg"
              class="rounded-circle"
            />
          </div>
        </div>
        <button
          class="btn btn-primary mx-auto my-2"
          (click)="openLinkBill('more')"
        >
          Link More Bills
        </button>
      </div>
    </div>
  </div>

  <!-- <div
    class="previous-payments"
    *ngIf="activeTab === 'billPay' && billPayDetails?.previousPayments?.length"
  > -->
  <div
      class="previous-payments"
      *ngIf="billPayDetails?.previousPayments?.length"
    >
    <div class="previous-width">
      <div class="previous-payments-title my-3">Previous payments</div>
      <div class="bills-listing mb-2">
        <div
          class="bills-item item-border"
          *ngFor="
            let payment of billPayDetails?.previousPayments;
            let i = index
          "
          [ngClass]="{
            'border-line': billPayDetails?.previousPayments?.length > i + 1
          }"
        >
          <div class="bills-content-wp">
            <div class="bill-list-item-content-name">
              <h3>{{ getMonthName(payment.dueDate) }}</h3>
            </div>
            <div class="bill-list-item-content-due d-flex align-items-center">
              <div class="payment-sub d-flex align-items-center mr-1">
                <img
                  src="../../assets/images/fb/check_green.svg"
                  class="payment-sub-img"
                />
                Paid
              </div>
              • {{ payment.createdAt | date: 'short' }}
            </div>
          </div>
          <div class="bill-list-item-amount font-amount">
            ${{ payment.dueAmount }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    class="previous-negotiations details-width-desktop"
    *ngIf="activeTab === 'savings' && bill?.savings?.length"
  >
    <div class="previous-payments-title my-3">Previous negotiations</div>
    <div class="mx-2 previous-payments-body">
      <div class="d-flex j-b" *ngFor="let saving of bill?.savings">
        <div class="d-flex-c">
          {{ bill?.savings[0].createdAt | date: 'mediumDate' }}
        </div>
        <div class="d-flex-c">
          <div>
            <div class="font-amount">
              ${{ saving.totalSavings | number: '1.2-2' }}
            </div>
            <div class="amount-sub">Savings</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="container-large" *ngIf="activeTab === 'billPay'">
    <div
      class="my-3 bill-details-card-s p-3"
      *ngIf="activeTab === 'savings' && !billPayId"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div class="mr-auto">
          <img
            src="../../assets/images/bill_stack.svg"
            class="rounded-circle"
          />

          <h6>Having any trouble</h6>
          <p>I am just a text away</p>
        </div>
      </div>
      <a class="btn btn-default mx-auto my-2" href="sms:1234&body=hi"
        >Text Me</a
      >
    </div>
  </div>
</div>

<ng-template #savingsBreakdownModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Savings Breakdown</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row savingsInfo">
      <div class="col-12 text-center p-0 px-4" *ngIf="billPayDetails?.recentSaving">
        <!-- <div class="d-flex savingItem">
          <div class="flex-fill text-left">Discount type</div>
          <div class="b-bold">line discount</div>
        </div> -->

        <div class="d-flex savingItem">
          <div class="flex-fill text-left">Discount/month</div>
          <div class="b-bold">
            ${{
              totdiscmon
             | number: '1.2-2'
            }}
          </div>
        </div>
        <div class="d-flex savingItem">
          <div class="flex-fill text-left">
            Total discount (${{
              billPayDetails.recentSaving.oldBillAmount -
              billPayDetails.recentSaving.newBillAmount  | number: '1.2-2'
            }}
            x
            {{ billPayDetails.recentSaving.contractTerm }} mo)
          </div>
          <div class="b-bold">
            ${{
            totdisc | number: '1.2-2'
            }}
          </div>
        </div>
        <div class="d-flex savingItem">
          <div class="flex-fill text-left">One-time courtesy credit</div>
          <div class="b-bold">
            ${{ billPayDetails.recentSaving.oneTimeCredit ? billPayDetails.recentSaving.oneTimeCredit : 0 | number: '1.2-2' }}
          </div>
        </div>
        <div class="d-flex savingItem">
          <div class="flex-fill text-left">Total savings</div>
          <div class="b-bold">
            ${{ billPayDetails.recentSaving.totalSavings ?billPayDetails.recentSaving.totalSavings :0 | number: '1.2-2' }}
          </div>
        </div>
        <div class="d-flex savingItem">
          <div class="flex-fill text-left">Date effective</div>
          <div class="b-bold">Next Billing Cycle</div>
        </div>
        <div class="d-flex savingItem">
          <div class="flex-fill text-left">BillTrim fee</div>
          <div class="b-bold">
            ${{ billPayDetails.recentSaving.totalSavings / 4 ?billPayDetails.recentSaving.totalSavings / 4:0 | number: '1.2-2' }}
          </div>
        </div>
        <!-- <div class="d-flex savingItem">
          <div class="flex-fill text-left">AT&T Rep</div>
          <div class="b-bold">Matteo</div>
        </div> -->
      </div>
      <div class="mt-3" *ngIf="!billPayDetails.recentSaving">
        <div class="text-left">
          Unable to show the savings breakdown right now! Please try again later
        </div>
      </div>

      <div class="mt-3" *ngIf="bill?.communicationMsg">
        <div class="comMsgTitle caps">Communication message</div>
        <div class="text-left comMsg">
          Spoke to Matteo from the Loyalty Department at AT&T. He approved a $5/
          line discount for 10 voice lines and decreased the price from $582.36
          to $532.36 on the base plan for the next 6 months and a $30 one time
          courtesy credit. Savings: $50 x 6 months + $30 = $330. No changes to
          the plan. Effective Date: Next Billing Cycle
        </div>
      </div>
    </div>
  </div>
</ng-template>

<footer *ngIf="showfooter"
 class="page-footer-rel font-small blue">
 <div  class="footer-copyright text-center py-3"> 
   Made with <img 
   src="../../assets/images/fb/heart.svg" style="height: 24px;"> in California </div></footer>