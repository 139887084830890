import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStore } from '../@core/stores/user.store';
import { BillsStore } from '../@core/stores/bills.store';
import { BillsService } from '../@core/backend/services/bills.service';
import { UsersService } from '../@core/backend/services/users.service';
import { AppApi } from '../apis/app.api';
import { AppStore } from '../stores/app.store';
import { HelperService } from '../@core/backend/services/helper.service';
import { billsConfig } from '../@config/page';
import { AppService } from '../app.service';
declare function initializeSDK(argObj:any)

@Component({
  selector: 'app-dashboard-v2',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  customer: any;
  accounts: any;
  queryParams: any = {};

  billpayData: any = [];
  upcomingPayments: any = [];
  linkedPayments: any = [];
  
  billPayAccounts: any = [];
  linkedAccounts: any = [];
  showRateThankYouMsg = false;
  showFeedbackReasonInput = false;
  givenRating: any = '';
  customerFeedBackReason = '';
  billsConfig: any = {};
  referralStats = {
    earnedAmount: 10, //default
  };

  providerList:any = [];
  showLowerBills : boolean = true;
  shownotification:boolean = false;
  showFeedbackPanel:boolean = true;
  showDash:boolean = false;
notificArr=[];
  constructor(
    private userStore: UserStore,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private router: Router,
    private billsService: BillsService,
    private billsStore: BillsStore,
    private appApi: AppApi,
    public helperService: HelperService,
    public appService: AppService,
    public appStore: AppStore
  ) {

    this.customer = this.userStore.getUser();
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'));

     if(this.appStore.getStorageState("FEEDBACK") === "1")
     {
       this.showFeedbackPanel = false;
     }
    if (!this.customer ) {
      this.router.navigate(['/funnel/initial']);
      return;
    }

    if (this.customer && !isLoggedIn) {
      this.router.navigate(['/funnel/initial']);
      return;
    }
    this.billsConfig = billsConfig;

    //check if fb customer
    if (this.customer ) {
      if (
        this.customer.pricingPlan &&
        parseInt(this.customer.pricingPlan) < 1000
      ) {
        
        // bill pay customer sending to new dashboard
        this.router.navigate(['/fb/dashboard']);
        
      }
      else
      {
        this.showDash = true;
      }

    }
    ////// Added to chk feedback done 
   
 
    let list = this.appService.getProviders();
    this.providerList = list && Object.keys(list).filter(i => i!== "health" && i!== "Other").map(type=>{ return {type:type} }) ;    

  }
/// check condition to show notification
 


/////show notifications
  getUserNotification()
  {

    if(this.customer && !this.customer.dob)
    {
      this.shownotification = true;
      this.notificArr.push({msg: 'Date of birth is missing',link:'/profile'})
    }
    if(this.linkedPayments.length < 3)
    {
      this.shownotification = true;
      //this.router.navigateByUrl(`/funnel/bill-provider`);
      this.notificArr.push({msg: 'Must link at least three bills for us to get you significant savings',link:'/funnel/bill-provider'})
    }
    if(this.customer.creditCard.exists  && !this.customer.creditCard.valid)
     {
       this.shownotification = true;
     this.notificArr.push({msg: 'Your Card is not valid',link:'/profile'})
     }
  if((this.customer.pricingPlan=='' || !this.customer.billPayCustomer) 
      )
       {
        this.shownotification = true;
        this.notificArr.push({msg: 'Please add your bank details',link:'/funnel/bank-link'})
       }

       if(this.customer.pricingPlanDetails?.paymentMethod === 'ACH' &&
        this.customer.pricingPlanDetails?.paymentStatus === 'failed')
        {
          this.shownotification = true;
          this.notificArr.push({msg: 'Payment failed ',link:'/funnel/bank-link'})
     
        }
  }

  openLink(link)
  {
   
    if(link=='/funnel/bill-provider')
    {
     
      if((this.customer.pricingPlan=='' || !this.customer.billPayCustomer) 
      )
       {
        alert('kk')
        this.router.navigateByUrl(`/funnel/bank-link?pref=bank`);
        return;
      }
      else
      {
        this.appStore.removeBillState();
        this.openSDK()
         return;
      
      }
    }
    if(link=='/funnel/bank-link')
    {
      this.router.navigateByUrl(`/funnel/bank-link?pref=bank`);
      return;
    }
   else
       this.router.navigate([link]);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });

   
    if (this.customer ) {
    if (window) window.scrollTo(0, 0);
       this.getBillPayEnabledBills();
    this.getLinkedBills();
    this.getCustomerDetails();

 // this.getUsnerNotification();

    }
  }

  openSDK()
  {
    let that = this
    initializeSDK({
      custid: this.customer.id,
      clientid:
        "8061810322a562f9c6ccb42220dcc8530c2ff711d06f4c4095718421fae82433",
      issandbox: false,
      section: '',
      billtype: "",
      onClose: function(){ that.getLinkedBills();},
      onError : function(err){}
    });
  }

  openLinkType(type)
  {
    if (type === 'bills') {
      if((this.customer.pricingPlan=='' || !this.customer.billPayCustomer) 
      )
       {
       
        this.router.navigateByUrl(`/funnel/bank-link?pref=bank`);
        return;
      }
      else
       this.openSDK();
  }
  else if (type === 'billpay') {
    // this.router.navigateByUrl(`/funnel/bank-link?pref=dashboard`);
    this.router.navigateByUrl(`/funnel/pay-bills`);
  } else if (type === 'bank') {
    this.router.navigateByUrl('/fb?bank=1&page=selectBanks');
  }
  }

 


  getproviderlist()
  {
    let list = this.appService.getProviders();
    this.providerList = list && Object.keys(list).filter(i => i!== "health" && i!== "Other").map(type=>{ return {type:type} }) ;    

  }

  getLinkedBills() {
    const accountQuery = {
      customerId: this.customer.id,
      category: 'personal',
    };
    this.billsService.getAll(accountQuery).subscribe((resp) => {
      this.getSavings(this.customer.id);
      this.accounts = this.billsStore.getBills();
      this.linkedAccounts = this.accounts.filter(
        (i) => i.keyStatus !== 'unTouched'
      );
      console.log(this.linkedAccounts, 'linked');
     
      if(!this.providerList)
        this.getproviderlist();
      // NOTE: Hide both icons when 8 accounts are connected.
      // for (let linkedBill of this.linkedAccounts) {
      //   const acc = this.providerList.find(i => i.type === linkedBill.type);

      //   if (acc && linkedBill?.FGSJUSI.billFetched && (linkedBill?.FGSJUSI.auth_verified == 1 || linkedBill?.FGSJUSI.pin_verified == 1)) {
      //     linkedBill +=1
      //   }
      //   else{
      //     this.showLowerBills = true;

      //   }
      this.showLowerBills = true;

      });


   // });
  }

  submitRatings(ratings) {
    this.showFeedbackReasonInput = true;
    this.givenRating = Number(ratings);
    const params = {
      _id: this.customer.id,
      'ratings.ratings': Number(ratings),
    };
    this.submitFeedback(params);
  }

  submitReason() {
    if (!this.customerFeedBackReason) {
      this.showRateThankYouMsg = true;
      this.showFeedbackReasonInput = false;
      return;
    }
    this.showFeedbackReasonInput = false;
    this.showRateThankYouMsg = true;
    const params = {
      _id: this.customer.id,
      'ratings.reason': this.customerFeedBackReason,
    };
    this.submitFeedback(params);
  }

  submitFeedback(params) {
    this.usersService.update(params).subscribe(
      (response) => {
        console.log(response);
        this.customer = this.userStore.getUser();
        this.appStore.setStorageState("FEEDBACK","1");
      },
      (error) => {
        console.log('error in submitFeedback', error);
      }
    );
  }


  getBillPayEnabledBills() {
    if (!this.customer) return;
    this.appApi.getBillPayEnabledBills(this.customer.id).subscribe(
      (resp: any) => {
        console.log(resp, 'sdb');

        this.billpayData = resp.data.billpayData ? resp.data.billpayData : [];

        let todayDate = new Date();

        this.billpayData.forEach((billObj) => {
          if (
            billObj.billDueDate &&
            new Date(billObj.billDueDate).getTime() > todayDate.getTime()
          ) {
            this.upcomingPayments.push(billObj);
          }
          
          this.linkedPayments.push(billObj);
        });
        

        console.log('this.billpayData: ', this.billpayData);
        console.log('this.upcomingPayments: ', this.upcomingPayments);
        console.log('this.linkedPayments: ', this.linkedPayments); 
   this.getUserNotification();
        // this.billPayAccounts = resp.data.upcomingPayments;
        // this.billPayAccounts = resp.data.optedBills;
        // if (!this.billPayAccounts.length) {
        //   this.billPayAccounts = resp.data.linkedBills;
        // }
      },
      (error) => {
        this.getUserNotification();
      }
    );
  }

  getLastScrapperTime(billobj)
  {
   
    if(billobj.account?.lastScraperRun)
    {
    let todayDate = new Date();
    let scraperDate = billobj.account?.lastScraperRun;
    
    var difference = todayDate.getTime() - new Date(scraperDate).getTime(); // This will give difference in milliseconds
var resultInMinutes = Math.round(difference / 60000);

     if(resultInMinutes>30)
     { console.log('Trturning True', billobj.account.type);return true}

     else
     { console.log('Trturning False', billobj.account.type);return false}
    }
    else
    return false
  }

  openBillPayDetails(bill: any) {
    const billType =
      bill.billTypes && bill.billTypes.length ? bill.billTypes[0] : '';
    if (billType) {
      this.router.navigateByUrl('/bill-details/' + billType + '/' + bill._id);
    } else {
      this.router.navigateByUrl('/bill-details/' + bill.type);
    }
  }

  openBillDetails(bill: any) {
    this.router.navigateByUrl('/bill-details/' + bill.type);
  }

  getSavings(customerId: string) {
    this.appApi.getSavings({ customer_id: customerId }).subscribe(
      (response: any) => {
        console.log('getSavings', response);
        this.customer = this.appStore.getUser();
        this.accounts = this.appStore.getBills();
      },
      (error) => {
        console.log('Error in getSavings', error);
      }
    );
  }



  logout() {
    this.helperService.logout();

    console.log(this.appStore.customer);
    this.router.navigateByUrl('/funnel/login');
  }

  openSupport() {
    this.router.navigate(['/fb/faq']);
  }
  openSetting() {
    this.router.navigate(['/profile']);
  }

  //referFriend
  referFriend() {
    this.router.navigate(['/referral/referrerHome']);
  }

  openReferrals() {
    if (window) {
      window.scrollTo(0, 0);
    }
    this.router.navigate(['/fb/referrals']);
  }

  getCustomerDetails() {
    this.usersService.get(this.customer?.id).subscribe(
      (data: any) => {
        this.customer = data;
        // if (this.paymentMethodExists()) {
        //   this.router.navigate(['/funnel/overpaying-amount'], {
        //     queryParamsHandling: 'preserve',
        //   });
        // }
      },
      (error) => {
        console.log('getUserDetails', error);
      }
    );
    const accountQuery = {
      customerId: this.customer.id,
      category: 'personal',
    };
    this.billsService.getAll(accountQuery).subscribe((resp) => {
      this.getSavings(this.customer.id); // imp to call after above otherwise savings are getting overwritten
    });
  }

  socialShare(site) {
    if (site === 'fb') {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.billtrim.com?caption=Use%20my%20referral%20code%3A' +
          this.customer.referralCode,
        'facebook-share-dialog',
        'width=1200,height=600'
      );
    } else if (site === 'twitter') {
      window.open(
        'https://twitter.com/share?url=https://billtrim.com' +
          '&text=Use my referral code:' +
          this.customer.referralCode,
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=700'
      );
    } else if (site === 'linkedin') {
      window.open(
        'http://www.linkedin.com/shareArticle?mini=true&url=www.billtrim.com',
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=700'
      );
    }
  }

  public paymentMethodExists(): boolean {
    const customer = this.appStore.getUser();
    return (
      !customer?.creditCard?.exists && !customer?.pricingPlanDetails?.planId
    );
  }
}
