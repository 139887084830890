import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillsStore } from '../../@core/stores/bills.store';
import { AppApi } from '../../apis/app.api';

import { app } from '../../fb/config/app';
import { bills } from '../../fb/config/bills';
import {Location} from '@angular/common';

declare function pingPixel(str: string, pageObj?: any): any;

@Component({
  selector: 'app-savings-breakdown',
  templateUrl: './savings-breakdown.component.html',
  styleUrls: ['./savings-breakdown.component.scss']
})
export class SavingsBreakdownComponent implements OnInit {

  queryParams: any = {};
  billType: any;
  
  config = {
    app,
    bills
  };

  bill: any;

  billPayId: any;
  billPaySaving: any;

  constructor(
    private router: Router,
    private billsStore: BillsStore,
    private activatedRoute: ActivatedRoute,
    private appApi: AppApi,
    private _location: Location



  ) { 
   
  }

  ngOnInit(): void {

    if (window) window.scrollTo(0, 0);
    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (params.billPayId) this.billPayId = params.billPayId;
    });

    this.getBillPayDetails();

  }

  getBillPayDetails() {
   
    this.appApi.billPayDetails(this.billPayId).subscribe(
      (response: any) => {
        console.log('billPayDetails', response);
        this.billPaySaving =  response?.data?.recentSaving ? response.data.recentSaving : null;
        console.log(  this.billPaySaving,'gggg')
      },
      error => {
        console.log('Error in billPayDetails', error);
      }
    );
  }

  onBack(): void {

    if (window.history.length > 1) {
      this._location.back()
    } else {
      this.router.navigate(['/dashboard'])
    }

  }
}
