export const pageTextConfig = {
  login: {
    title: 'Hey! I’m Alex.<br /> What’s your cell phone number?',
    subText:
      'Guaranteed savings up to 25% on your cell phone, cable and internet bills.',
    btnInfo: 'We never call or share your personal information',
    custom: {
      privacyContent: `<div class="font-small">We Care About Your Privacy</div>
  
      By clicking "Continue" you agree to receive a 4-digit code to verify this phone number, updates about BillTrim products, the BillTrim
      <a target="_blank" class="green" href="https://billtrim.com/terms-of-service.html">Terms of Use</a> and
      <a target="_blank" class="green" href="https://billtrim.com/privacy-pledge.html">Privacy Policy</a>. Message frequency may vary. Message and data rates
      may apply.
      <br />
      Reply HELP for help or STOP to cancel`,
      asSeenText: 'Also seen on',
    },
    formElements: [
      [
        {
          type: 'tel',
          required: true,
          validators: { minLength: 10, maxLength: 10, category: 'phone' },
          id: 'login-phone',
          onEnter: '',
          class: 'btn btn-primary mx-auto',
        },
        {
          type: 'text',
          required: true,
          validators: { minLength: 3, maxLength: 30, category: 'email' },
          id: 'login-email',
          onEnter: 'submit',
          class: 'btn btn-primary mx-auto',
        },
      ],
      {
        type: 'radio',
        required: true,
        id: 'login-select',
        name: 'login-select-a',
        value: 'a',
        class: 'radio',
      },
      {
        type: 'radio',
        required: true,
        id: 'login-select',
        name: 'login-select-b',
        value: 'b',
        class: 'radio',
      },
      {
        type: 'radio',
        required: true,
        dynamic: true,
        multiple: true,
        fetchFrom: 'selectList',
        id: 'login-select',
        name: 'login-select-a',
        class: 'radio',
      },
      {
        type: 'checkbox',
        dynamic: true,
        multiple: true,
        fetchFrom: 'checkList',
        id: 'login-check',
        showIf: "#login-select.value==='b'",
        class: 'checkbox',
      },
    ],
    onCtaClick: 'verify',
  },
  verify: {
    title: 'Please enter the code sent to',
    subText:
      'Code from BillTrim is on it’s way. Can take up to 30 seconds to arrive.',
    custom: {
      trustText: `<span class="my-auto"> Trusted by <strong> 300,000+ </strong> BillTrimmers! </span>`,
    },
    onCtaClick: 'selectProvider',
  },
  selectProvider: {
    ctaBtnText: 'Confirm',
    skipBtnText: 'I don’t have internet',
    custom: {},
    onCtaClick: 'rememberPassword',
  },
  rememberPassword: {
    custom: {},
    onCtaClick: 'credentials',
  },
  credentials: {
    title: 'Enter your credentials',
    ctaBtnText: 'Submit',
    custom: {},
    onCtaClick: 'selectLine',
  },
  selectLine: {
    title: 'Where should we send you your verification code?',
    ctaBtnText: 'Next',
    custom: {},
    onCtaClick: 'securityQuestion',
  },
  securityQuestion: {
    title: 'Answer security questions',
    ctaBtnText: 'Next',
    custom: {},
    onCtaClick: 'sendOTP',
  },
  sendOTP: {
    title: 'Answer security questions',
    ctaBtnText: 'Submit',
    custom: {},
    onCtaClick: 'billLinkingDone',
  },
  billLinkingDone: {
    successTitle: 'Bill was linked',
    linkNextBill: 'Link Next Bill',
    custom: {
      billActions: [
        {
          title: 'Identity Theft + Late Fee Protection',
          subText:
            'Your payment method will not be disclosed and we will automatically pay your bill based on expected amount & due date every each month.',
        },
        {
          title: 'Automatic Monitoring',
          subText:
            'We will monitor this bill for any unusual changes and update you at every stage.',
        },
        {
          title: 'Overpayment Protection',
          subText:
            'We make sure you do not overpay compared to your neighbours and talk to your provider to get you lower rates at all times',
        },
      ],
    },
  },
};
export const billsConfig = {
  billsList: [
    'cellphone',
    'internet',
    'cable',
    'homeSecurity',
    'auto',
    'homePhone',
    'home',
    'electricity',
  ],
  cellphone: {
    type: 'cellphone',
    displayName: 'Cellphone',
    icon: '../assets/images/icons/icon-bill-cellphone.svg',
    visibleProviders: ['5af9a9019b1f40176c989a2e'], //AT&T
  },
  cable: {
    type: 'cable',
    displayName: 'Cable',
    icon: '../assets/images/icons/icon-bill-cable.svg',
    visibleProviders: [],
  },
  internet: {
    type: 'internet',
    displayName: 'Internet',
    icon: '../assets/images/icons/icon-bill-internet.svg',
    visibleProviders: ['5af9a9019b1f40176c989bc2', '5af9a9019b1f40176c989bce'], //Spectrum, Optimum
  },
  homePhone: {
    type: 'homePhone',
    displayName: 'Home Phone',
    icon: '../assets/images/icons/icon-bill-homePhone.svg',
    visibleProviders: [],
  },
  homeSecurity: {
    type: 'homeSecurity',
    displayName: 'Home Security',
    icon: '../assets/images/icons/icon-bill-homeSecurity.svg',
    visibleProviders: ['5af9a9019b1f40176c989b7a'], //ADT
  },
  auto: {
    type: 'auto',
    displayName: 'Auto Insurance',
    icon: '../assets/images/icons/icon-bill-auto.svg',
    visibleProviders: ['5af9a9019b1f40176c989b02', '5af9a9019b1f40176c989b0e'], //Progressive,liberty
  },
  home: {
    type: 'home',
    displayName: 'Home Insurance',
    icon: '../assets/images/icons/icon-bill-home.svg',
    visibleProviders: [],
  },
  electricity: {
    type: 'electricity',
    displayName: 'Electricity',
    icon: '../assets/images/icons/icon-bill-electricity.svg',
    visibleProviders: [],
  },
};

export const pageLinks = {
  funnel1: [
    'funnel/intro',
    'funnel/save-money',
    'funnel/bill-tracking',
    'funnel/verify-email',
    'funnel/login',
    'funnel/verify-otp',
    'funnel/initial',
    'funnel/do-have-bill',
    'funnel/income-range',
    'funnel/residential-status',
    'funnel/vehicle-status',
    'funnel/overpaying-amount',
    'funnel/bank-link',
    'funnel/bank-accounts',
    'funnel/review-details',
    'funnel/bill-linking',
    'funnel/bill-linking/select-provider',
    'funnel/bill-linking/remember-password',
    'funnel/bill-linking/credential',
    'funnel/bill-linking/linking-progress',
    'funnel/bill-linking/bill-linked',
    'funnel/complete-bill-linking',
    '/dashboard',
  ],
};
export const providerJSONKeys = {
  '5af9a9019b1f40176c989a2e': {
    //AT&T
    primaryJson: [
      {
        name: 'Account number',
        val: 'billSummary.accountNumber',
      },
      {
        name: 'Bill generation date',
        val: 'billSummary.billGenerateDate',
      },
      {
        name: 'Number of lines',
        val: 'billSummary.numberOfLines',
      },
      {
        name: 'Number of devices',
        val: 'billSummary.numberOfDevices',
      },
    ],
  },
  '5af9a9019b1f40176c989bc2': {
    //Spectrum
    primaryJson: [
      {
        name: 'Autopay Date',
        val: 'billSummary.autoPayDate',
        isDate: true,
      },
      {
        name: 'Services',
        val: 'billSummary.services.[0].service',
      },
      {
        name: 'Address',
        val: 'billSummary.billingAddress.addressLine1',
      },
    ],
  },
  '5af9a9019b1f40176c989bce': {
    //Optimum
    primaryJson: [
      {
        name: 'Bill generation sate',
        val: 'billSummary.billGenerateDate',
        isDate: true,
      },
    ],
  },
  '5af9a9019b1f40176c989b7a': {
    //ADT
    primaryJson: [
      {
        name: 'Address',
        val: 'billSummary.billingAddress.addressLine1',
      },
    ],
  },
  '5af9a9019b1f40176c989b02': {
    //progressive
    primaryJson: [
      {
        name: 'Bill amount',
        val: 'policySummary.0.monthlyPremium',
        isBillAmount: true,
      },
      {
        name: 'Bill due date',
        val: 'policySummary.0.billDueDate',
        isBillDueDate: true,
        isDate: true,
      },
      {
        name: 'Policy number',
        val: 'policySummary.policyNumber',
      },
      {
        name: 'Total premium',
        val: 'policySummary.totalPremium',
        isCurrency: true,
      },
      {
        name: 'Policy start date',
        val: 'policySummary.policyStartDate',
        isDate: true,
      },
      {
        name: 'Policy end date',
        val: 'policySummary.policyEndDate',
        isDate: true,
      },
      {
        name: 'Driver',
        val: 'driverInfo.0.driverName.firstName',
      },
      {
        name: 'Driver licence',
        val: 'driverInfo.0.driverLicence',
      },
      {
        name: 'Vehicle make',
        val: 'vehicleInfo.0.make',
      },
      {
        name: 'Vehicle model',
        val: 'vehicleInfo.0.model',
      },
      // {
      //   name: 'Vehicle parking zipcode',
      //   val: 'vehicleInfo.0.parkingZipCode'
      // }
    ],
  },
};

export const validFirstThreeDigits = [
  201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216,
  217, 218, 219, 220, 223, 224, 225, 226, 228, 229, 231, 234, 236, 239, 240,
  242, 246, 248, 249, 250, 251, 252, 253, 254, 256, 260, 262, 264, 267, 268,
  269, 270, 272, 276, 279, 281, 284, 289, 301, 302, 303, 304, 305, 306, 307,
  308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 323, 325,
  326, 330, 331, 332, 334, 336, 337, 339, 340, 341, 343, 345, 346, 347, 351,
  352, 360, 361, 364, 365, 367, 380, 385, 386, 401, 402, 403, 404, 405, 406,
  407, 408, 409, 410, 412, 413, 414, 415, 416, 417, 418, 419, 423, 424, 425,
  430, 431, 432, 434, 435, 437, 438, 440, 441, 442, 443, 445, 450, 456, 458,
  463, 469, 470, 473, 475, 478, 479, 480, 484, 500, 501, 502, 503, 504, 505,
  506, 507, 508, 509, 510, 512, 513, 514, 515, 516, 517, 518, 519, 520, 530,
  531, 533, 534, 539, 540, 541, 544, 548, 551, 559, 561, 562, 563, 564, 566,
  567, 570, 571, 573, 574, 575, 577, 579, 580, 581, 585, 586, 587, 600, 601,
  602, 603, 604, 605, 606, 607, 608, 609, 610, 612, 613, 614, 615, 616, 617,
  618, 619, 620, 623, 626, 628, 629, 630, 631, 636, 639, 640, 641, 646, 647,
  649, 650, 651, 657, 658, 659, 660, 661, 662, 664, 667, 669, 670, 671, 672,
  678, 680, 681, 682, 684, 689, 700, 701, 702, 703, 704, 705, 706, 707, 708,
  709, 710, 712, 713, 714, 715, 716, 717, 718, 719, 720, 721, 724, 725, 726,
  727, 731, 732, 734, 737, 740, 743, 747, 754, 757, 758, 760, 762, 763, 765,
  767, 769, 770, 772, 773, 774, 775, 778, 779, 780, 781, 782, 784, 785, 786,
  787, 800, 801, 802, 803, 804, 805, 806, 807, 808, 809, 810, 812, 813, 814,
  815, 816, 817, 818, 819, 820, 825, 828, 829, 830, 831, 832, 833, 838, 839,
  843, 844, 845, 847, 848, 849, 850, 854, 855, 856, 857, 858, 859, 860, 862,
  863, 864, 865, 866, 867, 868, 869, 870, 872, 873, 876, 877, 878, 888, 900,
  901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912, 913, 914, 915,
  916, 917, 918, 919, 920, 925, 928, 929, 930, 931, 934, 936, 937, 938, 939,
  940, 941, 947, 949, 951, 952, 954, 956, 959, 970, 971, 972, 973, 978, 979,
  980, 984, 985, 986, 989,
];

export const providerJSONKeysSmartCircle = {
  login: {
    title: 'Hey! I’m Alex.<br /> What’s your cell phone number?',
    subText:
      'Guaranteed savings up to 25% on your cell phone, cable and internet bills.',
    btnInfo: 'We never call or share your personal information',
    privacyContent: `<div class="font-small">We Care About Your Privacy</div>

    By clicking "Continue" you agree to receive a 4-digit code to verify this phone number, updates about BillTrim products, the BillTrim
    <a target="_blank" class="green" href="https://billtrim.com/terms-of-service.html">Terms of Use</a> and
    <a target="_blank" class="green" href="https://billtrim.com/privacy-pledge.html">Privacy Policy</a>. Message frequency may vary. Message and data rates
    may apply.
    <br />
    Reply HELP for help or STOP to cancel`,
    asSeenText: 'Also seen on',
  },
  verify: {
    title: 'Please enter the code sent to',
    subText:
      'Code from BillTrim is on it’s way. Can take up to 30 seconds to arrive.',
    trustText: `<span class="my-auto"> Trusted by <strong> 300,000+ </strong> BillTrimmers! </span>`,
  },
  selectProvider: {
    ctaBtnText: 'Confirm',
    skipBtnText: 'I don’t have internet',
  },
  rememberPassword: {},
  credentials: {
    title: 'Enter your credentials',
    ctaBtnText: 'Submit',
  },
  selectLine: {
    title: 'Where can you receive a verification code?',
    ctaBtnText: 'Next',
  },
  securityQuestion: {
    title: 'Answer security questions',
    ctaBtnText: 'Next',
  },
  sendOTP: {
    title: 'Answer security questions',
    ctaBtnText: 'Submit',
  },
  billLinkingDone: {
    successTitle: 'Bill was linked',
    linkNextBill: 'Link Next Bill',
    billActions: [
      {
        title: 'Identity Theft + Late Fee Protection',
        subText:
          'Your payment method will not be disclosed and we will automatically pay your bill based on expected amount & due date every each month.',
      },
      {
        title: 'Automatic Monitoring',
        subText:
          'We will monitor this bill for any unusual changes and update you at every stage.',
      },
      {
        title: 'Overpayment Protection',
        subText:
          'We make sure you do not overpay compared to your neighbours and talk to your provider to get you lower rates at all times',
      },
    ],
  },
};

export const billsConfigSmartCircle = {
  billsList: [
    'cellphone',
    'cable',
    'internet',
    'homePhone',
    'homeSecurity',
    'auto',
    'home',
    'electricity',
  ],
  cellphone: {
    type: 'cellphone',
    displayName: 'Cellphone',
    icon: '../assets/images/icons/icon-bill-cellphone.svg',
    visibleProviders: [
      '5af9a9019b1f40176c989a2e',
      '5af9a9019b1f40176c989a32',
      '5af9a9019b1f40176c989a2a',
      '5af9a9019b1f40176c989a36',
      '5af9a9019b1f40176c989a3e',
    ],
  },
  cable: {
    type: 'cable',
    displayName: 'Cable',
    icon: '../assets/images/icons/icon-bill-cable.svg',
    visibleProviders: [
      '5af9a9019b1f40176c98998e',
      '5af9a9019b1f40176c98999a',
      '5af9a9019b1f40176c989bbe',
      '5af9a9019b1f40176c98998a',
      '5af9a9019b1f40176c98999e',
      '5af9a9019b1f40176c989996',
    ],
  },
  internet: {
    type: 'internet',
    displayName: 'Internet',
    icon: '../assets/images/icons/icon-bill-internet.svg',
    visibleProviders: [
      '5af9a9019b1f40176c9899f2',
      '5af9a9019b1f40176c989baa',
      '5af9a9019b1f40176c989bb6',
      '5af9a9019b1f40176c989bc2',
      '5cbef4f317f7945a3a01bfc8',
      '5af9a9019b1f40176c989a22',
    ], //Spectrum, Optimum
  },
  homePhone: {
    type: 'homePhone',
    displayName: 'Home Phone',
    icon: '../assets/images/icons/icon-bill-homePhone.svg',
    visibleProviders: [
      '5af9a9019b1f40176c989b66',
      '5af9a9019b1f40176c989bc6',
      '5af9a9019b1f40176c989b6a',
      '5af9a9019b1f40176c989b6e',
      '5cbef4f317f7945a3a01bf94',
      '5cbef4f317f7945a3a01bfc6',
    ],
  },
  homeSecurity: {
    type: 'homeSecurity',
    displayName: 'Home Security',
    icon: '../assets/images/icons/icon-bill-homeSecurity.svg',
    visibleProviders: [
      '5af9a9019b1f40176c989b7a',
      '5af9a9019b1f40176c989b7e',
      '5af9a9019b1f40176c989b82',
      '5af9a9019b1f40176c989b8a',
      '5cbedf7917f7945a3a01bf1f',
    ], //ADT
  },
  auto: {
    type: 'auto',
    displayName: 'Auto Insurance',
    icon: '../assets/images/icons/icon-bill-auto.svg',
    visibleProviders: [
      '5af9a9019b1f40176c989b02',
      '5af9a9019b1f40176c989b0e',
      '5af9a9019b1f40176c989afa',
      '5af9a9019b1f40176c989afe',
      '5af9a9019b1f40176c989af6',
      '5af9a9019b1f40176c989b16',
    ],
  },
  home: {
    type: 'home',
    displayName: 'Home Insurance',
    icon: '../assets/images/icons/icon-bill-home.svg',
    visibleProviders: [
      '5af9a9019b1f41176c989afa',
      '5af9a9019b1f50176c989b02',
      '5af9a9019b1f40176c989b2e',
      '5af9a9019b1f40176c989b52',
      '5af9a9019b1f40176c989b26',
      '5af9a9019b1f40176c989b1e',
    ],
  },
  electricity: {
    type: 'electricity',
    displayName: 'Electricity',
    icon: '../assets/images/icons/icon-bill-electricity.svg',
    visibleProviders: [
      '5af9a9019b1f40176c989a8e',
      '5af9a9019b1f40176c989a6a',
      '5af9a9019b1f40176c989a8a',
      '5af9a9019b1f40176c989a7a',
      '5af9a9019b1f40176c989a86',
      '5af9a9019b1f40176c989a82',
      '5af9a9019b1f40176c989a76',
    ],
  },
};
