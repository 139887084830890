export const bills = {
  cellphone: {
    displayName: 'Cell Phone',
    icon: 'cellphone',
    colorClass: 'txt-danger',
    averageSavings: 215,
    dummyNegotiatorName: 'Alex',
    savingsStats: {
      monthlySavings: 50,
      yearlySavings: 600,
      totalSavings: 625,
      netSavings: 467,
      oneTimeCredit: 25,
      oldBillAmount: 165,
      newBillAmount: 115,
      contractTerm: 12,
      createdAt: '2019-01-01' // this date format is important as it works in firefox
    },
    confidencePercentage: 99
  },
  cable: {
    displayName: 'Cable',
    icon: 'cable',
    colorClass: 'txt-danger',
    averageSavings: 185,
    dummyNegotiatorName: 'Michelle',
    savingsStats: {
      monthlySavings: 20,
      yearlySavings: 240,
      totalSavings: 480,
      netSavings: 360,
      oneTimeCredit: 15,
      oldBillAmount: 135,
      newBillAmount: 115,
      contractTerm: 24,
      createdAt: '2019-02-01'
    },
    confidencePercentage: 99
  },
  internet: {
    displayName: 'Internet',
    icon: 'internet',
    colorClass: 'txt-danger',
    averageSavings: 120,
    dummyNegotiatorName: 'David',
    savingsStats: {
      monthlySavings: 15,
      yearlySavings: 180,
      totalSavings: 200,
      netSavings: 150,
      oneTimeCredit: 20,
      oldBillAmount: 95,
      newBillAmount: 80,
      contractTerm: 12,
      createdAt: '2019-03-01'
    },
    confidencePercentage: 99
  },
  homePhone: {
    displayName: 'Home Phone',
    icon: 'home-phone',
    colorClass: 'txt-danger',
    averageSavings: 50,
    dummyNegotiatorName: 'Martha',
    savingsStats: {
      monthlySavings: 10,
      yearlySavings: 120,
      totalSavings: 120,
      netSavings: 90,
      oneTimeCredit: 0,
      oldBillAmount: 88,
      newBillAmount: 78,
      contractTerm: 12,
      createdAt: '2019-04-01'
    },
    confidencePercentage: 99
  },
  homeSecurity: {
    displayName: 'Home Security',
    icon: 'home-security',
    colorClass: 'txt-danger',
    averageSavings: 50,
    dummyNegotiatorName: 'Emily',
    savingsStats: {
      monthlySavings: 7,
      yearlySavings: 84,
      totalSavings: 100,
      netSavings: 75,
      oneTimeCredit: 16,
      oldBillAmount: 42,
      newBillAmount: 35,
      contractTerm: 12,
      createdAt: '2019-05-01'
    },
    confidencePercentage: 99
  },
  auto: {
    displayName: 'Auto Insurance',
    icon: 'auto-insurance',
    colorClass: 'txt-danger',
    averageSavings: 180,
    dummyNegotiatorName: 'Heather',
    savingsStats: {
      monthlySavings: 50,
      yearlySavings: 600,
      totalSavings: 600,
      netSavings: 450,
      oneTimeCredit: 0,
      oldBillAmount: 195,
      newBillAmount: 145,
      contractTerm: 12,
      createdAt: '2019-06-01'
    },
    confidencePercentage: 99
  },
  home: {
    displayName: 'Home Insurance',
    icon: 'home-insurance',
    colorClass: 'txt-danger',
    averageSavings: 150,
    dummyNegotiatorName: 'Lauren',
    savingsStats: {
      monthlySavings: 5,
      yearlySavings: 60,
      totalSavings: 60,
      netSavings: 45,
      oneTimeCredit: 0,
      oldBillAmount: 75,
      newBillAmount: 70,
      contractTerm: 12,
      createdAt: '2019-07-01'
    },
    confidencePercentage: 99
  },
  electricity: {
    displayName: 'Electricity',
    icon: 'electricity',
    colorClass: 'txt-danger',
    averageSavings: 210,
    dummyNegotiatorName: 'John',
    savingsStats: {
      monthlySavings: 20,
      yearlySavings: 240,
      totalSavings: 240,
      netSavings: 180,
      oneTimeCredit: 0,
      oldBillAmount: 118,
      newBillAmount: 98,
      contractTerm: 12,
      createdAt: '2019-08-01'
    },
    confidencePercentage: 99
  }
};
