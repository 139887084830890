import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SnackbarService } from './snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  iserror:boolean=false;
  errtxt:string=''
  private snackbarSubscription : Subscription

  constructor(private snackbarService : SnackbarService) { }

  ngOnInit(): void {

    this.snackbarSubscription = this.snackbarService.snackbarState.
    subscribe((state)=>{
     this.errtxt = state.message;
     this.iserror = state.iserror;
     setTimeout(() => {
       this.iserror = false;
     }, 5000);
    })

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.snackbarSubscription.unsubscribe();
  }

}
