import { Component, OnInit,Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AppStore } from '../../stores/app.store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() isback: any;
  @Input() backtext: any;


  constructor(private location: Location, private router: Router,
    public appStore: AppStore) { 

    console.log(this.backtext)
  }

  ngOnInit(): void {
  }


  onBack(): void {
    console.log(window.location)

    if (window.history.length > 1) {
     // if(window.location.href.includes('bill-provider')){
     //   this.router.navigate(['/dashboard']);
     // }
       if(window.location.href.includes('bill-linking/profile')){
        this.router.navigate(['/profile']);
      }
      else if(window.location.href.includes('bill-linking/')){
        this.router.navigate(['/funnel/bill-provider']);
      }
      else if(window.location.href.includes('/profile')||window.location.href.includes('bill-provider')||window.location.href.includes('bank-link')){
        if(this.appStore.getStorageState('appUrl') == 'fb')
        {
          this.router.navigate(['/fb/dashboard']);
        }
        else
        this.router.navigate(['/dashboard']);
        //this.appStore.setStorageState('appUrl', 'fb');
       
      }
      else
      this.location.back();
     
    } 
   else {
      this.router.navigate(['/dashboard']);
    }

  }

}
