import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { FirebaseLoginComponent } from './firebase-login/firebase-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { Footer_relComponent } from './footer_rel/footer_rel.component';

export let maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};


@NgModule({
  declarations: [FooterComponent,
    Footer_relComponent,
     HeaderComponent,
     FirebaseLoginComponent,
      SnackbarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskOptions),
  ],
  exports: [FooterComponent,Footer_relComponent, HeaderComponent,SnackbarComponent,FirebaseLoginComponent]
})
export class SharedModule { }
