import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { UsersApi } from '../../@core/backend/api/users.api';

@Injectable({
  providedIn: 'root'
})
// keep it dumb as much as we can
export class SupportService {
  constructor(private usersAPI: UsersApi) {}

  raiseTicket(data: any): Observable<any> {
    return this.usersAPI.raiseTicket(data);
  }
}
