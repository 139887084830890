import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { UsersService } from '../../@core/backend/services/users.service';
import { LoginService } from '../../@core/backend/services/login.service';
import { HelperService } from '../../@core/backend/services/helper.service';
// import { NavService } from 'src/app/@theme/services/nav.service';
import { app } from '../../@config/app';
import { UserStore } from '../../@core/stores/user.store';
import { BillsStore } from '../../@core/stores/bills.store';

declare function pingPixel(str: string): any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  pageSubTitle = 'You can login via your email or phone.';
  public newUser = false;
  public loginForm: any;
  apiErrors = {
    emailInvalidError: false,
    phoneInvalidError: false,
    otpInvalidError: false
  };
  showLoader = false;
  showOTP = false;
  showPhone = true;
  showEmail = true;
  emptyError = false;

  showError = false;
  errorMessage = '';
  config = {
    app
  }; // from @config
  phoneErrorMsg: any;
  emailErrorMsg: any;

  constructor(
    private userService: UsersService,
    private userStore: UserStore,
    private billStore: BillsStore,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    // private navServices: NavService,
    private helper: HelperService
  ) {
    // initialize login form
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      phone: ['', [Validators.minLength(10), Validators.maxLength(10), this.validatePhoneFirstThreeDigits.bind(this)]],
      otp: ['', [Validators.pattern('[0-9]{4}')]]
    });
  }

  ngOnInit() {
    this.billStore.removeBills();
    this.userStore.removeUser();

    // logout
    this.helper.logout();

    pingPixel('ViewContent');
  }
  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.error) {
        this.showError = true;
        this.errorMessage = params.error;
      } else if (params.email) {
        this.loginForm.controls.email.patchValue(params.email);
      } else if (params.phone) {
        this.loginForm.controls.phone.patchValue(params.phone);
      }
    });
  }

  clearOther(str) {
    if (str === 'phone') {
      this.loginForm.controls.email.patchValue('');
    } else if (str === 'email') {
      this.loginForm.controls.phone.patchValue('');
    }
  }
  login(form) {
    this.emptyError = false;
    this.showLoader = true;

    if (this.showOTP) {
      this.loginForm.get('otp').setValidators([Validators.required, Validators.pattern('[0-9]{4}')]);
      this.loginForm.get('otp').updateValueAndValidity();

      if (this.loginForm.invalid) {
        console.log('form is invalid', this.loginForm.controls);
        this.showLoader = false;
        return;
      }
      this.validateOTP();
      return;
    }

    // set required to either phone or email field dynamically
    if (this.loginForm.controls.email.value) {
      this.loginForm.get('email').setValidators([Validators.required, Validators.email]);
      this.loginForm.get('phone').setValidators([Validators.minLength(10), Validators.maxLength(10)]);
    } else if (this.loginForm.controls.phone.value) {
      this.loginForm.get('email').setValidators([Validators.email]);
      this.loginForm.get('phone').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
    }
    this.loginForm.get('email').updateValueAndValidity();
    this.loginForm.get('phone').updateValueAndValidity();

    if (this.loginForm.invalid) {
      console.log('form is invalid', this.loginForm.controls);
      this.showLoader = false;
      return;
    }

    var phoneInvalid = this.validatePhoneFirstThreeDigits(this.loginForm.controls.phone);
    if (phoneInvalid) {
      this.showLoader = false;
      return;
    }

    // call appropriate way to login
    if (this.loginForm.controls.email.value) {
      this.loginViaEmail();
    } else if (this.loginForm.controls.phone.value) {
      this.loginViaPhone();
    } else {
      this.emptyError = true;
      this.showLoader = false;
    }
  }
  loginViaEmail() {
    this.loginService
      .loginViaEmail({
        email: this.loginForm.controls.email.value
      })
      .subscribe(
        (response: any) => {
          if (response.status) {
            console.log('loginViaEmail', response);
            this.showEmail = false;
            this.showPhone = false;
            this.showOTP = true;
            this.pageSubTitle = response.message || 'Please enter the 4-digit access code sent to your E-Mail below.';
            this.showLoader = false;
          } else {
            if (this.loginForm.controls.phone.value) {
              this.loginViaPhone();
              return;
            }
            const responseMessage = response.error || response.message;
            console.log('Error in loginViaEmail', responseMessage);
            if (responseMessage.includes('re-activate')) {
              this.emailErrorMsg = responseMessage;
            } else {
              this.emailErrorMsg = 'This phone number was not found in our system. Please verify the number or create a new account';
            }
            this.apiErrors.emailInvalidError = true;
            this.showEmail = true;
            this.showPhone = false;
            this.showOTP = false;
            this.showLoader = false;
          }
        },
        error => {
          console.log('Error in loginViaEmail', error);
          this.apiErrors.emailInvalidError = true;
          this.showEmail = true;
          this.showPhone = false;
          this.showOTP = false;
          this.showLoader = false;
        }
      );
  }
  loginViaPhone() {
    this.loginService
      .loginViaPhone({
        phone: this.loginForm.controls.phone.value
      })
      .subscribe(
        (response: any) => {
          if (response.status) {
            console.log('loginViaPhone', response);
            this.showEmail = false;
            this.showPhone = false;
            this.showOTP = true;
            this.pageSubTitle = response.message || 'Please enter the 4-digit access code send to your Cellphone below.';
            this.showLoader = false;
          } else {
            const responseMessage = response.error || response.message;
            console.log('Error in loginViaPhone', responseMessage);
            if (responseMessage.includes('re-activate')) {
              this.phoneErrorMsg = responseMessage;
            } else {
              this.phoneErrorMsg = 'This phone number was not found in our system. Please verify the number or create a new account';
            }
            this.apiErrors.phoneInvalidError = true;
            this.showEmail = false;
            this.showPhone = true;
            this.showOTP = false;
            this.showLoader = false;
          }
        },
        error => {
          console.log('Error in loginViaPhone', error);
          const responseMessage = (error.error && error.error.error) || error.message;
          if (responseMessage.includes('re-activate')) {
            this.phoneErrorMsg = responseMessage;
          } else {
            this.phoneErrorMsg = 'This phone number was not found in our system. Please verify the number or create a new account';
          }
          this.apiErrors.phoneInvalidError = true;
          this.showEmail = false;
          this.showPhone = true;
          this.showOTP = false;
          this.showLoader = false;
        }
      );
  }
  validateOTP() {
    this.userService
      .validateOTP({
        email: this.loginForm.controls.email.value,
        phone: this.loginForm.controls.phone.value,
        otp: this.loginForm.controls.otp.value
      })
      .subscribe(
        async (response: any) => {
          this.pageSubTitle = 'Hold on!! redirecting you to your dashboard';
          this.showEmail = false;
          this.showPhone = false;
          this.showOTP = false;
          await this.helper.waitForSeconds(3); // imp
          const user = this.userStore.getUser();
          pingPixel('SmartlookIdentify');
          if (this.loginService.isOnboardingCompleted()) {
            // this.navServices.lockSidebar = false;
            this.router.navigate(['my-savings']);
          } else {
            setTimeout(() => {
              const pageObj = this.loginService.getRedirectToPageObj();
              this.router.navigate([pageObj.path], {
                queryParams: pageObj.queryParams
              });
            }, 2000); // wait for 2 seconds for gathering the required data in session
          }
        },
        error => {
          console.log('validateOTP :=== ', error);
          this.apiErrors.otpInvalidError = true;
          this.showEmail = false;
          this.showPhone = false;
          this.showOTP = true;
          this.showLoader = false;
        }
      );
  }
  private validatePhoneFirstThreeDigits(control: FormControl) {
    var firstThreeDigit = control.value.substring(0, 3);
    if (firstThreeDigit && control.value.length === 10) {
      firstThreeDigit = parseInt(firstThreeDigit);
    } else {
      return false;
    }

    if (this.config.app.validFirstThreeDigits.includes(firstThreeDigit)) {
      return null; // vailid
    } else {
      this.loginForm.controls['phone'].setErrors({ incorrect: true });
      return { firstThreeInvalid: true }; // invalid
    }
  }
}
