import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements OnInit {
  private loggly: any;

  constructor(private router: Router) {}

  ngOnInit(): void {
    try {
      this.loggly = new (window as any).LogglyTracker();

      let tag = 'Angular-App-Logs';
      if (!environment.production) {
        tag = 'Angular-App-Logs-DEV';
      }
      this.loggly.push({
        logglyKey: environment.LOGGLY_TOKEN,
        sendConsoleErrors: true,
        tag
      });
    } catch (error) {
      console.log('loggly failed to load');
    }
  }

  private sentToLoggly(formattedLog) {
    // if (!environment.production) {
    //   // No logs to loggly if it is not a prod
    //   return;
    // }

    if (this.loggly) {
      this.loggly.push(formattedLog);
    } else {
      this.ngOnInit();
    }
  }

  public logData(dataType: string, message: string, data: any) {
    if (message.includes('Expression has changed')) {
      return false; // ignore these
    }

    console.log('LoggingService:logData', dataType, message, data);

    const logInProperFormat = {
      level: '',
      action: '',
      message: '',
      customerId: '',
      params: {}
    };

    logInProperFormat.level = dataType;
    logInProperFormat.action = this.router.url;
    logInProperFormat.message = message;

    let usr;
    const user = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'USER'));
    if (user && user.id) {
      usr = {
        name: user.firstName + ' ' + user.lastName,
        email: user.email
      };
      logInProperFormat.customerId = user.id;
    } else {
      delete logInProperFormat.customerId;
    }

    if (dataType.includes('Error')) {
      logInProperFormat.params = JSON.stringify({
        usr,
        error: data
      });
    } else {
      logInProperFormat.params = JSON.stringify({
        usr,
        extra: data
      });
    }

    // Send log to server here
    this.sentToLoggly(logInProperFormat);
  }
}
