import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BillsApi } from '../api/bills.api';
import { Bill } from '../../interfaces/bills';
import { switchMap, map } from 'rxjs/operators';
import { BillsStore } from '../../stores/bills.store';

@Injectable({
  providedIn: 'root'
})
// here we map the raw API response to meaningful object defined via interface
// to be then used inside web application for simplifying business logic
export class BillsService {
  public $progressChange = new EventEmitter<any>();
  constructor(private api: BillsApi, private billStore: BillsStore) {}

  getAll(data: any): Observable<Bill> {
    return this.api.getAll(data);
  }

  update(data: any): Observable<Bill> {
    return this.api.update(data);
  }

  uploadPDF(data: any): Observable<any> {
    return this.api.uploadPDF(data);
  }

  getProviders(): Observable<any> {
    return this.api.getProviders();
  }

  getSignedURL(data: any): Observable<any> {
    return this.api.getSignedURL(data);
  }

  getAccountStatusDetails(data: any): Observable<any> {
    return this.api.getAccountStatusDetails(data);
  }

  startScpInstance(data: any): Observable<any> {
    return this.api.startScpInstance(data);
  }

  getScraperStatus(accountId: any): Observable<any> {
    return this.api.getScraperStatus(accountId);
  }

  verifyScpFlow(data: any): Observable<any> {
    return this.api.verifyScpFlow(data);
  }

  getBillDetails(customerId: any): Observable<any> {
    return this.api.getBillDetails(customerId);
  }

  addNotes(data: any): Observable<any> {
    return this.api.addNotes(data);
  }
  getSavings(data: any): Observable<any> {
    return this.api.getSavings(data);
  }

  getBillLinkingStatus(accountId: any) {
    const billStage = this.billStore.getBillStage(accountId);
    if (!billStage) {
      return null;
    }
    const timeGap: any = new Date().getTime() - new Date(billStage.setTime || null).getTime();
    if (timeGap < 5 * 60 * 1000) {
      return billStage;
    } else {
      return null;
    }
  }

  setBillLinkingStatus(stage: any) {
    stage.setTime = new Date();
    this.billStore.setBillLinkingStage(stage);
  }

  removeBillLinkingStatus(accountId: any) {
    this.billStore.removeBillLinkingStage(accountId);
  }

  getStatements(data: any): Observable<any> {
    return this.api.getStatements(data);
  }

  getPartnerReferralUrl(data: any): Observable<any> {
    return this.api.getPartnerReferralUrl(data);
  }

  startResetPin(data: any): Observable<any> {
    return this.api.startResetPin(data);
  }
  validatePin(data: any): Observable<any> {
    return this.api.validatePin(data);
  }
}
