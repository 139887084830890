import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpService } from './services/http.service';

import { LoginApi } from './api/login.api';

const API = [LoginApi];

const SERVICES = [HttpService];

@NgModule({
  imports: [CommonModule]
})
export class CommonBackendModule {
  static forRoot(): ModuleWithProviders<CommonBackendModule> {
    return {
      ngModule: CommonBackendModule,
      providers: [...API, ...SERVICES]
    } as ModuleWithProviders;
  }
}
