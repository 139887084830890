import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService } from './utils';

// import { UserStore } from './stores/user.store';
// import { UsersService } from './backend/services/users.service';
// import { UsersApi } from './backend/api/users.api';

import { CommonBackendModule } from './backend/common-backend.module';

// ...CommonMockModule.forRoot().providers
export const NB_CORE_PROVIDERS = [
  AnalyticsService,
  ...CommonBackendModule.forRoot().providers
];

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS]
    } as ModuleWithProviders;
  }
}
