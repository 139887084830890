import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../@core/backend/services/users.service';
import { HelperService } from '../../@core/backend/services/helper.service';
import { LoginService } from '../../@core/backend/services/login.service';
import { isArray } from 'util';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { UserStore } from '../../@core/stores/user.store';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  params: any;
  magicId: string;
  redirectToPage = '';
  fromVero = false;
  customer:any
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private userStore: UserStore,
    private helper: HelperService,
    private loginService: LoginService,
    private snackSer: SnackbarService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let temp = JSON.parse(JSON.stringify(params));
     console.log(temp,'temp')
      if(params.page.includes('referralHome'))
      {
        
        this.router.navigate(['/referral/referralIntro'], {
          queryParams: {leadid:params.refId,savings:params.savings},
        });

      }

     

      if (params.id) {
        if (!params.id.includes('+') && params.id.includes(' ')) {
          this.magicId = params.id.replace(' ', '+');
        } else {
          this.magicId = params.id;
        }
        delete temp.id;
      }
      if (params.page) {
        if (isArray(params.page)) {
          this.redirectToPage = params.page[0].toLowerCase();
        } else {
          this.redirectToPage = params.page.toLowerCase();
        }

        delete temp.page;
      }

      if (temp.vero_conv) {
        delete temp.vero_conv;
        this.fromVero = true;
      }

      if (temp.vero_id) {
        delete temp.vero_id;
        this.fromVero = true;
      }

      this.params = temp;
    });
    if (this.magicId) {
      this.processMagicId();
    } else if (this.params.fpage) {
      this.router.navigate(['/funnel/login'], { queryParamsHandling: 'preserve' });
    }
  }

  processMagicId() {
    this.userService
      .verifyMagicID({
        id: this.magicId
      })
      .subscribe(
        async (status: any) => {
          var isOnboardingCompleted = this.loginService.isOnboardingCompleted();
          var pageObj = this.loginService.getRedirectToPageObj();
          if (status) {
            await this.helper.waitForSeconds(0.9); // imp
            console.log(status, 'Magic URL is valid. Redirecting to ' + this.redirectToPage || 'my-savings');
          let  customer = this.userStore.getUser();
           if(customer)
           {
             if(customer.onboardingType==111)
             {
              this.router.navigate(['/dashboard'], {
                queryParams: this.params
              }); 
             }
           }
            //https://app.billtrim.com/update?id=8a308fdcddb59f009a7b719f72a5ea27626d709806956dcf1321ecb01b599905&page=onboarding&page=onboarding&card=cellphone&vero_id=5e9f190f5f44400027db57a3&vero_conv=rbdyPIjtcCBnHXOom6fwei3a_CKNbn9tQxibDx_0upIP0l1yRSTS_xBvHVLyKa0sqEFCgUxJhWBz0ayowf4PKemG32EO6KhzBTrcBDn7Jg0%3D
            if (this.redirectToPage) {
              if (this.redirectToPage === 'bill') {
                if (this.params.card) {
                  delete this.params.card;
                  this.router.navigate(['/bills/view/' + this.params.card], {
                    queryParams: this.params
                  });
                } else {
                  this.router.navigate(['my-savings'], {
                    queryParams: this.params
                  });
                }
              } else if (this.redirectToPage === 'profile') {
                this.router.navigate(['/profile'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'unsubscribe') {
                this.router.navigate(['/fb/unsubscribe'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'fb') {
                this.router.navigate(['fb'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'thankyou') {
                this.router.navigate(['/fb/thankyou'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'dashboard') {
                this.router.navigate(['/fb/dashboard'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'billpay_dashboard') {
                this.router.navigate(['/dashboard'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'smart-circle') {
                this.router.navigate(['/smart-circle'], {
                  queryParams: this.params
                });
              }else if (this.redirectToPage === 'bank_account') {
                this.router.navigate(['/funnel/bank-link'], {
                  queryParams: this.params
                });
              } else if (this.redirectToPage === 'funnel') {
                const fPage = this.params.fpage;
                this.router.navigate([`/funnel/${fPage}`], {
                  queryParams: this.params
                });
              } else {
                if (!isOnboardingCompleted && !this.fromVero) {
                  if (pageObj) {
                    this.router.navigate([pageObj.path], {
                      queryParams: { ...this.params, ...pageObj.queryParams }
                    });
                  } else {
                    this.router.navigate(['get-started'], {
                      queryParams: this.params
                    });
                  }
                } else {
                  this.router.navigate([this.redirectToPage], {
                    queryParams: this.params
                  });
                }
              }
            } else {
              if (!isOnboardingCompleted) {
                if (pageObj) {
                  this.router.navigate([pageObj.path], {
                    queryParams: { ...this.params, ...pageObj.queryParams }
                  });
                } else {
                  this.router.navigate(['get-started'], {
                    queryParams: this.params
                  });
                }
              } else {
                this.router.navigate(['my-savings'], {
                  queryParams: this.params
                });
              }
            }
          } else {
            this.handleInvalidMagicURL('');
          
          }
        },
        error => {
          console.log(error.error.error,'maccc')
          
          this.handleInvalidMagicURL(error?.error?.error);
        }
      );
  }

  handleInvalidMagicURL(err) {
    if(err == "URL Expired")
    {
      this.snackSer.show('Auto login URL is expired')  
    }
   else if(err =='Sorry! please contact customer support to re-activate your account' )
    {
      this.snackSer.show('Your account is de-activated. Please contact customer support <a href="mailto:customersucess@billtrim.com">customersucess@billtrim.com</a> to re-activate it.')  
    }
    else if (err == 'Customer ID not found')
    {
      this.snackSer.show('Auto login URL is invalid. Please log in again!')

    }
    else
    this.snackSer.show('Auto login URL is invalid. Please log in again!')
 
   
    this.router.navigate(['funnel/initial'], {
      queryParams: { page: 'login', ...this.params }
    });
  }
}
