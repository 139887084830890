import {
  ExtraOptions,
  RouterModule,
  Routes,
  Router,
  ActivatedRoute,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { NgModule, Injectable } from '@angular/core';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { SupportComponent } from './support/support.component';
import { Observable } from 'rxjs';
import { AllUpcomingBillsComponent } from './all-upcoming-bills/all-upcoming-bills.component';
import { AllLinkedBillsComponent } from './all-linked-bills/all-linked-bills.component';
import { PreviousPaymentsComponent } from './previous-payments/previous-payments.component';
import { SavingsBreakdownComponent } from './bill-details/savings-breakdown/savings-breakdown.component';



@Injectable()
class DirectFBRoutes implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.queryParams && route.queryParams.fl == 1) {
      const urlParams = JSON.parse(JSON.stringify(route.queryParams));
      delete urlParams.fl;
      this.router.navigate(['/funnel/initial'], { queryParams: urlParams } );
      return false;
    }
    if (route.queryParams && route.queryParams.utm_redirect) {
      const redirect = route.queryParams.utm_redirect;
      const urlParams = JSON.parse(JSON.stringify(route.queryParams));
      delete urlParams.utm_redirect;
      const urlParamsString = Object.keys(urlParams)
        .map(key => `${key}=${encodeURIComponent(urlParams[key])}`)
        .join('&');
      let redirectUrl = redirect.replace(/http(s*):\/\//g, '');
      redirectUrl = `https://${redirectUrl}?${urlParamsString}`;
      document.location.href = redirectUrl;
      return false;
    }
    return true;
  }
}

@Injectable()
class DirectFunnelRoutes implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.queryParams && route.queryParams.utm_redirect) {
      const redirectUrl = route.queryParams.utm_redirect;
      const urlParams = JSON.parse(JSON.stringify(route.queryParams));
      delete urlParams.utm_redirect;
      const urlParamsString = Object.keys(urlParams)
        .map(key => `${key}=${encodeURIComponent(urlParams[key])}`)
        .join('&');
      document.location.href = `http://${redirectUrl}?${urlParamsString}`;
      return true;
    }
    return true;
  }
}

const routes: Routes = [
  {
    path: 'update',
    component: RedirectComponent
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: 'fb',
    canActivate: [DirectFBRoutes],
    loadChildren: () => import('./fb/fb.module').then(m => m.FbModule)
  },
  {
    path: 'funnel',
    canActivate: [DirectFunnelRoutes],
    loadChildren: () =>
      import('./funnel/funnel.module').then(m => m.FunnelModule)
  },
  {
    path: 'smart-circle',
    canActivate: [DirectFunnelRoutes],
    loadChildren: () =>
      import('./smart-circle/smart-circle.module').then(m => m.SmartCircleModule)
  },
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule)    
  },
  {
    path: 'bill-details/:billType',
    component: BillDetailsComponent
  },
  {
    path: 'bill-details/:billType/:billPayId',
    component: BillDetailsComponent
  },

  {
    path: 'savings-breakdown/:billPayId',
    component: SavingsBreakdownComponent
  },
  {
    path: 'all-upcoming-bills',
    component: AllUpcomingBillsComponent
  },
  {
    path: 'all-linked-bills',
    component: AllLinkedBillsComponent
  },
  
  {
    path: 'previous-payments',
    component: PreviousPaymentsComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'support',
    component: SupportComponent
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [DirectFBRoutes, DirectFunnelRoutes]
})
export class AppRoutingModule {}
