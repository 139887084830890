<div class="container-fluid">
  <div class="auth-bg">
    <div class="authentication-box">
      <div class="text-center mb-5">
        <img src="https://www.billtrim.com/images/v2/symbol.png" alt="BillTrim Symbol" />
      </div>

      <div class="alert alert-dark dark f-18" role="alert" *ngIf="showError">
        <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> -->
        <p>Error: {{ errorMessage }}</p>
      </div>

      <form class="theme-form" *ngIf="!newUser" [formGroup]="loginForm">
        <div class="" [ngClass]="{ 'd-none': !showPhone }">
          <label>Enter your cellphone number</label>
          <input
            class="form-control phone-input mb-0"
            formControlName="phone"
            type="phone"
            onKeyPress="if(this.value.length>=12) return false;"
            placeholder="000-000-0000"
            autofocus=""
            mask="000-000-0000"
            (keydown.enter)="login(loginForm.value)"
            (click)="clearOther('phone')"
            autocomplete="off"
          />
          <div
            *ngIf="loginForm.controls.phone.errors !== null && loginForm.controls.phone.value.length === 10 && !apiErrors.phoneInvalidError"
            class="text-danger mt-1"
          >
            Invalid cellphone number
          </div>
          <div *ngIf="loginForm.controls.phone.touched && apiErrors.phoneInvalidError" class="text-danger mt-1">
            {{ phoneErrorMsg }}
          </div>
        </div>

        <!-- <div class="hr my-2" [hidden]="!showEmail || !showPhone">
          <div></div>
          <p class="p-2 m-0 text-muted">OR</p>
          <div></div>
        </div>

        <div class="mb-4" [ngClass]="{ 'd-none': !showEmail }">
          <label>Email Address</label>
          <input
            class="form-control form-control-lg mb-0"
            formControlName="email"
            onKeyPress="if(this.value.length>=40) return false;"
            type="email"
            placeholder="abc@gmail.com"
            (keydown.enter)="login(loginForm.value)"
            (click)="clearOther('email')"
            autocomplete="off"
          />
          <div
            *ngIf="
              loginForm.controls.email.touched &&
              loginForm.controls.email.errors !== null &&
              !apiErrors.emailInvalidError
            "
            class="text-danger mt-1"
          >
            Email is invalid
          </div>
          <div
            *ngIf="
              loginForm.controls.email.touched && apiErrors.emailInvalidError
            "
            class="text-danger mt-1"
          >
            No matching account found with given Email address
          </div>
        </div> -->

        <div class="form-group" [ngClass]="{ 'd-none': !showOTP }">
          <label>Enter the verification code</label>
          <input
            class="form-control f-64 text-center"
            type="otp"
            autofocus=""
            formControlName="otp"
            placeholder="0000"
            onKeyPress="if(this.value.length>=4) return false;"
            (keydown.enter)="login(loginForm.value)"
          />
          <div *ngIf="loginForm.controls.otp.touched && loginForm.controls.otp.errors !== null && !apiErrors.otpInvalidError" class="text-danger mt-1">
            Access code must be a 4 digit numeric code
          </div>
          <div *ngIf="loginForm.controls.otp.touched && apiErrors.otpInvalidError" class="text-danger mt-1">
            Access code is incorrect. Please enter valid code and try again.
          </div>
        </div>

        <div class="mt-5 mb-0">
          <div *ngIf="emptyError" class="text-danger mt-1 mb-2">
            Cellphone number is required
          </div>

          <button
            class="btn {{
              loginForm.controls.phone.value.length !== 10 || loginForm.controls.phone.errors ? 'btn-secondary' : 'btn-primary'
            }}  btn-block btn-lg"
            [class.loader--text]="showLoader"
            (click)="login(loginForm.value)"
            type="submit"
            [disabled]="loginForm.controls.phone.value.length !== 10 || loginForm.controls.phone.errors"
          >
            <span [hidden]="showLoader">{{ showOTP ? 'Sign In' : 'Send Access Code' }}</span>
          </button>
          <div class="py-3 f-14" *ngIf="!apiErrors.phoneInvalidError && !apiErrors.emailInvalidError && !showOTP">
            *For your convenience & security purposes, we will send you a 4-digit access code to your cellphone phone.
          </div>
        </div>

        <hr class="mt-5" />
        <div class="p-2 text-center">
          <p class="">
            <span class="">Don't have an BillTrim account? </span>
            <a [routerLink]="['/fb']" [queryParams]="{ new: 1 }" routerLinkActive="active" href="javascript: void(0)">
              Create Account
            </a>
          </p>
        </div>

        <div class="social mt-3 d-none">
          <div class="form-group btn-showcase d-flex">
            <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
              <i class="fa fa-facebook"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
