import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthInterceptor } from '../app/@core/backend/auth.interceptor';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { PubNubAngular } from 'pubnub-angular2';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { GlobalErrorHandler } from './@core/logging/global-error-handler';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { AllUpcomingBillsComponent } from './all-upcoming-bills/all-upcoming-bills.component';
import { AllLinkedBillsComponent } from './all-linked-bills/all-linked-bills.component';

import { NgbModule, NgbDropdown, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './profile/profile.component';
import { SupportComponent } from './support/support.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SavingsBreakdownComponent } from './bill-details/savings-breakdown/savings-breakdown.component';
import { PreviousPaymentsComponent } from './previous-payments/previous-payments.component';
import { SharedModule } from './shared/shared.module';

import { environment } from '../environments/environment';


export let maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [AppComponent,
     LoginComponent,
      RedirectComponent,
       DashboardComponent,
        BillDetailsComponent, 
        ProfileComponent,
         SupportComponent,
          AllUpcomingBillsComponent,
           AllLinkedBillsComponent,
            SavingsBreakdownComponent,
             PreviousPaymentsComponent,
            ],
  imports: [
    FormsModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    CoreModule.forRoot(),
    ReactiveFormsModule,
    NgxPlaidLinkModule,
    NgbModule,
  
    
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
      // progressBar: true,
    }),
    NgxMaskModule.forRoot(maskOptions),
    NgOtpInputModule,
    // NgxOtpInputModule
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300
    }),
    SharedModule
  ],
  providers: [
    FormBuilder,
    PubNubAngular,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  
   
  ],
  bootstrap: [AppComponent],
  exports: [FormsModule, ReactiveFormsModule]
})
export class AppModule {}
