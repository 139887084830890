// Note this is based on big query "BillAmountVsPercentOfCustomerPerProvider"
// TODO: need to update this monthly
export const providerBillAmountSpread = [
  {
    type: 'auto',
    name: 'Allstate',
    _id: '5af9a9019b1f40176c989afe',
    Percent_0_50: '3.0',
    Percent_50_100: '7.01',
    Percent_100_150: '13.0',
    Percent_150_200: '20.0',
    Percent_200_250: '10.0',
    Percent_250_300: '3.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '3.0',
    Percent_450_500: '3.0',
    Percent_500_plus: '38.0',
    TotalCount: '40'
  },
  {
    type: 'auto',
    name: 'Geico',
    _id: '5af9a9019b1f40176c989afa',
    Percent_0_50: '3.0',
    Percent_50_100: '14.02',
    Percent_100_150: '23.0',
    Percent_150_200: '15.0',
    Percent_200_250: '10.0',
    Percent_250_300: '2.0',
    Percent_300_350: '3.0',
    Percent_350_400: '2.0',
    Percent_400_450: '4.0',
    Percent_450_500: '2.0',
    Percent_500_plus: '18.0',
    TotalCount: '361'
  },
  {
    type: 'auto',
    name: 'Liberty Mutual',
    _id: '5af9a9019b1f40176c989b0e',
    Percent_0_50: '0.0',
    Percent_50_100: '5.0',
    Percent_100_150: '18.0',
    Percent_150_200: '14.02',
    Percent_200_250: '9.0',
    Percent_250_300: '0.0',
    Percent_300_350: '18.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '32.0',
    TotalCount: '22'
  },
  {
    type: 'auto',
    name: 'Progressive',
    _id: '5af9a9019b1f40176c989b02',
    Percent_0_50: '3.0',
    Percent_50_100: '13.0',
    Percent_100_150: '22.0',
    Percent_150_200: '16.0',
    Percent_200_250: '5.0',
    Percent_250_300: '3.0',
    Percent_300_350: '3.0',
    Percent_350_400: '1.0',
    Percent_400_450: '3.0',
    Percent_450_500: '2.0',
    Percent_500_plus: '25.0',
    TotalCount: '134'
  },
  {
    type: 'auto',
    name: 'State Farm Insurance',
    _id: '5af9a9019b1f40176c989af6',
    Percent_0_50: '2.0',
    Percent_50_100: '12.0',
    Percent_100_150: '28.04',
    Percent_150_200: '12.0',
    Percent_200_250: '8.0',
    Percent_250_300: '8.0',
    Percent_300_350: '6.0',
    Percent_350_400: '3.0',
    Percent_400_450: '5.0',
    Percent_450_500: '2.0',
    Percent_500_plus: '8.0',
    TotalCount: '112'
  },
  {
    type: 'auto',
    name: 'Travellers',
    _id: '5af9a9019b1f40176c989b1a',
    Percent_0_50: '9.0',
    Percent_50_100: '0.0',
    Percent_100_150: '9.0',
    Percent_150_200: '0.0',
    Percent_200_250: '9.0',
    Percent_250_300: '18.0',
    Percent_300_350: '9.0',
    Percent_350_400: '0.0',
    Percent_400_450: '9.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '27.0',
    TotalCount: '11'
  },
  {
    type: 'auto',
    name: 'USAA',
    _id: '5af9a9019b1f40176c989b06',
    Percent_0_50: '0.0',
    Percent_50_100: '12.0',
    Percent_100_150: '26.0',
    Percent_150_200: '24.0',
    Percent_200_250: '3.0',
    Percent_250_300: '3.0',
    Percent_300_350: '3.0',
    Percent_350_400: '3.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '21.0',
    TotalCount: '34'
  },
  {
    type: 'cable',
    name: 'AT&T DirecTV',
    _id: '5af9a9019b1f40176c98998a',
    Percent_0_50: '3.0',
    Percent_50_100: '32.0',
    Percent_100_150: '28.04',
    Percent_150_200: '24.0',
    Percent_200_250: '6.0',
    Percent_250_300: '2.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '2.0',
    Percent_500_plus: '0.0',
    TotalCount: '116'
  },
  {
    type: 'cable',
    name: 'Cable One',
    _id: '5af9a9019b1f40176c9899b2',
    Percent_0_50: '16.0',
    Percent_50_100: '22.0',
    Percent_100_150: '27.0',
    Percent_150_200: '24.0',
    Percent_200_250: '4.0',
    Percent_250_300: '1.0',
    Percent_300_350: '1.0',
    Percent_350_400: '1.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '1.0',
    TotalCount: '184'
  },
  {
    type: 'cable',
    name: 'CenturyLink',
    _id: '5af9a9019b1f40176c9899be',
    Percent_0_50: '9.0',
    Percent_50_100: '40.0',
    Percent_100_150: '41.0',
    Percent_150_200: '4.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '1.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '1.0',
    Percent_500_plus: '0.0',
    TotalCount: '68'
  },
  {
    type: 'cable',
    name: 'Comcast Xfinity',
    _id: '5af9a9019b1f40176c98998e',
    Percent_0_50: '2.0',
    Percent_50_100: '16.0',
    Percent_100_150: '22.0',
    Percent_150_200: '30.0',
    Percent_200_250: '20.0',
    Percent_250_300: '6.0',
    Percent_300_350: '3.0',
    Percent_350_400: '1.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '353'
  },
  {
    type: 'cable',
    name: 'Dish Network',
    _id: '5af9a9019b1f40176c989996',
    Percent_0_50: '0.0',
    Percent_50_100: '53.0',
    Percent_100_150: '37.0',
    Percent_150_200: '5.0',
    Percent_200_250: '0.0',
    Percent_250_300: '5.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '19'
  },
  {
    type: 'cable',
    name: 'Google Fiber',
    _id: '5af9a9019b1f40176c9899ea',
    Percent_0_50: '0.0',
    Percent_50_100: '16.0',
    Percent_100_150: '41.0',
    Percent_150_200: '26.0',
    Percent_200_250: '10.0',
    Percent_250_300: '1.0',
    Percent_300_350: '1.0',
    Percent_350_400: '1.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '68'
  },
  {
    type: 'cable',
    name: 'RCN Corporation',
    _id: '5af9a9019b1f40176c9899ba',
    Percent_0_50: '4.0',
    Percent_50_100: '21.0',
    Percent_100_150: '30.0',
    Percent_150_200: '21.0',
    Percent_200_250: '14.02',
    Percent_250_300: '4.0',
    Percent_300_350: '2.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '2.0',
    Percent_500_plus: '4.0',
    TotalCount: '57'
  },
  {
    type: 'cable',
    name: 'Verizon Fios',
    _id: '5af9a9019b1f40176c98999a',
    Percent_0_50: '7.01',
    Percent_50_100: '25.0',
    Percent_100_150: '30.0',
    Percent_150_200: '17.0',
    Percent_200_250: '11.0',
    Percent_250_300: '4.0',
    Percent_300_350: '2.0',
    Percent_350_400: '0.0',
    Percent_400_450: '1.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '321'
  },
  {
    type: 'cable',
    name: 'Windstream',
    _id: '5af9a9019b1f40176c9899b6',
    Percent_0_50: '9.0',
    Percent_50_100: '24.0',
    Percent_100_150: '23.0',
    Percent_150_200: '21.0',
    Percent_200_250: '13.0',
    Percent_250_300: '5.0',
    Percent_300_350: '2.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '1.0',
    TotalCount: '456'
  },
  {
    type: 'cellphone',
    name: 'AT&T',
    _id: '5af9a9019b1f40176c989a2e',
    Percent_0_50: '2.0',
    Percent_50_100: '14.02',
    Percent_100_150: '19.0',
    Percent_150_200: '21.0',
    Percent_200_250: '15.0',
    Percent_250_300: '9.0',
    Percent_300_350: '6.0',
    Percent_350_400: '4.0',
    Percent_400_450: '3.0',
    Percent_450_500: '1.0',
    Percent_500_plus: '4.0',
    TotalCount: '748'
  },
  {
    type: 'cellphone',
    name: 'Boost Mobile',
    _id: '5af9a9019b1f40176c989a42',
    Percent_0_50: '0.0',
    Percent_50_100: '47.0',
    Percent_100_150: '21.0',
    Percent_150_200: '3.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '34'
  },
  {
    type: 'cellphone',
    name: 'Consumer Cellular',
    _id: '5af9a9019b1f40176c989a3e',
    Percent_0_50: '25.0',
    Percent_50_100: '53.0',
    Percent_100_150: '5.0',
    Percent_150_200: '0.0',
    Percent_200_250: '3.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '40'
  },
  {
    type: 'cellphone',
    name: 'Credo Mobile',
    _id: '5af9a9019b1f40176c989a46',
    Percent_0_50: '2.0',
    Percent_50_100: '19.0',
    Percent_100_150: '25.0',
    Percent_150_200: '13.0',
    Percent_200_250: '12.0',
    Percent_250_300: '11.0',
    Percent_300_350: '5.0',
    Percent_350_400: '2.0',
    Percent_400_450: '1.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '2.0',
    TotalCount: '202'
  },
  {
    type: 'cellphone',
    name: 'Sprint',
    _id: '5af9a9019b1f40176c989a36',
    Percent_0_50: '10.0',
    Percent_50_100: '15.0',
    Percent_100_150: '25.0',
    Percent_150_200: '14.02',
    Percent_200_250: '10.0',
    Percent_250_300: '5.0',
    Percent_300_350: '6.0',
    Percent_350_400: '6.0',
    Percent_400_450: '4.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '2.0',
    TotalCount: '84'
  },
  {
    type: 'cellphone',
    name: 'Straight Talk',
    _id: '5af9a9019b1f40176c989a56',
    Percent_0_50: '2.0',
    Percent_50_100: '11.0',
    Percent_100_150: '22.0',
    Percent_150_200: '21.0',
    Percent_200_250: '13.0',
    Percent_250_300: '9.0',
    Percent_300_350: '7.01',
    Percent_350_400: '5.0',
    Percent_400_450: '3.0',
    Percent_450_500: '1.0',
    Percent_500_plus: '2.0',
    TotalCount: '462'
  },
  {
    type: 'cellphone',
    name: 'T-Mobile',
    _id: '5af9a9019b1f40176c989a32',
    Percent_0_50: '18.0',
    Percent_50_100: '14.02',
    Percent_100_150: '16.0',
    Percent_150_200: '19.0',
    Percent_200_250: '6.0',
    Percent_250_300: '5.0',
    Percent_300_350: '5.0',
    Percent_350_400: '2.0',
    Percent_400_450: '3.0',
    Percent_450_500: '1.0',
    Percent_500_plus: '5.0',
    TotalCount: '110'
  },
  {
    type: 'cellphone',
    name: 'US Cellular',
    _id: '5af9a9019b1f40176c989a4e',
    Percent_0_50: '1.0',
    Percent_50_100: '14.02',
    Percent_100_150: '24.0',
    Percent_150_200: '14.02',
    Percent_200_250: '15.0',
    Percent_250_300: '9.0',
    Percent_300_350: '6.0',
    Percent_350_400: '3.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '6.0',
    TotalCount: '294'
  },
  {
    type: 'cellphone',
    name: 'Verizon',
    _id: '5af9a9019b1f40176c989a2a',
    Percent_0_50: '13.0',
    Percent_50_100: '18.0',
    Percent_100_150: '15.0',
    Percent_150_200: '17.0',
    Percent_200_250: '17.0',
    Percent_250_300: '7.01',
    Percent_300_350: '3.0',
    Percent_350_400: '2.0',
    Percent_400_450: '1.0',
    Percent_450_500: '1.0',
    Percent_500_plus: '0.0',
    TotalCount: '139'
  },
  {
    type: 'cellphone',
    name: 'Virgin Mobile',
    _id: '5af9a9019b1f40176c989a4a',
    Percent_0_50: '93.0',
    Percent_50_100: '0.0',
    Percent_100_150: '7.01',
    Percent_150_200: '0.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '14'
  },
  {
    type: 'cellphone',
    name: 'metroPCS',
    _id: '5af9a9019b1f40176c989a52',
    Percent_0_50: '19.0',
    Percent_50_100: '22.0',
    Percent_100_150: '11.0',
    Percent_150_200: '19.0',
    Percent_200_250: '11.0',
    Percent_250_300: '11.0',
    Percent_300_350: '7.01',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '27'
  },
  {
    type: 'electricity',
    name: 'Reliant an NRG Company',
    _id: '5af9a9019b1f40176c989a76',
    Percent_0_50: '3.0',
    Percent_50_100: '5.0',
    Percent_100_150: '37.0',
    Percent_150_200: '24.0',
    Percent_200_250: '21.0',
    Percent_250_300: '5.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '3.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '3.0',
    TotalCount: '38'
  },
  {
    type: 'electricity',
    name: 'TXU Energy',
    _id: '5af9a9019b1f40176c989a7e',
    Percent_0_50: '0.0',
    Percent_50_100: '17.0',
    Percent_100_150: '39.0',
    Percent_150_200: '17.0',
    Percent_200_250: '11.0',
    Percent_250_300: '11.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '6.0',
    Percent_500_plus: '0.0',
    TotalCount: '18'
  },
  {
    type: 'home',
    name: 'Progressive',
    _id: '5af9a9019b1f50176c989b02',
    Percent_0_50: '3.0',
    Percent_50_100: '13.0',
    Percent_100_150: '22.0',
    Percent_150_200: '16.0',
    Percent_200_250: '5.0',
    Percent_250_300: '3.0',
    Percent_300_350: '3.0',
    Percent_350_400: '1.0',
    Percent_400_450: '3.0',
    Percent_450_500: '2.0',
    Percent_500_plus: '25.0',
    TotalCount: '134'
  },
  {
    type: 'homePhone',
    name: 'AT&T',
    _id: '5af9a9019b1f40176c989b6a',
    Percent_0_50: '9.0',
    Percent_50_100: '7.01',
    Percent_100_150: '20.0',
    Percent_150_200: '26.0',
    Percent_200_250: '35.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '2.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '54'
  },
  {
    type: 'homePhone',
    name: 'Spectrum',
    _id: '5af9a9019b1f40176c989bc6',
    Percent_0_50: '27.0',
    Percent_50_100: '19.0',
    Percent_100_150: '27.0',
    Percent_150_200: '19.0',
    Percent_200_250: '6.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '52'
  },
  {
    type: 'homePhone',
    name: 'Sprint',
    _id: '5af9a9019b1f40176c989b76',
    Percent_0_50: '0.0',
    Percent_50_100: '17.0',
    Percent_100_150: '44.0',
    Percent_150_200: '22.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '6.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '6.0',
    TotalCount: '18'
  },
  {
    type: 'homePhone',
    name: 'Time Warner',
    _id: '5af9a9019b1f40176c989b72',
    Percent_0_50: '0.0',
    Percent_50_100: '17.0',
    Percent_100_150: '33.0',
    Percent_150_200: '33.0',
    Percent_200_250: '17.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '12'
  },
  {
    type: 'homePhone',
    name: 'Verizon',
    _id: '5af9a9019b1f40176c989b6e',
    Percent_0_50: '23.0',
    Percent_50_100: '15.0',
    Percent_100_150: '23.0',
    Percent_150_200: '15.0',
    Percent_200_250: '8.0',
    Percent_250_300: '0.0',
    Percent_300_350: '8.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '8.0',
    TotalCount: '13'
  },
  {
    type: 'homeSecurity',
    name: 'ADT',
    _id: '5af9a9019b1f40176c989b7a',
    Percent_0_50: '47.0',
    Percent_50_100: '49.0',
    Percent_100_150: '3.0',
    Percent_150_200: '0.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '59'
  },
  {
    type: 'homeSecurity',
    name: 'Vivint',
    _id: '5af9a9019b1f40176c989b8a',
    Percent_0_50: '28.999999999999996',
    Percent_50_100: '68.0',
    Percent_100_150: '0.0',
    Percent_150_200: '4.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '28'
  },
  {
    type: 'internet',
    name: 'AT&T U-verse',
    _id: '5af9a9019b1f40176c989baa',
    Percent_0_50: '21.0',
    Percent_50_100: '40.0',
    Percent_100_150: '18.0',
    Percent_150_200: '10.0',
    Percent_200_250: '4.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '1.0',
    Percent_500_plus: '1.0',
    TotalCount: '276'
  },
  {
    type: 'internet',
    name: 'Charter Communications',
    _id: '5af9a9019b1f40176c9899fa',
    Percent_0_50: '15.0',
    Percent_50_100: '52.0',
    Percent_100_150: '11.0',
    Percent_150_200: '19.0',
    Percent_200_250: '4.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '27'
  },
  {
    type: 'internet',
    name: 'Comcast Xfinity',
    _id: '5af9a9019b1f40176c9899f2',
    Percent_0_50: '11.0',
    Percent_50_100: '40.0',
    Percent_100_150: '21.0',
    Percent_150_200: '16.0',
    Percent_200_250: '9.0',
    Percent_250_300: '1.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '203'
  },
  {
    type: 'internet',
    name: 'Cox Communications',
    _id: '5af9a9019b1f40176c9899fe',
    Percent_0_50: '4.0',
    Percent_50_100: '65.0',
    Percent_100_150: '23.0',
    Percent_150_200: '8.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '26'
  },
  {
    type: 'internet',
    name: 'LongLines Communications',
    _id: '5af9a9019b1f40176c989a26',
    Percent_0_50: '18.0',
    Percent_50_100: '45.0',
    Percent_100_150: '0.0',
    Percent_150_200: '27.0',
    Percent_200_250: '9.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '11'
  },
  {
    type: 'internet',
    name: 'Mediacom',
    _id: '5af9a9019b1f40176c989a0e',
    Percent_0_50: '27.0',
    Percent_50_100: '39.0',
    Percent_100_150: '19.0',
    Percent_150_200: '11.0',
    Percent_200_250: '2.0',
    Percent_250_300: '0.0',
    Percent_300_350: '1.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '104'
  },
  {
    type: 'internet',
    name: 'Optimum',
    _id: '5af9a9019b1f40176c989bce',
    Percent_0_50: '0.0',
    Percent_50_100: '36.0',
    Percent_100_150: '21.0',
    Percent_150_200: '43.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '14'
  },
  {
    type: 'internet',
    name: 'RCN',
    _id: '5af9a9019b1f40176c989a16',
    Percent_0_50: '10.0',
    Percent_50_100: '61.0',
    Percent_100_150: '15.0',
    Percent_150_200: '10.0',
    Percent_200_250: '4.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '71'
  },
  {
    type: 'internet',
    name: 'Spectrum',
    _id: '5af9a9019b1f40176c989bc2',
    Percent_0_50: '19.0',
    Percent_50_100: '46.0',
    Percent_100_150: '18.0',
    Percent_150_200: '11.0',
    Percent_200_250: '4.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '84'
  },
  {
    type: 'internet',
    name: 'Spectrum (Bright House Networks)',
    _id: '5af9a9019b1f40176c989a06',
    Percent_0_50: '23.0',
    Percent_50_100: '28.04',
    Percent_100_150: '18.0',
    Percent_150_200: '14.02',
    Percent_200_250: '5.0',
    Percent_250_300: '3.0',
    Percent_300_350: '1.0',
    Percent_350_400: '1.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '1.0',
    TotalCount: '185'
  },
  {
    type: 'internet',
    name: 'Spectrum (Time Warner Cable)',
    _id: '5af9a9019b1f40176c9899f6',
    Percent_0_50: '22.0',
    Percent_50_100: '50.0',
    Percent_100_150: '12.0',
    Percent_150_200: '9.0',
    Percent_200_250: '2.0',
    Percent_250_300: '1.0',
    Percent_300_350: '1.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '339'
  },
  {
    type: 'internet',
    name: 'Suddenlink',
    _id: '5af9a9019b1f40176c989a12',
    Percent_0_50: '19.0',
    Percent_50_100: '41.0',
    Percent_100_150: '15.0',
    Percent_150_200: '12.0',
    Percent_200_250: '9.0',
    Percent_250_300: '1.0',
    Percent_300_350: '1.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '1.0',
    TotalCount: '511'
  },
  {
    type: 'internet',
    name: 'Verizon Fios',
    _id: '5af9a9019b1f40176c989bb6',
    Percent_0_50: '0.0',
    Percent_50_100: '38.0',
    Percent_100_150: '19.0',
    Percent_150_200: '28.999999999999996',
    Percent_200_250: '10.0',
    Percent_250_300: '5.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '21'
  },
  {
    type: 'internet',
    name: 'WOW!',
    _id: '5af9a9019b1f40176c989a0a',
    Percent_0_50: '22.0',
    Percent_50_100: '22.0',
    Percent_100_150: '22.0',
    Percent_150_200: '33.0',
    Percent_200_250: '0.0',
    Percent_250_300: '0.0',
    Percent_300_350: '0.0',
    Percent_350_400: '0.0',
    Percent_400_450: '0.0',
    Percent_450_500: '0.0',
    Percent_500_plus: '0.0',
    TotalCount: '18'
  }
];
