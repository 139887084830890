<div class="wrapper d-flex flex-column h-100">
  <div class="header-block">
    <div class="text-center">
      <img class="back-btn" src="../../../assets/images/fb/arrow_back.svg" (click)="onBack()" />
      <h5 class="py-3 mb-0">Previous Payments (#STATIC)</h5>
    </div>
  </div>

  <div class="content-block background-grey flex-grow-1">

    <div class="d-flex flex-column align-items-center">
      <div class="segmented-control">
        <div class="option" [ngClass]="{ active: activeTab === 'all'}" (click)="selectTab('all')">All</div>
        <div class="option" [ngClass]="{ active: activeTab === 'failed' }" (click)="selectTab('failed')">Failed</div>
      </div>
    </div>

    <div class="linked-bills-wrap" *ngIf="previousPayments.length > 0">
      <div class="container-large">

        <div class="bills-listing">
          <div class="bills-item" *ngFor="let payment of previousPayments; let i = index"
            [ngClass]="{ 'item-border': previousPayments.length > i + 1 }">
            <div class="bills-content-wp">

              <div>
                <div class="bill-list-item-content-name">
                  <h3>{{ payment.providerName || billsConfig[payment.type]?.displayName }}</h3>
                </div>

                <ng-container *ngIf="payment.isPaid; else faildPayent">
                  <div class="bill-list-item-content-due d-flex align-items-center">
                    <img src="../../assets/images/fb/check_green.svg" class="mr-1" /> Paid • {{
                    payment.billPaidDate | date: 'mediumDate' }}
                  </div>
                </ng-container>

                <ng-template #faildPayent>
                  <div class="bill-list-item-content-due d-flex align-items-center">
                    <img src="../../assets/images/fb/alert.svg" class="mr-1" /> Paid • {{
                    payment.billPaidDate | date: 'mediumDate' }}
                  </div>
                </ng-template>

              </div>
            </div>

            <div class="d-flex-c">
              <div class="bill-list-item-amount font-amount" *ngIf="payment.billAmount">${{ payment.billAmount }}</div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>