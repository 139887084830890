<div class="row">
  <div class="col-xl-12 col-md-12">
    <div class="card">
      <div class="card-body text-center" style="min-height: 500px;">
        <img width="90px" height="90px" src="assets/images/loader-bill.gif" style="margin: 50px 0 20px 0;" />
        <div class="p-3">
          Getting your dashboard ready. Please wait ...
        </div>
      </div>
    </div>
  </div>
</div>
