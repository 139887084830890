import { Injectable } from '@angular/core';
import { Bill } from '../interfaces/bills';
import { environment } from 'src/environments/environment';
// The class will be available application wide as a singleton
// with no need to add it to a module's providers array
@Injectable({
  providedIn: 'root',
})
// store class maintains the object so that it can be accessed across any components or pages
export class BillsStore {
  private bills: Bill[];
  private billLinking: any = {};
  private providers: any;
  private billDetails: any;
  private numBillTouched: any;

  getBill(type: string): Bill {
    if (this.bills) {
      return this.bills.find((bill) => bill.type === type);
    } else {
      try {
        this.bills = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS')
        );
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
      if (this.bills) {
        return this.bills.find((bill) => bill.type === type) || null;
      } else {
        return null;
      }
    }
  }

  setBill(paramBill: Bill) {
    const index = this.bills.findIndex((e) => e.type === paramBill.type);

    if (index === -1) {
      this.bills.push(paramBill);
    } else {
      this.bills[index] = paramBill;
    }
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'BILLS',
      JSON.stringify(this.bills)
    );
    return this.bills;
  }

  getBills(): Bill[] {
    if (!this.bills) {
      // get it from local as the user seems to have refreshed
      try {
        this.bills = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS')
        );
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
    }
    return this.bills;
  }

  setBills(paramBills: Bill[]): Bill[] {
    this.bills = paramBills;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'BILLS',
      JSON.stringify(this.bills)
    );
    return this.bills;
  }

  setBillTouched(numBillTouched: any): void {
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'numBillTouched');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'numBillTouched',
      JSON.stringify(numBillTouched)
    );
  }

  setValidBills(numValidBills: any): void {
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'numValidBills');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'numValidBills',
      JSON.stringify(numValidBills)
    );
  }

  getProviders(): any {
    if (!this.providers) {
      // get it from local as the user seems to have refreshed
      try {
        this.providers = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'PROVIDERS')
        );
      } catch (e) {
        // console.log(e);
        this.providers = null;
      }
    }
    return this.providers;
  }
  setProviders(providers: any): any {
    this.providers = providers;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'PROVIDERS');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'PROVIDERS',
      JSON.stringify(this.providers)
    );
    return this.providers;
  }
  getProviderName(billType: string, providerId: string): string {
    const providers = JSON.parse(
      sessionStorage.getItem(environment.COOKIE_PREFIX + 'PROVIDERS')
    );
    if (!providers || !providerId) {
      return null;
    }
    const provider = providers[billType].filter((x) => x._id === providerId);
    if (provider.length > 0) {
      return provider[0].name;
    } else {
      return null;
    }
  }

  isSkipped(billType: string): boolean {
    const bills = this.getBills();
    if (bills) {
      const bill: any = bills.find((bl) => {
        return bl.type === billType;
      });
      return bill ? bill.flags.skipped : false;
    } else {
      return false;
    }
  }

  removeBills(): void {
    this.bills = null;
    this.providers = null;
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'PROVIDERS');
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
  }

  setBillLinkingStage(billStage: any) {
    this.billLinking[billStage.id || billStage.accountId] = billStage;
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLLINKING');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'BILLLINKING',
      JSON.stringify(this.billLinking)
    );
  }
  removeBillLinkingStage(billStageId: any) {
    delete this.billLinking[billStageId];
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLLINKING');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'BILLLINKING',
      JSON.stringify(this.billLinking)
    );
  }
  setAllBillLinkingStages(billsStages: any): any {
    this.billLinking = billsStages;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLLINKING');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'BILLLINKING',
      JSON.stringify(this.bills)
    );
    return this.billLinking;
  }

  getAllBillStages() {
    if (!this.billLinking) {
      // get it from local as the user seems to have refreshed
      try {
        this.billLinking =
          JSON.parse(
            sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLLINKING')
          ) || {};
      } catch (e) {
        // console.log(e);
        this.billLinking = {};
      }
    }
    return this.billLinking;
  }

  getBillStage(billId: string): any {
    if (!this.billLinking || Object.keys(this.billLinking).length === 0) {
      // get it from local as the user seems to have refreshed
      try {
        this.billLinking =
          JSON.parse(
            sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLLINKING')
          ) || {};
      } catch (e) {
        this.billLinking = {};
      }
    }
    const billStage = this.billLinking ? this.billLinking[billId] : null;
    return billStage;
  }

  sortSavingsPerBill() {
    if (this.bills) {
      try {
        this.bills = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS')
        );
        this.bills.forEach((bill) => {
          if (bill.savings.length > 0) {
            // bill has savings records, sort in ASC as per attempt date
            const orderedSavings = bill.savings.sort(function (a, b) {
              var keyA = new Date(a.createdAt),
                keyB = new Date(b.createdAt);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            bill.savings = orderedSavings;
            if (!bill.latestBillAmount) {
              bill.latestBillAmount =
                bill.savings[bill.savings.length - 1].newBillAmount;
            }
            if (!bill.lastNegotiatedOn) {
              bill.lastNegotiatedOn =
                bill.savings[bill.savings.length - 1].createdAt;
            }
          }
        });
        sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
        sessionStorage.setItem(
          environment.COOKIE_PREFIX + 'BILLS',
          JSON.stringify(this.bills)
        );
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
    }
  }
  sortPaymentsPerBill() {
    if (this.bills) {
      try {
        this.bills = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS')
        );
        this.bills.forEach((bill) => {
          if (bill.payments.length > 0) {
            // payments has charges records, sort in ASC as per scheduleDate date
            const orderedPayments = bill.payments.sort(function (a, b) {
              var keyA = new Date(a.scheduleDate),
                keyB = new Date(b.scheduleDate);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            bill.payments = orderedPayments;
          }
        });
        sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
        sessionStorage.setItem(
          environment.COOKIE_PREFIX + 'BILLS',
          JSON.stringify(this.bills)
        );
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
    }
  }
  setBillDetails(details: any): any {
    this.billDetails = details;
    // save it on local as well for cases when user refreshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILL_DETAILS');
    sessionStorage.setItem(
      environment.COOKIE_PREFIX + 'BILL_DETAILS',
      JSON.stringify(details)
    );
    return this.billDetails;
  }
  getBillDetails() {
    if (!this.billDetails) {
      // get it from local as the user seems to have refreshed
      try {
        this.billDetails = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILL_DETAILS')
        );
      } catch (e) {
        // console.log(e);
        this.billDetails = null;
      }
    }
    return this.billDetails;
  }
  getBillTouched() {
    if (!this.numBillTouched) {
      // get it from local as the user seems to have refreshed
      try {
        this.numBillTouched = JSON.parse(
          sessionStorage.getItem(environment.COOKIE_PREFIX + 'numBillTouched')
        );
      } catch (e) {
        // console.log(e);
        this.numBillTouched = null;
      }
    }
    return this.numBillTouched;
  }
}
