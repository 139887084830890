<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Savings Breakdown</h4>
    <button type="button" class="close" aria-label="Close" (click)="onBack()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="billPaySaving" class="row savings-tnfo">
        <div class="col-12 text-center p-0 px-4">

            <div class="d-flex justify-content-between align-items-center saving-item">
                <div class="saving-name">Monthly Discount</div>
                <div class="saving-price">${{billPaySaving.oldBillAmount - billPaySaving.newBillAmount}}</div>
            </div>
           
            <div class="d-flex justify-content-between align-items-center saving-item">
                <div class="saving-name">Deal term</div>
                <div class="saving-price">{{billPaySaving.contractTerm}} Months</div>
            </div>


            <div class="d-flex justify-content-between align-items-center saving-item">
                <div class="saving-name">One time courtesy credit</div>
                <div class="saving-price">${{billPaySaving.oneTimeCredit}}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center saving-item">
                <div class="saving-name">Total Saving</div>
                <div class="saving-price">${{billPaySaving.totalSavings}}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center saving-item">
                <div class="saving-name">Effective date</div>
                <div class="saving-price">08/22/2020</div>
            </div>

            <div class="d-flex justify-content-between align-items-center saving-item">
            <div class="saving-name">AT&T Rep</div>
            <div class="saving-price">Matteo</div>
          </div>
        </div>
        <!-- <div class="mt-3" *ngIf="!bill?.savings?.length">
            <div class="text-left">
                Unable to show the savings breakdown right now! Please try again later
            </div>
        </div> -->

        <!-- <div class="mt-3" *ngIf="bill?.communicationMsg">
            <div class="comMsgTitle caps">Communication message</div>
            <div class="text-left comMsg">
                Spoke to Matteo from the Loyalty Department at AT&T. He approved a $5/ line discount for 10 voice lines
                and decreased the price from $582.36 to
                $532.36 on the base plan for the next 6 months and a $30 one time courtesy credit. Savings: $50 x 6
                months + $30 = $330. No changes to the plan.
                Effective Date: Next Billing Cycle
            </div>
        </div> -->
    </div>
</div>