import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
// keep it dumb as much as we can
export class SqueezeService {
  deviceInfo: any;
  isMobile = false;
  isTablet = false;
  isDesktopDevice = false;
  constructor(private deviceService: DeviceDetectorService) {}

  setDevices() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
}
