<div class="wrapper d-flex flex-column h-100">
  <div class="header-block">
    <div class="text-center">
      <img
        class="back-btn"
        src="../../../assets/images/fb/arrow_back.svg"
        (click)="onBack()"
      />
      <h5 class="py-3 mb-0">LINKED BILLS</h5>
    </div>
  </div>

  <div class="content-block background-grey flex-grow-1">
    <div class="linked-bills-wrap" *ngIf="linkedPayments.length > 0">
      <div class="container-large">
        <div class="bills-listing">
          <div
            class="bills-item"
            *ngFor="let bill of linkedPayments; let i = index"
            [ngClass]="
              bill.account.billFetched == 0 ||
              ['relinkingRequired'].includes(
                bill.billpayStage
              )
                ? 'error-box'
                : 'item-border'
            "
            (click)="openBillPayDetails(bill)"
          >
            <div class="bills-content-wp">
              <div
                class="bills-icon"
                *ngIf="
                  bill.account.billFetched &&
                  !['relinkingRequired'].includes(
                    bill.billpayStage
                  )
                "
              >
                <img
                  src="../../../../assets/images/fb/bill-types/{{
                    bill.type || (bill.billTypes.length && bill.billTypes[0])
                  }}.svg"
                />
              </div>
              <div
                class="bills-icon"
                *ngIf="
                  ['relinkingRequired'].includes(
                    bill.billpayStage
                  ) || bill.account.billFetched == 0
                "
              >
                <img src="../../../../assets/images/fb/red_error.svg" />
              </div>
              <h3>
                {{
                  bill.account.providerName ||
                    billsConfig[
                      bill.type || (bill.billTypes.length && bill.billTypes[0])
                    ]?.displayName
                }}
              </h3>
            </div>
            <!-- <div class="bills-value" *ngIf="bill.totalSavings">
              <span class="price">${{ bill.totalSavings || '0' }}</span>
              <span class="statement">Last payment</span>
            </div> -->

            <div class="d-flex-c">
              
              <!-- savingsFound -->
              <div
                class="bill-total-savings text-right"
                *ngIf="bill.billpayStage === 'savingsFound'"
              >
                <span class="total">${{ bill.totalSavings || '0' }}</span>
                <span class="label-sm">Total savings</span>
              </div>
              
              <!-- Negotiating -->
              <div
                class="bill-total-savings text-right"
                *ngIf="
                  bill.account.billFetched == 1 &&
                  [
                    'awaitingCustomerResponse',
                    'pdfMissing',
                    'negotiationRequired',
                    'qaRejected'
                  ].includes(bill.billpayStage)
                "
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/green-dot.svg"
                /></span>
                <span class="label-sm">Negotiating</span>
              </div>
              
              <!-- Negotiation Completed -->
              <div
                class="bill-total-savings text-right"
                *ngIf="
                  bill.account.billFetched == 1 &&
                  bill.billpayStage === 'qaApproved'
                "
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/green-dot.svg"
                /></span>
                <span class="label-sm">Negotiation Completed</span>
              </div>
             

              <!-- Got Bill -->
              <div
                class="bill-total-savings text-right"
                *ngIf="
                  bill.account.billFetched == 1 &&
                  bill.billpayStage === 'billFetched'
                "
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/green-dot.svg"
                /></span>
                <span class="label-sm">Analyzing</span>
              </div>

              <!-- Out of Sync -->
              <div
                class="bill-negotiating d-flex align-items-center"
                *ngIf="
                  bill.account.billFetched == 0 ||
                  ['relinkingRequired'].includes(
                    bill.billpayStage
                  )
                "
              >
                <div class="bill-error">
                  <div class="fix">Fix now</div>
                  <div class="out-sync">Out of Sync</div>
                </div>
              </div>
             
              <!-- Failed -->
              <div
                class="bill-total-savings text-right"
                *ngIf="
                  [
                    'customerError',
                    'inCorrectPin',
                    'chargeFailed',
                    'paymentFailed'
                  ].includes(bill.billpayStage)
                "
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/red_error.svg"
                /></span>
                <span class="label-sm">Failed</span>
              </div>

              <!-- Paid -->
              <div
                class="bill-total-savings text-right"
                *ngIf="
                  bill.account.billFetched == 1 &&
                  (bill.billpayStage === 'customerCharged' ||
                    bill.billpayStage === 'paymentDone')
                "
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/green-dot.svg"
                /></span>
                <span class="label-sm">Paid</span>
              </div>

              <div
                class="bill-total-savings text-right"
                *ngIf="bill.billpayStage === 'onBestDeal'"
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/green-dot.svg"
                /></span>
                <span class="label-sm">On Best Deal</span>
              </div>

              <div
                class="bill-total-savings text-right"
                *ngIf="
                  bill.account.billFetched == 1 &&
                  bill.billpayStage === 'chargePending'
                "
              >
                <span class="total"
                  ><img src="../../../../assets/images/fb/green-dot.svg"
                /></span>
                <span class="label-sm">Pending</span>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
