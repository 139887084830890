<!-- Upcomming bills -->
<div class="wrapper d-flex flex-column h-100">
  <div class="header-block">
    <div class="text-center">
      <img class="back-btn" src="../../../assets/images/fb/arrow_back.svg" (click)="onBack()" />
      <h5 class="py-3 mb-0">UPCOMING BILLS </h5>
    </div>
  </div>

  <div class="content-block background-grey flex-grow-1">

    <div class="linked-bills-wrap" *ngIf="upcomingBillList.length > 0">
      <div class="container-large">

        <div class="bills-listing">
          <div class="bills-item" *ngFor="let bill of upcomingBillList; let i = index"
            [ngClass]="{ 'item-border': upcomingBillList.length > i + 1 }" (click)="openBillPayDetails(bill)">
            <div class="bills-content-wp">
              <div class="bills-icon" 
              *ngIf="
                  bill.account.billFetched &&
                  !['relinkingRequired', 'pdfMissing', 'enabled'].includes(
                    bill.billpayStage
                  )">
                <img
                  src="../../../../assets/images/fb/bill-types/{{ bill.type || (bill.billTypes.length && bill.billTypes[0]) }}.svg" />
              </div>
              <div
              class="bills-icon"
              *ngIf="
                ['relinkingRequired', 'pdfMissing', 'enabled'].includes(
                  bill.billpayStage
                ) || bill.account.billFetched == 0
              "
            >
              <img src="../../../../assets/images/fb/red_error.svg" />
            </div>

              <div>
                <div class="bill-list-item-content-name">
                  <h3>{{ bill.account.providerName || billsConfig[bill.type || (bill.billTypes.length &&
                    bill.billTypes[0])]?.displayName }}</h3>

                </div>
                <div class="bill-list-item-content-due">Due {{ bill.billDueDate | date: 'mediumDate' }}</div>
              </div>
            </div>
            <!-- <div class="bills-value" *ngIf="bill.totalSavings">
              <span class="price">${{ bill.totalSavings || '0' }}</span>
              <span class="statement">Last payment</span>
            </div> -->

            <div class="d-flex-c">
              <div class="bill-total-savings text-right" *ngIf="bill.billpayStage === 'savingsFound'">
                <span class="total">${{ bill.totalSavings || '0' }}</span>
                <span class="label-sm">Total savings</span>
              </div>
              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="bill.account.billFetched == 1 && (bill.billpayStage === 'awaitingCustomerResponse' || bill.billpayStage === 'pdfMissing' || bill.billpayStage === 'negotiationRequired' || bill.billpayStage === 'qaRejected')">
                <img src="../../../../assets/images/fb/green-dot.svg" /> Negotiating
              </div>
              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="bill.account.billFetched == 1 && bill.billpayStage === 'qaApproved'">
                <img src="../../../../assets/images/fb/green-dot.svg" /> Negotiation Completed
              </div>

              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="bill.account.billFetched == 1 && bill.billpayStage === 'billFetched'">
                <img src="../../../../assets/images/fb/green-dot.svg" /> Analyzing
              </div>


              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="bill.account.billFetched == 1 && (bill.billpayStage === 'customerCharged' || bill.billpayStage ===  'paymentDone')">
                <img src="../../../../assets/images/fb/green-dot.svg" /> Paid
              </div>

              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="bill.account.billFetched == 1 && (bill.billpayStage === 'paymentFailed'
               || bill.billpayStage ===  'chargeFailed' || bill.billpayStage === 'customerError' || bill.billpayStage === 'inCorrectPin')">
                <img src="../../../../assets/images/fb/red-dot.svg" /> Failed
              </div>

              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="!bill.account.billFetched || bill.billpayStage === 'relinkingRequired'">
                <div class="bill-error">
                  <div class="fix">Fix now</div>
                  <div class="out-sync">Out of Sync</div>
                </div>

                <div class="bill-negotiating d-flex align-items-center" *ngIf="bill.billpayStage === 'onBestDeal'">
                  <img src="../../../../assets/images/fb/green-dot.svg" /> On Best Deal
                </div>
              </div>


              <div class="bill-negotiating d-flex align-items-center"
                *ngIf="bill.account.billFetched == 1 && (bill.billpayStage === 'chargePending')">
                <img src="../../../../assets/images/fb/green-dot.svg" /> Pending
              </div>
            
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>