import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppStore {
  private user: any;
  private bills: any;
  private providers: any;

  private CUSTOMER_STATE: any;
  private ACCOUNTS_STATE: any;
  private SELECTED_ACCOUNT_STATE: any;
  private BILLS_STATE: any;
  public bankAccounts: any;

  public localStore: any = {};

  public $updateAccounts = new EventEmitter<string>();
  public $updateBank = new EventEmitter<string>();

  get customer(): any {
    if (!this.CUSTOMER_STATE) this.CUSTOMER_STATE = this.getStorageState('USER');
    return this.CUSTOMER_STATE;
  }

  setCustomer(state: any) {
    this.CUSTOMER_STATE = { ...this.CUSTOMER_STATE, ...state };
  
    this.setStorageState('USER', this.CUSTOMER_STATE);
  }

  get accounts(): any {
    if (!this.ACCOUNTS_STATE) this.ACCOUNTS_STATE = this.getStorageState('BILLS');
    return this.ACCOUNTS_STATE;
  }

  get billsState(): any {
    if (!this.BILLS_STATE) this.BILLS_STATE = this.getStorageState('BILLS_STATE') || [];
    return this.BILLS_STATE;
  }

  removeBillState() {
    const bills = this.getStorageState('BILLS_STATE') || [];
    let bill_list = bills.map((item) => {      
      return {
        type:item.type,
        skipped:false,
        linked:item?.linked ? true : false

      }
    })        
    this.setStorageState('BILLS_STATE', bill_list);
  }

  set billsState(state: any) {
    console.log(state.length, 'statgele');
    let lBillState: any = this.getStorageState('BILLS_STATE') || [];
    if (state.length) {
      if (!lBillState || !lBillState.length) lBillState = state;
      for (let bill of state) {
        lBillState[lBillState.indexOf(lBillState.find((i: any) => i.type === bill.type))] = bill;
      }
    } else {
      if (!lBillState || !lBillState.length) lBillState = [state];
      lBillState[lBillState.indexOf(lBillState.find((i: any) => i.type === state.type))] = state;
    }
    this.BILLS_STATE = lBillState;
    this.setStorageState('BILLS_STATE', lBillState);
  }

  get selectedAccountState(): any {
    if (!this.SELECTED_ACCOUNT_STATE) this.SELECTED_ACCOUNT_STATE = this.getStorageState('SELECTED_ACCOUNT_STATE');
    return this.SELECTED_ACCOUNT_STATE;
  }

  set selectedAccountState(state: any) {
    this.SELECTED_ACCOUNT_STATE = { ...this.SELECTED_ACCOUNT_STATE, ...state };
    this.setStorageState('SELECTED_ACCOUNT_STATE', this.SELECTED_ACCOUNT_STATE);
  }

  getStorageState(key: string) {
    try {
      const val: any = sessionStorage.getItem(environment.COOKIE_PREFIX + key) || null;
      return JSON.parse(val);
    } catch (e) {
      return null;
    }
  }

  setStorageState(key: string, val: any) {
    try {
      sessionStorage.removeItem(environment.COOKIE_PREFIX + key);
      sessionStorage.setItem(environment.COOKIE_PREFIX + key, JSON.stringify(val));
    } catch (e) {}
  }

  

  getAccount(typeOrId: string): any {
    if (this.accounts && this.accounts.length) {
      return this.accounts.find((i: any) => i.type === typeOrId || i.id === typeOrId);
    }
    return null;
  }

  syncAccounts() {
    this.ACCOUNTS_STATE = this.getStorageState('BILLS');
    this.$updateAccounts.emit();
  }

  getUser(): any {
    if (!this.user) {
      // get it from local a user has refreshed
      try {
        const lUser: any = sessionStorage.getItem(environment.COOKIE_PREFIX + 'USER') || null;
        this.user = JSON.parse(lUser);
      } catch (e) {
        // console.log(e);
        this.user = null;
      }
    }
    return this.user;
  }

  setUser(paramUser: any) {
    this.user = paramUser;
    this.CUSTOMER_STATE= paramUser
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'USER');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'USER', JSON.stringify(this.user));
  }

  getBill(type: string): any {
    if (this.bills) {
      return this.bills.find((bill: any) => bill.type === type);
    } else {
      try {
        const lBills: any = sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS') || null;
        this.bills = JSON.parse(lBills);
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
      if (this.bills) {
        return this.bills.find((bill: any) => bill.type === type) || null;
      } else {
        return null;
      }
    }
  }

  setBill(paramBill: any) {
    const index = this.bills.findIndex((e: any) => e.type === paramBill.type);

    if (index === -1) {
      this.bills.push(paramBill);
    } else {
      this.bills[index] = paramBill;
    }
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'BILLS', JSON.stringify(this.bills));
    this.syncAccounts();
    return this.bills;
  }

  getBills(): any {
    if (!this.bills) {
      // get it from local as the user seems to have refreshed
      try {
        const lBills: any = sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS') || null;
        this.bills = JSON.parse(lBills);
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
    }
    return this.bills;
  }

  setBills(paramBills: any): any {
    this.bills = paramBills;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'BILLS', JSON.stringify(this.bills));
    this.syncAccounts();
    return this.bills;
  }

  getProviders(): any {
    if (!this.providers) {
      // get it from local as the user seems to have refreshed
      try {
        const lproviders: any = sessionStorage.getItem(environment.COOKIE_PREFIX + 'PROVIDERS') || null;
        this.providers = JSON.parse(lproviders);
      } catch (e) {
        // console.log(e);
        this.providers = null;
      }
    }
    return this.providers;
  }

  setProviders(providers: any): any {
    this.providers = providers;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'PROVIDERS');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'PROVIDERS', JSON.stringify(this.providers));
    return this.providers;
  }

  getProviderName(billType: string, providerId: string): any {
    const lproviders: any = sessionStorage.getItem(environment.COOKIE_PREFIX + 'PROVIDERS') || null;
    const providers = JSON.parse(lproviders);
    if (!providers || !providerId) {
      return null;
    }
    const provider = providers[billType].filter((x: any) => x._id === providerId);
    if (provider.length > 0) {
      return provider[0].name;
    } else {
      return null;
    }
  }
  setSavingSummary(summary: any) {
    if (!this.user){
      this.getUser();
    }
    this.user.savingsSummary = summary;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'USER');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'USER', JSON.stringify(this.user));
  }


  sortPaymentsPerBill() {
    if (this.bills) {
      try {
        this.bills = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS'));
        this.bills.forEach(bill => {
          if (bill.payments.length > 0) {
            // payments has charges records, sort in ASC as per scheduleDate date
            const orderedPayments = bill.payments.sort(function(a, b) {
              var keyA = new Date(a.scheduleDate),
                keyB = new Date(b.scheduleDate);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            bill.payments = orderedPayments;
          }
        });
        sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
        sessionStorage.setItem(environment.COOKIE_PREFIX + 'BILLS', JSON.stringify(this.bills));
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
    }
  }
  sortSavingsPerBill() {
    if (this.bills) {
      try {
        this.bills = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'BILLS'));
        this.bills.forEach(bill => {
          if (bill.savings.length > 0) {
            // bill has savings records, sort in ASC as per attempt date
            const orderedSavings = bill.savings.sort(function(a, b) {
              var keyA = new Date(a.createdAt),
                keyB = new Date(b.createdAt);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            bill.savings = orderedSavings;
            if (!bill.latestBillAmount) {
              bill.latestBillAmount = bill.savings[bill.savings.length - 1].newBillAmount;
            }
            if (!bill.lastNegotiatedOn) {
              bill.lastNegotiatedOn = bill.savings[bill.savings.length - 1].createdAt;
            }
          }
        });
        sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
        sessionStorage.setItem(environment.COOKIE_PREFIX + 'BILLS', JSON.stringify(this.bills));
      } catch (e) {
        // console.log(e);
        this.bills = null;
      }
    }
  }
}
