import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserStore } from '../@core/stores/user.store';
import { AppApi } from '../apis/app.api';
import {Location} from '@angular/common';


@Component({
  selector: 'app-all-upcoming-bills',
  templateUrl: './all-upcoming-bills.component.html',
  styleUrls: ['./all-upcoming-bills.component.scss']
})
export class AllUpcomingBillsComponent implements OnInit {

  customer: any;
  upcomingBillList: any = [];
  previousPayments: any = [];

  constructor(
    private userStore: UserStore,
    private router: Router,
    private appApi: AppApi,
    private _location: Location

  ) { }

  ngOnInit(): void {
    this.customer = this.userStore.getUser();    
    if (!this.customer) {
      this.router.navigate(['/funnel/initial']);
      return;
    }

    this.getBillPayEnabledBills();
    this.getPreviouslyPaidBills();
  }


  getPreviouslyPaidBills(){
    this.previousPayments = [

      {
        "hasCredentials": true,
        "needCustomerFor2FA": false,
        "loginAndVerificationSuccess": false,
        "auth_verified": -1,
        "billFetched": 0,
        "_id": "6253c1e20669ef00195f68f4",
        "type": "auto",
        "provider_id": "5af9a9019b1f40176c989afa",
        "username": "rss1@optonline.net",
        "providerName": "Geico",
        "billPaidDate": new Date(),
        "billAmount": 10.99,
        "isSync": true,


      }

    ]
  }
  

  getBillPayEnabledBills() {
    if (!this.customer) return;
    this.appApi.getBillPayEnabledBills(this.customer.id).subscribe(
      (resp: any) => {
        console.log(resp, 'sdb');

        let todayDate = new Date();

        resp.data.billpayData.forEach( (billObj)=>{
          if(billObj.billDueDate || new Date(billObj.billDueDate).getTime() > todayDate.getTime()){
            this.upcomingBillList.push(billObj);
          }
        });

        console.log('upcomingBillList',this.upcomingBillList)

        // this.billPayAccounts = resp.data.optedBills;
        // if (!this.billPayAccounts.length) {
        //   this.billPayAccounts = resp.data.linkedBills;
        // }
      },
      error => {}
    );
  }

  openBillPayDetails(bill: any) {
    const billType = bill.billTypes && bill.billTypes.length ? bill.billTypes[0] : '';
    if (billType) {
      this.router.navigateByUrl('/bill-details/' + billType + '/' + bill._id);
    } else {
      this.router.navigateByUrl('/bill-details/' + bill.type);
    }
  }

  onBack(): void {
    this.router.navigateByUrl(`/dashboard`)
  }

  goToNewBill(){
    this.router.navigateByUrl(`/funnel/bill-provider`)
  }
  goToPaymentMethods(){
    this.router.navigateByUrl(`/funnel/add-card-detail`)
  }
  goToLinkedBills(){
    this.router.navigateByUrl(`/all-linked-bills`)
  }
  goToPreviousPayments(){
    this.router.navigateByUrl(`/previous-payments`)
  }
  goToSavingBreakdown(){
    this.router.navigateByUrl(`//savings-breakdown/someID`)
  }
}
