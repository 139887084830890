import { Injectable, ElementRef } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { providerBillAmountSpread } from '../../../@config/providersBillAmountSpread';
import { UserStore } from '../../stores/user.store';
import { LoginService } from '../../backend/services/login.service';
import { BillsService } from '../../backend/services/bills.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { validFirstThreeDigits } from '../../../@config/page';

declare function customJSLogOut(): any;

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private toastrService: ToastrService,
    private billsService: BillsService,
    private loginService: LoginService,
    private userStore: UserStore,
    private router: Router
  ) {}

  monthNumToNameMap = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  getAge(dob, now) {
    const birthday = new Date(now.getFullYear(), dob.getMonth(), dob.getDate());
    if (now >= birthday) {
      return now.getFullYear() - dob.getFullYear();
    } else {
      return now.getFullYear() - dob.getFullYear() - 1;
    }
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  randomIntFromInterval(min1, max1) {
    return Math.floor(Math.random() * (max1 - min1) + min1);
  }

  getStackChartData(savingsStats) {
    const stackChartArray = [];
    if (savingsStats.oldBillAmount === 0) {
      return stackChartArray;
    }

    let savingStartFromMonthIndex = new Date(savingsStats.createdAt).getMonth();
    let savingStartFromYear = new Date(savingsStats.createdAt).getFullYear();
    const minIteration = Math.max(savingsStats.contractTerm, 12);

    for (let i = 1; i <= minIteration; i++) {
      const chartObj: any = {};
      chartObj.date =
        this.monthNumToNameMap[savingStartFromMonthIndex] +
        ' ' +
        savingStartFromYear;
      chartObj.due = savingsStats.newBillAmount;
      if (i > savingsStats.contractTerm) {
        chartObj.savings = 0;
      } else {
        chartObj.savings = savingsStats.monthlySavings
          ? savingsStats.monthlySavings
          : Math.round(savingsStats.oldBillAmount - savingsStats.newBillAmount);
      }
      stackChartArray.push(chartObj);
      savingStartFromMonthIndex++;
      if (savingStartFromMonthIndex > 11) {
        savingStartFromMonthIndex = 0; // back to JAN
        savingStartFromYear += 1;
      }
    }

    if (savingsStats.oneTimeCredit) {
      stackChartArray[0].savings =
        stackChartArray[0].savings + savingsStats.oneTimeCredit; // add OTC to savings
      stackChartArray[0].due =
        stackChartArray[0].due - savingsStats.oneTimeCredit; // subtract OTC from due
    }
    return stackChartArray;
  }

  copyToClipboard(text: string) {
    const copyText: any = document.createElement('input');
    copyText.value = text;
    copyText.style.cssText = 'position: absolute;top: -999999px';
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    copyText.blur();

   // this.toastrService.success('Copied to your Clipboard', '', {
   //   timeOut: 2000,
   // });
  }

  scrollToElement(elementId) {
    const element: HTMLElement = document.getElementById(
      elementId
    ) as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // hack to make the tour close button white
    setTimeout(() => {
      const btns = document.querySelectorAll('.navigate-btn');
      btns.forEach((btn) => {
        if (btn.innerHTML == '✖') {
          btn.innerHTML = 'X';
        }
      });
    }, 100);
  }

  leftPad(n, width, z) {
    if (n) {
      z = z || '0';
      n = n + '';
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    } else {
      return '';
    }
  }

  getProviderBillAmountSpread(billType, providerId) {
    function fudgePercentage(direction, currentValue, lastValue) {
      var fudgedPercentage = 0;
      if (direction == 'upwards') {
        fudgedPercentage = currentValue * 1.5;
      } else {
        fudgedPercentage = lastValue * 0.5;
      }
      return fudgedPercentage;
    }

    // get spread as per current data per provider from config
    let spread = [];
    if (billType != 'electricity' && providerId && providerId != '0') {
      spread = providerBillAmountSpread.filter(function (provider) {
        return provider._id === providerId;
      });
      if (spread[0]) {
        spread = spread[0];
      } else {
        // provider id not found then pick first of type
        spread = providerBillAmountSpread.filter(function (provider) {
          return provider.type === billType;
        });
        spread = spread[0];
      }
    } else {
      spread = providerBillAmountSpread.filter(function (provider) {
        return provider.type === billType;
      });
      spread = spread[0];
    }

    let data = [];
    let lastValue = 3;
    // iterate through the config data as some fudging is required so that the customer have a better UX
    for (let [key, value] of Object.entries(spread)) {
      if (key.includes('Percent') && !key.includes('plus')) {
        value = parseInt(value);
        var t = {
          amountRange: 0,
          percentCustomers: 3,
        };
        var r = key.split('_');
        t.amountRange = parseInt(r[r.length - 1]);
        t.percentCustomers = Math.round(value);

        // double check if fudging is required
        if (data.length > 0) {
          let direction = '';
          if (t.amountRange <= 200) {
            direction = 'upwards';
          } else {
            direction = 'downwards';
            if (lastValue <= 3) {
              // no point in fudging anymore. better to exit.
              break;
            }
          }

          if (value === 0) {
            // zero
            t.percentCustomers = fudgePercentage(direction, value, lastValue);
          } else if (value === lastValue) {
            // no change
            t.percentCustomers = fudgePercentage(direction, value, lastValue);
          } else {
            if (direction === 'upwards') {
              if (value < lastValue) {
                // avoid spike
                t.percentCustomers = fudgePercentage(
                  direction,
                  value,
                  lastValue
                );
              } else if (value - lastValue > 25) {
                // big jump
                t.percentCustomers = fudgePercentage(
                  direction,
                  value,
                  lastValue
                );
              }
            } else {
              // downwards
              if (value > lastValue) {
                // avoid spike
                t.percentCustomers = fudgePercentage(
                  direction,
                  value,
                  lastValue
                );
              } else if (lastValue - value > 25) {
                // big drop
                t.percentCustomers = fudgePercentage(
                  direction,
                  value,
                  lastValue
                );
              }
            }
          }
        }

        data.push(t);
        lastValue = t.percentCustomers;
      }
    }

    // add default first data point (MIN)
    data.unshift({
      amountRange: 0,
      percentCustomers: 0,
    });

    // add default last data point (MAX)
    data.push({
      amountRange: data[data.length - 1].amountRange + 50 + '+',
      percentCustomers: 0,
    });

    return data;
  }

  getSavingScore(billType, providerId, monthlyBillAmount) {
    let savingScore = 0;

    let spread = [];
    if (providerId && providerId != '0') {
      spread = providerBillAmountSpread.filter(function (provider) {
        return provider._id === providerId;
      });
      if (spread[0]) {
        spread = spread[0];
      } else {
        // provider id not found then pick first of type
        spread = providerBillAmountSpread.filter(function (provider) {
          return provider.type === billType;
        });
        spread = spread[0];
      }
    } else {
      spread = providerBillAmountSpread.filter(function (provider) {
        return provider.type === billType;
      });
      spread = spread[0];
    }

    if (spread) {
      for (let [key, value] of Object.entries(spread)) {
        if (key.includes('Percent')) {
          var r = key.split('_');
          var amountMin = parseInt(r[r.length - 2]);
          var amountMax = parseInt(r[r.length - 1]);
          if (amountMin >= monthlyBillAmount) {
            // since I am paying lesser, I get the whole chunk
            savingScore += Math.round(parseInt(value));
          } else if (
            amountMin < monthlyBillAmount &&
            amountMax > monthlyBillAmount
          ) {
            // the closer to min of current bracket I am, the more the value I will accumulate
            var t = (monthlyBillAmount - amountMin) * 2;
            t = 100 - t; // reverse the percentage gain
            t = t / 100; // convert percentage to decimal
            t = t * parseInt(value); // get my share as per where I fall within the bracket
            savingScore += Math.round(t);
          }
        }
      }
    }

    if (savingScore >= 10) {
      return savingScore;
    } else {
      return 10; // minimum of 10 as default
    }
  }

  setRequiredDataInSession(customerId) {
    this.billsService
      .getAll({
        customerId,
        category: 'personal',
      })
      .subscribe(
        (response2: any) => {
          console.log(
            'setRequiredDataInSession getUsersBillsDetails successfully',
            response2
          );
        },
        (error) => {
          console.log('setRequiredDataInSession getUsersBillsDetails', error);
        }
      );

    this.billsService.getProviders().subscribe(
      (response3: any) => {
        console.log(
          'setRequiredDataInSession getProvidersList successfully',
          response3
        );
      },
      (error) => {
        console.log('setRequiredDataInSession getProvidersList', error);
      }
    );

    this.billsService.getBillDetails(customerId).subscribe(
      (response3: any) => {
        console.log(
          'setRequiredDataInSession getBillDetails successfully',
          response3
        );
      },
      (error) => {
        console.log('setRequiredDataInSession getBillDetails', error);
      }
    );
  }

  async waitForSeconds(seconds) {
    if (!seconds) {
      seconds = 1; // default to one second
    }
    return new Promise((resolve) => setTimeout(resolve, 1000 * seconds));
  }

  logout() {
    sessionStorage.removeItem('TKN');
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('FEEDBACK');
    sessionStorage.removeItem('BNK_ERR');
    
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILLS');
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'USER');
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'NOTIFICATIONS');
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'BILL_DETAILS');
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'CURRENTPAGE');
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'numBillTouched');
     sessionStorage.clear();
    customJSLogOut();


  }

  informUserIsInactive(payload) {
    this.loginService.informUserIsInactive(payload).subscribe(
      (response: any) => {
        this.logout();
      },
      (error) => {
        console.log('informUserIsInactive error', error);
        this.logout();
      }
    );
  }

  getOperatingSystem(getDevice?: any) {
    try {
      let userAgent =
        navigator.userAgent || navigator.vendor || (window as any).opera;
      userAgent = userAgent.toLowerCase();

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows/i.test(userAgent)) {
        return 'Windows';
      }

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod|mac/.test(userAgent) && !(window as any).MSStream) {
        let os = 'iOS';
        if (getDevice) {
          if (window && window.screen) {
            if (window.screen.width <= 320 || window.screen.height <= 480) {
              os = 'iPhone4';
            } else if (
              window.screen.width === 320 &&
              window.screen.height === 568
            ) {
              os = 'iPhone5';
            } else if (
              window.screen.width === 375 &&
              window.screen.height === 667
            ) {
              os = 'iPhone8';
            } else if (
              window.screen.width === 414 &&
              window.screen.height === 736
            ) {
              os = 'iPhone8+';
            } else if (
              window.screen.width === 375 &&
              window.screen.height === 812
            ) {
              os = 'iPhoneX';
            } else if (
              window.screen.width === 414 &&
              window.screen.height === 896
            ) {
              os = 'iPhone11';
            }
            return os;
          }
        }
        if (navigator.userAgent.match(/(iPod|iPhone)/)) {
          os = 'iPhone';
        } else if (navigator.userAgent.match(/(iPad)/)) {
          os = 'iPad';
        } else if (navigator.userAgent.match(/(mac)/)) {
          os = 'mac';
        }

        return os;
      }

      return 'unknown';
    } catch (error) {
      console.log('error while getting os');
      return 'unknown';
    }
  }

  validatePhoneFirstThreeDigits(phone) {
   
    var firstThreeDigit = phone.substring(0, 3);
    if (firstThreeDigit && phone.length === 10) {
      firstThreeDigit = parseInt(firstThreeDigit);
    } else {
      return false;
    }
    ///alert(firstThreeDigit)
    if (validFirstThreeDigits.includes(firstThreeDigit)) {
      return null; // valid
    } else {
      return { firstThreeInvalid: true }; // invalid
    }
  }
}
