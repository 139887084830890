import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginApi {
  constructor(private api: HttpService) {}

  loginViaPhone(data: any): Observable<any> {
    return this.api.post(`customers/custLoginPhone`, data);
  }
  loginViaEmail(data: any): Observable<any> {
    return this.api.post(`customers/custLoginEmail`, data);
  }
  informUserIsInactive(data: any): Observable<any> {
    return this.api.postAppNode(`webhook/endless`, data);
  }
}
