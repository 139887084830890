import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserStore } from '../../stores/user.store';
import { HelperService } from '../services/helper.service';

@Injectable({
  providedIn: 'root',
})
// ping API and get the raw response
// map the raw API response to meaningful object defined via interface
// to be then used inside web application for simplifying business logic
export class UsersApi {
  private readonly apiController: string = 'users'; // main resource name

  constructor(
    private api: HttpService,
    private userStore: UserStore,
    private http: HttpClient,
    private helper: HelperService
  ) {}

  get(id: string): Observable<any> {
    return this.api
      .post(`customers/getCustomer`, { custId: id, paymentMethods: true })
      .pipe(
        map((response) => {
          return this.mapResponseToUserInterface(
            response.result.customer,
            undefined
          );
        })
      );
  }

  add(data: any): Observable<any> {
    return this.api.post(`customers/createCustomer`, data).pipe(
      map((response) => {
        if (response.status === false || !response.result.customer) {
          return response;
        }
        return this.mapResponseToUserInterface(
          response.result.customer,
          response.result.token
        );
      })
    );
  }

  update(data: any): Observable<any> {
    return this.api.post(`customers/updateCustomer`, data);
  }

  getTickets(data: any): Observable<any> {
    return this.api.post(`customers/getTickets`, data);
  }

  mapResponseToUserInterface(customerObj, respToken) {
    let token = '';
    if (respToken) {
      token = respToken;
      sessionStorage.setItem('TKN', JSON.stringify(respToken));
    }
console.log(customerObj,'customerObj')   
 let mappedResponse: any;
    try {
      var oldTS = new Date(customerObj.createdAt).getTime();
      var nowTS = new Date().getTime();
      var numDaysSinceSignup = Math.round((nowTS - oldTS) / (1000 * 3600 * 24));

      mappedResponse = {
        // identifiers and numbers
        id: customerObj._id,
        last4SSN: customerObj.last4_ssn,
        onboardingType: customerObj.onboardingType || '',
        numDaysSinceSignup: numDaysSinceSignup,

        // strings
        authToken: token,
        firstName: customerObj.firstName || '',
        lastName: customerObj.lastName || '',
        dob: customerObj.dob || '',
        gender: customerObj.gender || '',
        phone: customerObj.phone || '',
        email: customerObj.email || '',
        pricingPlan: customerObj.pricingPlan || '', // TODO:
        photoURL: customerObj.photoURL || '',
        referralCode: customerObj.referralCode,
        avatarURL: customerObj.avatarURL || 'assets/images/user/user.png',
        phoneVerified: customerObj.phoneVerified,
        ratings: customerObj.ratings && customerObj.ratings.ratings,
        customerHardShips: customerObj.customerHardShips,
        campaign: customerObj.campaign,
        billPayCustomer: customerObj.billPayCustomer,
        hotlist:customerObj.hotlist,
        // booleans
        flags: {
          isMilitary: customerObj.isMilitary,
          isOwnsHouse: customerObj.isOwnsHouse,
          onboardingCompleted: customerObj.onboarding_completed ? true : false,
          profileCompleted:
            // tslint:disable-next-line:max-line-length
            customerObj.firstName &&
            customerObj.lastName &&
            customerObj.email &&
            customerObj.phone &&
            customerObj.phoneVerified
              ? true
              : false, // this decides whether to lock the card
          phoneVerified: customerObj.phoneVerified || false,
          emailVerified: null, // TODO:
          subscribed: !customerObj.archived || false,
        },

        // dates
        signupDate: customerObj?.createdAt || '',
        lastLoginDate: '', // TODO:

        // objects
        creditCard: {
          exists: customerObj.card ? true : false,
          valid: customerObj.card && customerObj.card_verified ? true : false,
          last4: (customerObj.card && customerObj.card.card_last_four) || '',
        },

        address: {
          addressLine:
            (customerObj.address && customerObj.address.addressLine) || '',
          addressLine2:
            (customerObj.address && customerObj.address.addressLine2) || '',
          city: (customerObj.address && customerObj.address.city) || '',
          state: (customerObj.address && customerObj.address.state) || '',
          zipcode: (customerObj.address && customerObj.address.zipcode) || '',
        },
        pricingPlanDetails: customerObj?.pricingPlanDetails,
        paymentMethods: customerObj?.paymentMethods,
        notificationPreferences: customerObj.notificationPreferences || {},
      };
    } catch (e) {
      console.log(e);
    }

    // save in store for future reference
    this.userStore.setUser(mappedResponse);

    return mappedResponse;
  }

  phoneVerifyAndRegister(data: any): Observable<any> {
    return this.api.post(`customers/phoneVerifyAndRegister`, data);
  }

  queueRule(data: any): Observable<any> {
    data.sc = environment.SECRET_CODE;
    return this.api.post(`customers/queueRule`, data);
  }
  getLatestNumbers(): Observable<any> {
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'CURRENTPAGE');
    return this.api.get(`customers/lastestUnverified`);
  }

  addRemoveCustomerTags(data: any): Observable<any> {
    return this.api.postAppNode(`addRemoveCustomerTags`, data);
  }
  getIPData(): Observable<any> {
    return this.http.get('https://ipinfo.io/json?token=47e2f1ebfa2707').pipe(
      map((response) => {
        return this.mapIpdata(response);
      })
    );
  }
  setNotificationPreferences(data: any): Observable<any> {
    return this.api.post(`customers/setNotificationPreferences`, data);
  }

  customerExists(data: any): Observable<any> {
    return this.api.post(`customers/customerExists`, data);
  }

  attributeCustomer(data: any): Observable<any> {
    return this.api.post(`customers/attributeCustomer`, data);
  }

  validateOTP(data: any): Observable<any> {
    return this.api.post(`customers/custLoginVerify`, data).pipe(
      map((response) => {
        if (response.status === false) {
          sessionStorage.removeItem('isLoggedIn');
          return response;
        }
        sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
        sessionStorage.setItem('loggedViaMagicLink', JSON.stringify(false));
        this.mapResponseToUserInterface(
          response.result.customer,
          response.result.token
        );
        this.helper.setRequiredDataInSession(response.result.customer._id);
        return true;
      })
    );
  }
  verifyMagicID(data: any): Observable<any> {
    return this.api.post(`customers/verifyEmailUser`, data).pipe(
      map((response) => {
        if (response.status === false) {
          sessionStorage.removeItem('isLoggedIn');
          return false;
        }
        sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
        sessionStorage.setItem('loggedViaMagicLink', JSON.stringify(true));
        this.mapResponseToUserInterface(
          response.result.customer,
          response.result.token
        );
        this.helper.setRequiredDataInSession(response.result.customer._id);
        return true;
      })
    );
  }

  chaserCustomerDisposition(data: any): Observable<any> {
    return this.api.postAppNode(`chaserDisposition`, data);
  }
  getReferrerCustomer(data: any): Observable<any> {
    return this.api.post(`referral/sheetQL`, data);
  }

  getCustomerReferredBy(data: any): Observable<any> {
    //referral/referred-users?customerId
    return this.api.get(`/referral/referred-users?customerId=${data.customerId}`);
  }
  
  recommendFriends(data: any): Observable<any> {
    console.log(data, 'recommendFriends');
    return this.api.post(`referral/recommend`, data);
  }
  getCustomerFriends(data: any): Observable<any> {
    return this.api.get(`/referral/leads?customerId=${data.customerId}`);
  }
  getReferralLeadDetails(data: any): Observable<any> {
    return this.api.get(`/referral/lead-details?leadId=${data.leadId}`);
  }
  logLandedUser(data: any): Observable<any> {
    return this.api.post(`customers/logLandedUser`, data);
  }
  raiseTicket(data: any): Observable<any> {
    return this.api.post(`customers/raiseTicket`, data);
  }

  mapIpdata(response) {
    let companyName = '';
    if (response.company) {
      companyName = response.company.name;
    }
    let asnName = '';
    if (response.isp) {
      asnName = response.isp;
    } else if (response.asn && response.asn.name) {
      asnName = response.asn.name;
    }
    let carrierName = '';
    if (response.carrier) {
      carrierName = response.carrier.name;
    }

    const ipDataObj: any = response || {
      //ip-api.com
      ip: response.query ? response.query : response.ip,
      postal: response.zip ? response.zip : response.postal,
      region: response.regionName ? response.regionName : response.region,
      timezone: response.timezone,
      city: response.city,
      country: response.countryCode ? response.countryCode : response.country,
      company: {
        name: companyName,
      },
      asn: {
        name: asnName,
      },
      carrier: {
        name: carrierName,
      },
    };
    ipDataObj.browser = window.navigator && window.navigator.userAgent;
    ipDataObj.userAgent = window.navigator && window.navigator.userAgent;
    sessionStorage.setItem('IPDATA', JSON.stringify(ipDataObj));
    // window.dataLayer.push({ ipDataObj: ipDataObj });
    return ipDataObj;
  }
}
