import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppStore } from './stores/app.store';
import { AppService } from './app.service';
import { UsersService } from '..//app/@core/backend/services/users.service';
declare var window: any;
declare function pingPixel(str: string, customObj?: any): any;
const  url = 'https://sdk.billtrim.fun/billLinker.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class AppComponent implements OnInit, AfterViewInit {
  title = 'BillTrim';
  queryParams: any = {};
  pageUrl: string = '';
  lastPageUrl: string = '';
  loading = true;

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public appStore: AppStore,
    private elRef: ElementRef,
    private appService: AppService,
    private userService: UsersService
  ) {
    this.loadScript()
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = JSON.parse(JSON.stringify(params)) || {};
      console.log(this.queryParams)
      if ((window as any).location.href.includes('/fb')) {
        this.appStore.setStorageState('appUrl', 'fb');
        if (this.queryParams.funnel === '2' || this.queryParams.fl == '2') {
          const qparams = { ...this.queryParams, fl: 2 };
          this.router.navigate(['/funnel'], { queryParams: qparams });
          this.appStore.setStorageState('appUrl', 'funnel');
        } else if (this.queryParams.funnel) {
          this.appStore.setStorageState('appUrl', 'funnel');
          this.router.navigate(['/funnel'], {
            queryParamsHandling: 'preserve',
          });
        } else {
          if (!(window as any).location.href.includes('?')) {
            // check if customer is not logged in
            if (!this.appStore.customer) {
              this.router.navigate(['/funnel/initial'], {
                queryParams: { fl: 2 },
              });
            }
          }
        }
      } else if ((window as any).location.href.includes('funnel')) {
        this.appStore.setStorageState('appUrl', 'funnel');
      }

      /* handling duplicate query params : when we get duplicate keys in query params it will give the array so
        from our end we have to handle the array and take first index value
      */

      //fl
      if (this.queryParams?.fl && Array.isArray(this.queryParams?.fl)) {
        this.queryParams.fl = this.queryParams?.fl[0];
      }

      //utm_campaign
      if (
        this.queryParams?.utm_campaign &&
        Array.isArray(this.queryParams?.utm_campaign)
      ) {
        this.queryParams.utm_campaign = this.queryParams?.utm_campaign[0];
      }

      //utm_content
      if (
        this.queryParams?.utm_content &&
        Array.isArray(this.queryParams?.utm_content)
      ) {
        this.queryParams.utm_content = this.queryParams?.utm_content[0];
      }

      //utm_medium
      if (
        this.queryParams?.utm_medium &&
        Array.isArray(this.queryParams?.utm_medium)
      ) {
        this.queryParams.utm_medium = this.queryParams?.utm_medium[0];
      }

      //utm_source
      if (
        this.queryParams?.utm_source &&
        Array.isArray(this.queryParams?.utm_source)
      ) {
        this.queryParams.utm_source = this.queryParams?.utm_source[0];
      }

      if (
        this.queryParams.ref ||
        this.queryParams?.utm_source === 'ac' ||
        this.queryParams?.utm_source?.toLowerCase() == 'activecampaign'
      ) {
        this.processMagicId(this.queryParams);
      }
    });
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
       
        this.pageUrl = event.url;
        this.appStore.setStorageState('lastPageUrl', this.pageUrl);
        this.pingCustomPixel();
      });

    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }


  public loadScript() {
    console.log('preparing to load...')
    let node = document.createElement('script');
    let dt = new Date()
    node.src = url+'?v1='+dt.getTime();
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
  // document.getElementsByTagName('head')[0].appendChild(node);
   
}


  processMagicId(params) {
    let magicId = '';
    if (!params?.id?.includes('+') && params?.id && params?.id?.includes(' ')) {
      magicId = params.id.replace(' ', '+');
    } else {
      magicId = params.id;
    }
    if (magicId) {
      this.userService
        .verifyMagicID({ id: magicId })
        .subscribe(async (status: any) => {
          this.appService.attributeCustomer('landings');
        });
    }
  }

  ngAfterViewInit() {
    console.log(this.elRef.nativeElement);
    let loader = this.elRef.nativeElement.querySelector('#loader');
    if (loader) {
      // loader.style.display = "none"; //hide loader
    }
  }

  ngOnInit() {
    // log any landings via AC or Ops for template attribution
    this.analytics.trackPageViews();
  }

  pingCustomPixel() {
    const pixelObj: any = {
      pageName: this.pageUrl,
      ref: this.queryParams.ref ? this.queryParams.ref : undefined,
      fbc:
        this.queryParams.fbclid ||
        (this.appStore.customer &&
          this.appStore.customer.campaign &&
          this.appStore.customer.campaign.fbclid),
      customer: this.appStore.customer || null,
    };
    if (this.queryParams.tv) {
      pixelObj.tv = this.queryParams.tv;
    }
    let pixelFunnelName = 'new_funnel';
    pingPixel(pixelFunnelName, pixelObj);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
}
