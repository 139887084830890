import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  animate,
  style,
  query,
  group,
  animateChild,
} from '@angular/animations';
import { Router } from '@angular/router';
import { BillsService } from '../@core/backend/services/bills.service';
import { BillsStore } from '../@core/stores/bills.store';
import { UserStore } from '../@core/stores/user.store';
import { SqueezeService } from '../fb/services/squeeze.service';
import { SupportService } from '../fb/services/support.service';
import { environment } from '../../environments/environment';
import { AppStore } from '../stores/app.store';

let isMobile = true;
const enterAnimation = trigger('enterAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(10%)', opacity: 0.5 }),
    animate(
      `${isMobile ? '200ms' : '500ms'} ease-in-out`,
      style({ transform: 'translateX(0)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0)', opacity: 0 }),
    animate(
      `${isMobile ? '200ms' : '1000ms'} ease-in-out`,
      style({ transform: 'translateX(-10%)', opacity: 0 })
    ),
  ]),
]);

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  animations: [enterAnimation],
})
export class SupportComponent implements OnInit {
  isProd = environment.production;
  showHamBurgerMenu = false;
  showSidebar = false;
  isMobile = true;
  tktErr:string=''
  constructor(
    private squeezeService: SqueezeService,
    private supportService: SupportService,
    private router: Router,
    private userStore: UserStore,
    private billsStore: BillsStore,
    private billsService: BillsService,
    private appStore: AppStore
  ) {
    this.squeezeService.setDevices();
  }
  //TODO: rename topics = category
  topics: any = [
    {
      name: 'Negotiation OR Refunds',
      id: 'negotiation',
    },
    {
      name: 'Account OR Payment Options ',
      id: 'account',
    },
    // {
    //   name: "Billing",
    //   id: "billing",
    // },
    // {
    //   name: 'Website',
    //   id: 'website'
    // }
  ];
  //TODO: rename categories = subCategories

  categories: any = [
    {
      categoryId: 'negotiation',
      id: 'negotiationTakingLong',
      name: 'My negotiation is taking long',
      showSelectAccount: true,
      problemTitle: 'Negotiation is taking long',
    },
    {
      categoryId: 'negotiation',
      id: 'dintGetSavingsConfirmationEmail',
      name: 'I didn’t get the savings confirmation email',
      showSelectAccount: true,
      problemTitle: 'You didn’t get the savings confirmation email',
    },
    {
      categoryId: 'negotiation',
      id: 'dintGetPromisedSavings',
      name: 'I didn’t get the savings I was promised',
      showSelectAccount: true,
      problemTitle: 'You didn’t get the savings that was promised',
    },
    {
      categoryId: 'negotiation',
      id: 'lostFeatures',
      name: 'I lost some features',
      showSelectAccount: true,
      problemTitle: 'You have lost some features',
    },
    {
      categoryId: 'negotiation',
      id: 'chargedIncorrectly',
      name: 'I was charged incorrectly',
      showSelectAccount: true,
      problemTitle: 'You were charged incorrectly',
    },
    {
      categoryId: 'negotiation',
      id: 'negoDifferentIssue',
      name: 'I have a different issue',
      showSelectAccount: true,
      problemTitle: 'You have some other issue',
    },
    {
      categoryId: 'website',
      id: 'troubleWithWebsite',
      name: 'I am having trouble with website',
      showSelectAccount: false,
      problemTitle: 'You are having trouble with the website',
    },
    {
      categoryId: 'account',
      id: 'unableToSignin',
      name: 'I can’t sign in',
      showSelectAccount: false,
      problemTitle: 'You are not able to sign in',
    },
    {
      categoryId: 'account',
      id: 'notGettingPasscode',
      name: 'I am not getting the one time passcode',
      showSelectAccount: false,
      problemTitle: 'You are not getting the one time passcode',
    },
    {
      categoryId: 'account',
      id: 'accountLocked',
      name: 'My account is locked',
      showSelectAccount: false,
      problemTitle: 'Your account is locked',
    },
    {
      categoryId: 'account',
      id: 'cancelMyAccount',
      name: 'I want to cancel my account',
      showSelectAccount: false,
      problemTitle: 'You want to cancel your BillTrim account',
    },
    {
      categoryId: 'account',
      id: 'updateInfo',
      name: 'I want to update my info',
      showSelectAccount: false,
      problemTitle: 'You want to update your profile information',
    },
    {
      categoryId: 'account',
      id: 'updateInfo',
      name: 'Updating payment method',
      showSelectAccount: false,
      problemTitle: 'You want to update your payment information',
    },
    {
      categoryId: 'account',
      id: 'otherAccountIssue',
      name: 'I have a different issue',
      showSelectAccount: false,
      problemTitle: 'You have some other issue',
    },
  ];
  filteredCategories = [];
  selectedTopic: any = null;
  selectedCategory: any = null;
  selectAccountInput: any = '0';
  page = {
    name: 'selectTopic',
  };
  customer: any;
  accounts: any;
  btnLoading = false;
  feedbackInput: any = '';
  ticketNumber: any = '';

  ngOnInit() {
    if (isMobile) {
      this.isMobile = this.squeezeService.isMobile;
      isMobile = this.squeezeService.isMobile;
      console.log(isMobile, 'isMobile');
    }
    this.customer = this.userStore.getUser();
    if (!this.customer) {
     
      this.router.navigateByUrl('/fb/?page=login&pref=support');
    }
    this.accounts = this.billsStore.getBills();
    if (!this.accounts) {
      this.getAllAccounts();
    }
  }

  submitCta(page?) {
    const pageName = page || this.page.name;
    switch (pageName) {
      case 'selectTopic':
        this.selectTopic();
        break;
      case 'selectCategory':
        this.selectCategory();
        break;
      case 'submitFeedback':
        this.raiseTicket();
        break;
      case 'ticketRaised':
        if (this.appStore.getStorageState('appUrl') == 'fb') {
          this.router.navigate(['fb/dashboard']);
        } else {
          this.router.navigate(['/dashboard']);
        }

        break;
      default:
        this.gotoPage('selectTopic');
        break;
    }
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  selectTopic() {
    this.filteredCategories = this.categories.filter(
      (i) => i.categoryId === this.selectedTopic.id
    );
    this.gotoPage('selectCategory');
  }
  selectCategory() {
    this.gotoPage('submitFeedback');
  }

  raiseTicket() {
  
  let acc = this.categories.filter(i => i.id==this.selectedCategory.id)
console.log(acc)
if(acc[0].showSelectAccount)
{
  if (this.selectAccountInput && this.selectAccountInput == '0') 
  {
    this.tktErr = 'select account type'
    return;
  }
  else{
    this.tktErr = ''
  }
}
  
    const ticketParams: any = {
      category: this.selectedCategory.id,
      topic: this.selectedTopic.id,
      feedback: this.feedbackInput,
      customerId: this.customer.id,
      customerPhone: this.customer.phone,
      customerName:
        this.customer.fullname ||
        this.customer.firstName + ' ' + this.customer.lastName,
    };
    if (this.selectAccountInput && this.selectAccountInput !== '0') {
      ticketParams.accountType = this.selectAccountInput;
      if (this.accounts && this.accounts.length) {
        const selectedAccount = this.accounts.find(
          (i) => i.type === this.selectAccountInput
        );
        ticketParams.accountId = selectedAccount.id;
      }
    }
    this.supportService.raiseTicket(ticketParams).subscribe(
      (data) => {
        if (data.result && data.result.ticket) {
          this.ticketNumber = data.result.ticket.ticketId;
        }
        this.gotoPage('ticketRaised');
      },
      (error) => {
        this.tktErr = 'Some error occurred'
       // this.gotoPage('ticketRaised');
      }
    );
  }

  gotoPage(page) {
    this.btnLoading = false;
    let newPageObj: any = {};
    if (this.page.name !== page) {
      // Calling Init functions for pages
      const functionName = `init${
        page.charAt(0).toUpperCase() + page.slice(1)
      }`;
      if (this[functionName]) {
        newPageObj = this[functionName]();
      } else {
        console.log('no init function found for ', page);
      }
    }
    if (newPageObj && newPageObj.pageName) {
      page = newPageObj.pageName;
      const functionName = `init${
        page.charAt(0).toUpperCase() + page.slice(1)
      }`;
      if (this[functionName]) {
        newPageObj = this[functionName]();
      } else {
        console.log('no init function found for ', page);
      }
    }

    this.page.name = page;
  }

  gotoPreviousPage() {
    switch (this.page.name) {
      case 'selectCategory':
        this.gotoPage('selectTopic');
        break;
      case 'submitFeedback':
        this.gotoPage('selectCategory');
        break;
      default:
        break;
    }
  }

  getAllAccounts() {
    console.log('getting accounts');
    const accountQuery = {
      customerId: this.customer.id,
      category: 'personal',
    };
    this.billsService.getAll(accountQuery).subscribe((resp) => {
      this.accounts = this.billsStore.getBills();
    });
  }
}
