<div *ngIf="showDash" class="wrapper h-100">
  <div class="header-block d-flex justify-content-between align-items-center">
    <img src="../../assets/images/fb/logo.svg" alt="billtrim" />
    <div class="nav-profile-name d-flex align-items-center">
      <!-- <span>Welcome, {{ this.customer?.firstName }}</span>
      <img src="../../assets/images/icons/icon-dropdown.svg" alt="" /> -->
      <div ngbDropdown>
        <!-- **here** -->
        <div class="nav-profile-name hand" ngbDropdownToggle>
          {{
            customer?.firstName
              ? customer.firstName + ' ' + customer.lastName
              : 'Hi 👋'
          }}
        </div>
        <div ngbDropdownMenu>
          <!-- <button ngbDropdownItem [routerLink]="['/fb/profile']">
            Account Settings
          </button> -->
          <button ngbDropdownItem (click)="openSetting()">Settings</button>
          <button ngbDropdownItem (click)="referFriend()">Refer Friends</button>

          <button ngbDropdownItem (click)="openSupport()">Help</button>
          <button ngbDropdownItem (click)="logout()">Logout</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Fixed White Background -->
  <div class="content-block bg-white">
    <div class="container-large mt-1">
      <div class="section-heading">
        <h1 class="heading text-center">
          Welcome, {{ this.customer?.firstName }}
        </h1>

      
  
      
   
  

      </div>
        <!--Show notification -->
 <div *ngIf="shownotification" class="linked-bills-wrap bg-white">
  <div class="container-large">
    <div class="section-headin" >
      <h2 class="heading heading-notify font-weight-normal"> 
        Notifications
      </h2>
    </div>

   
    <div class="reLinkBillCont" *ngFor="let item of notificArr">
      <div class="d-flex">
        <div class="px-2">
          <img src="../../assets/images/alert_warning.svg" />
        </div>
        <div>
          <div class="card-ttl">{{item.msg}}</div>
        
          <div style="cursor:pointer" class="relink-bil d-flex hand" (click)="openLink(item.link)">
            <div>Take Action</div>
            <div>
              <img
                class="chevron"
                src="../../assets/images/fb/chevron_right.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  

  
  
  </div>
</div>

    
    </div>
  </div>

  <div  *ngIf="customer?.savingsSummary?.totalSavings" class="background-pattern py-4 mb-5 mt-5">
    <div
      class="float-card text-center center-max"
     
    >
      <div class="savings-title">You saved</div>
      <div class="savings-lg my-2">
        ${{ customer?.savingsSummary?.totalSavings | number: '1.2-2' || 0 }}
      </div>

      <div
        class="savings-info-text"
        *ngIf="customer?.savingsSummary?.totalSavings"
      >
        <span *ngIf="customer?.savingsSummary?.totalSavings > 500">
          💡 Equivalent to a relaxing vacation with your family 🏖️
        </span>
        <span
          *ngIf="
            customer?.savingsSummary?.totalSavings > 150 &&
            customer?.savingsSummary?.totalSavings < 500
          "
        >
          💡 Equivalent to a flight to your favorite destination ✈️
        </span>
        <span
          *ngIf="
            customer?.savingsSummary?.totalSavings > 50 &&
            customer?.savingsSummary?.totalSavings < 150
          "
        >
          💡 Equivalent to
          {{
            customer?.savingsSummary?.totalSavings / 10 | number: '1.0-0'
          }}
          meals 🍔
        </span>
        <span
          *ngIf="
            customer?.savingsSummary?.totalSavings > 0 &&
            customer?.savingsSummary?.totalSavings < 50
          "
        >
          💡 Equivalent to
          {{ customer?.savingsSummary?.totalSavings / 7 | number: '1.0-0' }}
          coffee ☕
        </span>
      </div>
    </div>
  </div>

  <!-- Fixed Grey Background   show lower your bill icons-->
  <div class="content-block background-grey" *ngIf="showLowerBills">
    <div class="container-large">
      <div class="bill-types-wrap d-flex justify-content-around">
        <div class="bill-type-box col">
          <div class="bill-type-content"  (click)="openLinkType('bills')">
            <div class="bill-type-round">
              <img src="../../assets/images/icons/bill_dollars.svg" alt="" />
            </div>
            <div class="bill-type-add">
              <img src="../../../../assets/images/fb/add.svg" alt="" />
            </div>
          </div>
          <h3>Lower bills</h3>
        </div>

        <div class="bill-type-box col">
          <div class="bill-type-content"  (click)="openLinkType('billpay')" >
            <div class="bill-type-round">
              <img src="../../assets/images/icons/bill_paid.svg" alt="" />
            </div>
            <div class="bill-type-add">
              <img src="../../../../assets/images/fb/add.svg" alt="" />
            </div>
          </div>
          <h3>Pay bills</h3>
        </div>

        <!-- <div class="bill-type-box col" (click)="openLinkType('bank')">
          <div class="bill-type-content">
            <div class="bill-type-round">
              <img src="../../../../assets/images/fb/bank_icon.svg" alt="" />
            </div>
            <div class="bill-type-add">
              <img src="../../../../assets/images/fb/add.svg" alt="" />
            </div>
          </div>
          <h3>FIGHT BANK FEES</h3>
        </div> -->
      </div>
    </div>
  </div>

 
  <!-- Fixed White Background -->
  <!--Show linked bills upcoming payment -->
  <div class="linked-bills-wrap bg-white">
    <div class="container-large">
      <div class="section-heading" *ngIf="linkedPayments.length">
        <h2 class="heading font-weight-normal">Upcoming payments</h2>
      </div>

      <div
        class="d-flex align-items-center position-relative upcoming-payments card-width"
        *ngIf="upcomingPayments?.length"
      >
        <div class="upcoming-payments-text mr-auto">
          No hidden charges detected
        </div>
        <img
          class="upcoming-payments-icon"
          src="../../../../assets/images/fb/security.svg"
        />
        <div class="card-tag-line">BillPay enabled</div>
      </div>

      <div class="bills-listing mt-5">
        <div
          class="bills-item"
          *ngFor="let bill of upcomingPayments; let i = index"
          [ngClass]="{ 'item-border': upcomingPayments.length > i + 1 }"
          (click)="openBillPayDetails(bill)"
        >
          <div class="bills-content-wp">
            <div class="bills-icon">
              <img
                src="../../../../assets/images/fb/bill-types/{{
                  bill.type || (bill.billTypes.length && bill.billTypes[0])
                }}.svg"
              />
            </div>
            <div>
              <div class="bill-list-item-content-name">
                <h3>
                  {{
                    bill.account.providerName ||
                      billsConfig[
                        bill.type ||
                          (bill.billTypes.length && bill.billTypes[0])
                      ]?.displayName
                  }}
                </h3>
              </div>
              <div class="bill-list-item-content-due">
                Due {{ bill.billDueDate | date: 'mediumDate' }}
              </div>
            </div>
          </div>
          <div class="d-flex-c">
            <div
              class="bill-list-item-amount font-amount"
              *ngIf="bill.billAmount"
            >
              ${{ bill.billAmount }}
            </div>
            <div class="ml-2">
              <img
                src="../../assets/images/icons/icon-chevron-right.svg"
                alt="Arrow"
                class="chevron"
              />
            </div>
          </div>
        </div>
      </div>

   <!--    <div class="text-center" *ngIf="upcomingPayments?.length">
        <div
          class="learn-more pt-3 hand"
          [routerLink]="['/all-upcoming-bills']"
        >
          View All
          <img
            class="chevron"
            src="../../../../assets/images/fb/chevron_right.svg"
          />
        </div>
      </div>
 -->
      <div
        class="text-center"
        *ngIf="linkedPayments.length && !upcomingPayments.length"
      >
        <div class="learn-more pt-3 ">No upcoming bills to pay as of now</div>
      </div>
    </div>
  </div>

  






  <!-- Fixed Grey Background -->
  <div class="linked-bills-wrap background-grey">
    <div class="container-large">
      <div class="section-heading">
        <h2 class="heading font-weight-normal">Linked bills</h2>
      </div>

      <div class="bills-listing">
        <div
          class="bills-item"
          *ngFor="let bill of linkedPayments; let i = index"
          [ngClass]="{ 'item-border': linkedPayments.length > i + 1 }"
          (click)="openBillPayDetails(bill)"
        >
          <div class="bills-content-wp">
            <div class="bills-icon">
              <img
                src="../../../../assets/images/fb/bill-types/{{
                  bill.type || (bill.billTypes.length && bill.billTypes[0])
                }}.svg"
              />
            </div>
            <h3>
              {{
                bill.account.providerName ||
                  billsConfig[
                    bill.type || (bill.billTypes.length && bill.billTypes[0])
                  ]?.displayName
              }}
            </h3>
          </div>
          <!-- <div class="bills-value" *ngIf="bill.totalSavings">
            <span class="price">${{ bill.totalSavings || '0' }}</span>
            <span class="statement">Last payment</span>
          </div> -->

          <div class="d-flex-c">
            <div
              class="bill-total-savings text-right"
              *ngIf="bill.billpayStage === 'savingsFound'"
            >
              <span class="total">${{ bill.totalSavings || '0' }}</span>
              <span class="label-sm">Total savings</span>
            </div>
            <div
              class="bill-total-savings text-right"
              *ngIf="
                bill.billpayStage === 'awaitingCustomerResponse' ||
                bill.billpayStage === 'pdfMissing' ||
                bill.billpayStage === 'negotiationRequired' ||
                bill.billpayStage === 'qaRejected'
               
              "
            >
              <span class="total"
                ><img src="../../../../assets/images/fb/green-dot.svg"
              /></span>
              <span class="label-sm">Negotiating</span>
            </div>
            <div
              class="bill-total-savings text-right"
              *ngIf="(bill.billpayStage === 'qaApproved' ||
              bill.billpayStage === 'customerNotified') &&  bill.account.billFetched == 1  "
            >
              <span class="total"
                ><img src="../../../../assets/images/fb/green-dot.svg"
              /></span>
              <span class="label-sm">Negotiation Completed</span>
            </div>

            <div
              class="bill-total-savings text-right"
              *ngIf="(bill.account.needCustomerFor2FA==false &&
               bill.account.billFetched > 0 && 
                bill.account.loginAndVerificationSuccess==true
                 && bill.account.lastScraperRun &&
                  bill.account.auth_verified==1)" >
              <span class="total"
                ><img src="../../../../assets/images/fb/green-dot.svg"
              /></span>
              <span class="label-sm">Analyzing</span>
            </div>

            <div
            class="bill-total-savings text-right"
            *ngIf="
           ( (bill.account.billFetched == 0 &&  bill.account.loginAndVerificationSuccess==true
               && bill.account.lastScraperRun &&  bill.account.auth_verified==1) || !getLastScrapperTime(bill)) 
            "
          >
            <span class="total"
              ><img src="../../../../assets/images/fb/green-dot.svg"
            /></span>
            <span class="label-sm">Processing</span>
          </div>

          <div
          class="bill-total-savings text-right"
          *ngIf="(bill.account.auth_verified==1 && 
          bill.account.needCustomerFor2FA==true && 
            bill.account.billFetched == 0 && getLastScrapperTime(bill)) 
          "
        >
          <span class="total">
            <img src="../../../../assets/images/fb/red_error.svg"
          /></span>
          <span class="label-sm">2FA Required</span>
        </div>
            <div
              class="bill-total-savings text-right"
              *ngIf="((bill.account.auth_verified===-1 || bill.account.auth_verified===0) && 
                (bill.account.billFetched === 0 || bill.account.billFetched === -1)  && getLastScrapperTime(bill)) 
              "
            >
              <span class="total">
                <img src="../../../../assets/images/fb/red_error.svg"
              /></span>
              <span class="label-sm">Out of Sync </span>
            </div>
            <div
              class="bill-total-savings text-right"
              *ngIf="
                bill.billpayStage === 'customerError' ||
                bill.billpayStage === 'inCorrectPin' ||
                bill.billpayStage === 'chargeFailed' ||
                bill.billpayStage === 'paymentFailed' ||
                bill.billpayStage === 'cardLoadFailed' ||
                bill.billpayStage === 'vccNotLinked'
              "
            >
              <span class="total"
                ><img src="../../../../assets/images/fb/red_error.svg"
              /></span>
              <span class="label-sm">Failed</span>
            </div>
            <div
              class="bill-total-savings text-right"
              *ngIf="bill.billpayStage === 'onBestDeal'"
            >
              <span class="total"
                ><img src="../../../../assets/images/fb/green-dot.svg"
              /></span>
              <span class="label-sm">On Best Deal</span>
            </div>
            <div
            class="bill-total-savings text-right"
            *ngIf="
              bill.account.billFetched == 1 &&
              bill.billpayStage === 'chargePending'
            "
          >
            <span class="total"
              ><img src="../../../../assets/images/fb/green-dot.svg"
            /></span>
            <span class="label-sm">Pending</span>
          </div>
          </div>
        </div>
      </div>

   <!--    <div class="text-center" *ngIf="linkedPayments.length && linkedPayments.length>2">
        <div class="learn-more pt-3 hand" [routerLink]="['/all-linked-bills']">
          View All
          <img
            class="chevron"
            src="../../../../assets/images/fb/chevron_right.svg"
          />
        </div>
      </div> -->

      <div class="text-center" *ngIf="!linkedPayments.length">
        <div class="learn-more pt-3 ">You have not linked any bills yet</div>
      </div>
    </div>
  </div>

  <!-- Fixed White Background -->
  <div *ngIf="showFeedbackPanel" class="d-card mx-auto py-5 bg-white">
    <div *ngIf="!showRateThankYouMsg">
      <div class="section-heading">
        <h2 class="heading font-weight-normal">Rate your experience</h2>
      </div>
      <div class="row justify-content-center mx-auto py-3">
        <div class="col-auto text-center">
          <div
            class="experience"
            title="Hate it"
            [ngClass]="{ active: givenRating === 1 }"
            (click)="submitRatings(1)"
          >
            😡
          </div>
        </div>
        <div class="col-auto text-center">
          <div
            class="experience"
            title="Not impressed"
            [ngClass]="{ active: givenRating === 2 }"
            (click)="submitRatings(2)"
          >
            🙁
          </div>
        </div>
        <div class="col-auto text-center">
          <div
            class="experience"
            title="Happy"
            [ngClass]="{ active: givenRating === 4 }"
            (click)="submitRatings(4)"
          >
            🙂
          </div>
        </div>
        <div class="col-auto text-center">
          <div
            class="experience"
            title="Love it"
            [ngClass]="{ active: givenRating === 5 }"
            (click)="submitRatings(5)"
          >
            😍
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showRateThankYouMsg && !showFeedbackReasonInput">
      <div class="row justify-content-center background-grey mx-auto py-3">
        <div class="col-auto text-center thank-you-text">
          <img class="" src="../../../../assets/images/fb/tick.svg" />
          Thank you for your valued feedback!
        </div>
      </div>
    </div>
    <div *ngIf="showFeedbackReasonInput" class="rating-max mx-auto">
      <!-- <div class="page-title text-center mb-4">Help us improve</div> -->
      <div class="text-center my-3">
        <span *ngIf="givenRating === 1 || givenRating === 2"
          >We’re sorry we let you down. We’d love to hear what went wrong and
          what we could do better.</span
        >
        <span *ngIf="givenRating === 4"
          >We’re glad to hear that! We’d love to know what we can do
          better.</span
        >
        <span *ngIf="givenRating === 5">
          Thank you for rating us 5-star! Please rate us on
          <a href="https://www.trustpilot.com/review/billtrim.com"
            >TrustPilot</a
          >
          to help us spread the word!
          <br />
          <br />
          We’re glad to hear that! If there’s anything else, please let us
          know.</span
        >
      </div>
      <div>
        <div class="input-group">
          <div class="form-label-group">
            <textarea
              id="inputFeedback"
              class="form-control"
              placeholder="WRITE YOUR FEEDBACK (optional)"
              [(ngModel)]="customerFeedBackReason"
              cols="30"
              rows="4"
              autofocus
            ></textarea>
          </div>
        </div>
      </div>
      <div class="my-4">
        <button
          type="button"
          class="btn btn-primary mx-auto"
          (click)="submitReason()"
        >
          SUBMIT FEEDBACK
        </button>
        <div
          class="page_link text-center mt-4"
          *ngIf="givenRating < 4"
          [routerLink]="['/support']"
        >
          🎟️ Raise a ticket
        </div>
      </div>
    </div>
  </div>

  <!-- Fixed Grey Background -->
  <div class="d-card mx-auto pt-5 background-grey">
    <div class="text-center offer-max mx-auto">
      <div class="section-heading">
        <h2 class="heading font-weight-normal">Refer & Earn</h2>
      </div>
      <div>
        <circle-progress
          [percent]="(referralStats?.earnedAmount / 50) * 100 || 0"
          [animation]="true"
          [animationDuration]="300"
          [backgroundPadding]="7"
          [radius]="125"
          [space]="-15"
          [outerStrokeWidth]="16"
          [outerStrokeColor]="'#000000'"
          [innerStrokeColor]="'#e7e8ea'"
          [innerStrokeWidth]="16"
          [title]="'$' + (referralStats?.earnedAmount || 0)"
          [titleFontSize]="50"
          [subtitle]="'Cash Out At $50'"
          [subtitleFontSize]="15"
          [animateTitle]="true"
          [showUnits]="false"
          [clockwise]="true"
        ></circle-progress>
        <span class="you-earned">YOU EARNED</span>
      </div>
      <div class="d-card-cont-title mt-3 mb-4 text-uppercase">
        Share on social media
      </div>
      <div
        class="row text-center d-flex justify-content-center py-3 background-grey"
      >
        <div class="col-2 mb-3" (click)="socialShare('fb')">
          <a class="share-link hand" href="javascript:void(0)"
            ><img
              class=""
              src="../../../../assets/images/fb/share-facebook.svg"
          /></a>
        </div>

        <div class="col-2 mb-3">
          <a
            class="share-link hand"
            href="mailto:?subject=Try BillTrim!&body=I just tried BillTrim.com and saved some money on my bills. It was totally wroth it! Give it a shot when you can. Here is the link: https://billtrim.com?ref="
            ><img class="" src="../../../../assets/images/fb/mail.svg"
          /></a>
        </div>

        <div class="col-2 mb-3" (click)="socialShare('twitter')">
          <a class="share-link hand" href="javascript:void(0)"
            ><img class="" src="../../../../assets/images/fb/share-twitter.svg"
          /></a>
        </div>

        <div class="col-2 mb-3" (click)="socialShare('linkedin')">
          <a class="share-link hand" href="javascript:void(0)"
            ><img
              class=""
              src="../../../../assets/images/fb/share-linkedin.svg"
          /></a>
        </div>
      </div>
      <div class="separator my-3">
        <span style="background-color: #f7f7f7">OR</span>
      </div>
      <button
        type="button"
        class="btn btn-primary mx-auto my-3"
        (click)="
          this.helperService.copyToClipboard(
            'https://billtrim.com/?rc=' + this.customer.referralCode
          )
        "
      >
        COPY YOUR UNIQUE LINK
      </button>
      <div class="learn-more pt-3 hand" [routerLink]="['/fb/referrals']">
        Learn More
        <img
          class="chevron"
          src="../../../../assets/images/fb/chevron_right.svg"
        />
      </div>
    </div>
  </div>

 <app-footer></app-footer>
</div>
