import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { LoggingService } from "./logging.service";
import { ErrorService } from "./error.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);

    let message: any;
    let stackTrace: any;
    let dataType: any;
    if (error instanceof HttpErrorResponse) {
      dataType = "ServerError";
      message = errorService.getServerErrorMessage(error);
      stackTrace = errorService.getServerErrorStackTrace(error);
    } else {
      dataType = "ClientError";
      message = errorService.getClientErrorMessage(error);
      stackTrace = errorService.getClientErrorStackTrace(error);
    }

    logger.logData(dataType, message, stackTrace);
    console.error(error);
  }
}
