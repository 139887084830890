import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpService } from '../services/http.service';
import { catchError, map } from 'rxjs/operators';
import { Bill } from '../../interfaces/bills';
import { bills } from '../../../@config/bills';
import { BillsStore } from '../../../@core/stores/bills.store';
import { UserStore } from '../../../@core/stores/user.store';
import { environment } from '../../../../environments/environment';

declare function markLeftNavLink(bool: boolean, str: string): any;

@Injectable({
  providedIn: 'root',
})
// ping API and get the raw response without any mapping or massaging
export class BillsApi {
  private readonly apiController: string = 'accounts'; // main resource name
  private billsOrder = [
    'cellphone',
    'cable',
    'internet',
    'homePhone',
    'homeSecurity',
    'auto',
    'home',
    'electricity',
  ];

  config = {
    bills,
  }; // from @config

  constructor(
    private api: HttpService,
    private billsStore: BillsStore,
    private userStore: UserStore
  ) {}

  getAll(data: any): Observable<any> {
    if (!data?.customerId) return of(null);
    return this.api.post(`customers/getCustomerAccounts`, data).pipe(
      map((response) => {
        return this.mapResponseToBillsInterface(response);
      })
    );
  }

  update(item: any): Observable<any> {
    return this.api.post(`accounts/updateAccount`, item);
  }

  addNotes(data: any): Observable<any> {
    return this.api.post(`customers/addNotes`, data);
  }

  uploadPDF(data: any): Observable<any> {
    return this.api.post(`accounts/pdfUpload`, data, {
      enctype: 'multipart/form-data',
    });
  }

  getSignedURL(data: any): Observable<any> {
    return this.api.post(`customers/getSignedURL`, data).pipe(
      map((response) => {
        return response.result.signedUrl;
      })
    );
  }
  getStatements(data: any) {
    return this.api.post(`accounts/getBillStatements`, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getProviders(): Observable<any> {
    return this.api
      .post(`${environment.API_OLD_BASE_URL}/getProviders`, {})
      .pipe(
        map((response) => {
          const final = {};
          let temp = [];
          // filter extra providers we do not care about
          // tslint:disable-next-line:forin
          if (response.data && response.data.provider) {
            response = response.data;
            for (const billType in response.provider) {
              const providers = response.provider[billType];
              if (providers) {
                providers.forEach((provider) => {
                  if (provider.sequence < 7 || provider.name === 'Other') {
                    temp.push(provider);
                  }
                });
                final[billType] = temp;
                temp = [];
              }
            }
          }

          // save in store for future reference
          this.billsStore.setProviders(final);

          return response.provider;
        })
      );
  }

  startScpInstance(data: any): Observable<any> {
    return this.api.post(`accounts/startInstance`, data);
  }

  getScraperStatus(accountId: any): Observable<any> {
    return this.api.get(`accounts/getScraperStatus/${accountId}`);
  }

  getBillDetails(customerId: any): Observable<any> {
    // customerId = "5e79553f9042c60027e62366"; // test for local
    // postLocal test for local
    return this.api.post(`accounts/getBillDetails`, { customerId }).pipe(
      map((response) => {
        if (response && response.status && response.result) {
          const final = response.result;
          // save in store for future reference
          this.billsStore.setBillDetails(final);
          return final;
        } else {
          return null;
        }
      })
    );
  }

  verifyScpFlow(data: any): Observable<any> {
    return this.api.post(`customers/verifySCPProvider`, data);
  }

  getPartnerReferralUrl(data: any): Observable<any> {
    return this.api.post(`customers/getPartnerReferralUrl`, data);
  }

  getAccountStatusDetails(data: any): Observable<any> {
    return this.api.post(`accounts/accountStatus`, data).pipe(
      map((response) => {
        let b = [] as any;
        b = this.billsStore.getBill(data.billType);
        if (!b) {
          console.log('Bill not found!');
          return false;
        }
        b.accountStatusDetails = [];
        if (response.result.accountStatusDetails.length) {
          for (const [key, status] of Object.entries(
            response.result.accountStatusDetails[0]
          )) {
            const a: any = status; // Linting not letting go through without this
            const temp = {
              title: a.title,
              subTitle: a.subTitle[0],
              completed: a.completed,
              date: a.completed ? new Date().toDateString() : '',
            };

            b.accountStatusDetails.push(temp);
          }
        }
        this.billsStore.setBill(b);
        return true;
      })
    );
  }

  getSavings(data: any): Observable<any> {
    return this.api.post(`customers/getSavingSummary`, data).pipe(
      map((response) => {
        if (response.result.savings.savings.length) {
          // fetch & format all paymentsMade
          const paymentsMade = [];
          if (response.result.payments.payments.length) {
            response.result.payments.payments.forEach((payment) => {
              if (payment.charges >= 0) {
                paymentsMade.push({
                  _id: payment._id,
                  type: payment.type,
                  charges: payment.charges,
                  description: payment.description,
                  scheduleDate: payment.scheduleDate,
                  receipt_url: payment.receipt_url,
                  createdAt: payment.createdAt,
                });
              }
            });
          }

          // save summary numbers in user store for future purposes
          var oldestCreatedAt = '';
          var longestContractTerm = 0;
          response.result.savings.savings.forEach((saving) => {
            if (saving.totalSavings >= 0) {
              if (
                oldestCreatedAt === '' ||
                oldestCreatedAt > saving.createdAt
              ) {
                oldestCreatedAt = saving.createdAt;
              }
              if (longestContractTerm < saving.contractTerm) {
                longestContractTerm = saving.contractTerm;
              }
            } // skip looking at savings with negative total_savings
          });
          const summary = {
            totalOldBillAmount:
              response.result.savings.total_old_bill_amount.toFixed(2),
            totalNewBillAmount:
              response.result.savings.total_new_bill_amount.toFixed(2),
            totalOneTimeCredit:
              response.result.savings.total_one_time_credit.toFixed(2),
            totalYearlySavings: Math.round(
              ((response.result.savings.total_savings -
                response.result.savings.total_one_time_credit) /
                longestContractTerm) *
                12
            ),
            totalSavings: response.result.savings.total_savings,
            totalMonthlySavings: Math.round(
              (response.result.savings.total_savings -
                response.result.savings.total_one_time_credit) /
                longestContractTerm
            ), // average monthly savings across all bills
            totalNetSavings: Math.round(
              response.result.savings.total_savings * 0.75
            ),
            savings: response.result.savings.savings,
            createdAt: oldestCreatedAt,
            contractTerm: longestContractTerm,
          };
          this.userStore.setSavingSummary(summary);

          // loop through savings and add savings as well as payments to billsStore
          response.result.savings.savings.forEach((saving) => {
            if (saving.totalSavings >= 0) {
              let b = [] as any;
              b = this.billsStore.getBill(saving.type);
              if (b) {
                const index = b.savings.findIndex(
                  (svg) => svg._id === saving._id
                );
                if (index === -1) {
                  b.savings.push(saving);
                  b.totalSavings = b.totalSavings + saving.totalSavings;
                  b.keyStatus = this.helperCurrentKeyStatus(b);
                }
                b.payments = paymentsMade;
                this.billsStore.setBill(b);
              }
            }
          });

          this.billsStore.sortPaymentsPerBill(); // sort payments from old to new
          this.billsStore.sortSavingsPerBill(); // sort savings from old to new
          return true;
        } else {
          return false;
        }
      })
    );
  }

  mapResponseToBillsInterface(response) {
    if (response.result.accounts.length < 1) {
      return [];
    }
    const mappedResponse = [];
    let numBillTouched = 0;
    let numValidBills = 0;

    try {
      response.result.accounts.forEach((account) => {
        if (!this.config.bills[account.type]) {
          return;
        }
        var oldTS = new Date(account.createdAt).getTime();
        var nowTS = new Date().getTime();
        var numDaysSinceBillGiven = Math.round(
          (nowTS - oldTS) / (1000 * 3600 * 24)
        );

        const bill: Bill = {
          // identifiers and numbers
          id: account._id,
          providerId: account.provider_id,
          pin: account.pin,
          cust_id: account.cust_id,
          totalSavings: 0, // TODO
          estimatedSavings:
            account.estimatedSavingWithinProvider < 1
              ? '0'
              : account.estimatedSavingWithinProvider,
          latestBillAmount: account.billAmount || account.tempBillAmount,
          numDaysSinceBillGiven: numDaysSinceBillGiven,
          latestBillDueDate: account.billDueDate,

          // strings
          type: account.type,
          displayName: this.config.bills[account.type].displayName,
          icon: this.config.bills[account.type].icon,
          colorClass: this.helperGetColorClass(account),
          username: account.username,
          password: account.password,
          providerName:
            account.other_provider_name ||
            this.billsStore.getProviderName(account.type, account.provider_id),
          otherProviderName: account.other_provider_name || null,
          currentStatus: '', // gets calculated on the fly. see helperCurrentStatus below
          keyStatus: '',
          rejectType: account.rejectType,

          // booleans
          flags: {
            skipped: account.isSkipped,
            hasCredentials: account.hasCredentials
              ? true
              : !account.username && !account.password
              ? false
              : true,
            hasBillPDF: account.files.length > 0,
            negotiatorAssigned: account.negotiationsCompleted ? true : false,
            negotiationCompleted: account.negotiationsCompleted,
            paidServiceFee: false, // TODO:
          },

          // dates
          lastNegotiatedOn: '', // TODO:
          createdOn: account.createdAt || '',

          // objects
          pdfs: account.files,
          savings: [],
          payments: [],
          accountStatusDetails: [],
          FGSJUSI: {
            billFetched: account.billFetched,
            isTechnicalIssue: account.isTechnicalIssue,
            pin_verified: account.pin_verified,
            hasCredentials: account.hasCredentials,
            hasLatestBillDetails: account.hasLatestBillDetails,
            needCustomerFor2FA: account.needCustomerFor2FA,
            isPrimaryAccount: account.isPrimaryAccount,
            isFGSJUSIRunning: account.isScraperRunning,
            loginAndVerificationSuccess: account.loginAndVerificationSuccess,
            auth_verified: account.auth_verified,
            securtyQuestion_verified: account.securtyQuestion_verified,
            scrapingStuckReason: account.scrapingStuckReason,
          },
        };

        // IMP: alert scraper team (csr?) about bill details missing for bill older then 2 days
        if (!bill.flags.skipped) {
          if (bill.numDaysSinceBillGiven > 2) {
            if (!bill.providerName) {
              // console.log(`Critical: providerName is missing for ${bill.type} bill since ${bill.numDaysSinceBillGiven} days`);
            }
            if (!bill.latestBillAmount) {
              // console.log(`Critical: latestBillAmount is missing for ${bill.type} bill`);
            }
            if (!bill.latestBillDueDate) {
              // console.log(`Critical: latestBillDueDate is missing for ${bill.type} bill`);
            }
          }
          markLeftNavLink(false, bill.icon); // mark left nav tab as grey
        } else {
          markLeftNavLink(true, bill.icon); // mark left nav tab as grey
        }

        // calculate current status of each bill
        bill.currentStatus = this.helperCurrentStatus(bill);
        bill.keyStatus = this.helperCurrentKeyStatus(bill);

        mappedResponse.push(bill);
        if (bill.colorClass === 'txt-success') {
          numBillTouched = numBillTouched + 1;
        }
        if (bill.flags.hasBillPDF) {
          numValidBills = numValidBills + 1;
        }
      });
    } catch (e) {
      console.log(e);
    }

    // save in store for future reference
    let billList = Array(mappedResponse.length).fill(null);
    mappedResponse.forEach((i) => {
      billList[this.billsOrder.indexOf(i.type)] = i; // Ordering; bills in specific; order;
    });
    billList = billList.filter((i) => i != null);
    this.billsStore.setBills(billList);

    this.billsStore.setBillTouched(numBillTouched);
    this.billsStore.setValidBills(numValidBills);
    return mappedResponse;
  }

  helperGetColorClass(account) {
    let cls = '';
    if (account.isSkipped) {
      cls = 'txt-grey';
    } else if (
      account.isSkipped ||
      account.hasCredentials ||
      account.files.length > 0 ||
      (account.username && account.username.length > 0) ||
      (account.password && account.password.length > 0)
    ) {
      cls = 'txt-success';
    } else {
      cls = 'txt-danger';
    }
    return cls;
  }

  helperCurrentStatus(bill) {
    let status = '';

    if (
      !bill.flags.skipped &&
      !bill.flags.hasBillPDF &&
      !bill.flags.hasCredentials
    ) {
      status = 'You have not taken any action on this bill yet.';
    } else if (bill.flags.skipped) {
      status =
        'You chose to skip this account. We will hence neither negotiate nor monitor for any overpayment.';
    } else if (bill.flags.negotiationCompleted) {
      status = `Negotiation completed. Please visit the ${bill.displayName} tab for more details. `;
    } else if (bill.otherProviderName) {
      status =
        'Under negotiation with data validation pending. Our representatives will reach out to you via text to take this forward.';
    } else if (bill.flags.hasCredentials) {
      if (bill.FGSJUSI.hasLatestBillDetails) {
        status =
          'Credentials verified and account is under negotiation. We will update you upon completion.';
      } else if (bill.FGSJUSI.isTechnicalIssue) {
        // Last bill linking attempt failed. We will continue to retry linking on our end and update you accordingly.
        status =
          'We were not able to get your latest bill last time. Click the card to try again! :)';
      } else if (bill.FGSJUSI.needCustomerFor2FA) {
        status =
          'We need you to be online to clear the authentication with your provider. Click the card when ready! :)';
      } else if (bill.FGSJUSI.securtyQuestion_verified === 0) {
        // We need you to be online, to clear the authentication asked by the providers website and finish bill linking.
        status =
          'We need you to answer security questions from your provider. Click the card when ready! :)';
      } else if (!bill.FGSJUSI.isPrimaryAccount) {
        status =
          'We need primary account credentials as it helps us monitor the bill automatically every month. ';
      } else if (bill.FGSJUSI.scrapingStuckReason) {
        // Example: Password needs to be Updated, Pin needs to be updated, Service got discontinued
        status = `Issue: ${bill.FGSJUSI.scrapingStuckReason}. Please login to your provider and fix it so we can monitor your bill every month.`;
      } else {
        status =
          'Account is under negotiation. We will update you upon completion.';
      }
    } else if (bill.pdfs && bill.pdfs.length > 0) {
      status =
        'Under negotiation with file validation pending. Our representatives will reach out to you via text to take this forward.';
    }

    return status;
  }
  helperCurrentKeyStatus(bill) {
    let status = '';

    if (
      !bill.flags.skipped &&
      !bill.flags.hasBillPDF &&
      !bill.flags.hasCredentials &&
      !bill.pin
    ) {
      status = 'unTouched'; // show green + button
    } else if (bill.flags.skipped) {
      status = 'unTouched'; // show green + button
    } else {
      // customer given creds
      if (bill.flags.hasCredentials) {
        // check for bill linking errors first
        if (
          bill.FGSJUSI.needCustomerFor2FA ||
          bill.FGSJUSI.securtyQuestion_verified === 0 ||
          bill.FGSJUSI.auth_verified === 0 ||
          bill.FGSJUSI.pin_verified === 0 ||
          !bill.FGSJUSI.isPrimaryAccount
        ) {
          status = 'customerError'; // failed to link account due to error on customer's end
        } else if (bill.FGSJUSI.scrapingStuckReason) {
          console.log(bill.type, bill.FGSJUSI.scrapingStuckReason); // failed to link account due to error on our end
          status = 'underNegotiation';
          if (bill.flags.negotiationCompleted) {
            status = `negotiationCompleted`;
            if (!bill.totalSavings || bill.totalSavings < 1) {
              status = `onBestDeal`;
            }
            if (bill.totalSavings > 0) {
              status = `savingsFound`;
            }
          }
        } else if (bill.totalSavings > 0) {
          status = `savingsFound`;
        } else {
          status = `underNegotiation`;
          if (bill.pdfs && bill.pdfs.length > 0) {
            if (bill.flags.negotiationCompleted) {
              status = `negotiationCompleted`;
              console.log(
                bill,
                bill.totalSavings,
                !bill.totalSavings,
                bill.totalSavings < 1,
                'billtotalsavings'
              );
              if (!bill.totalSavings || bill.totalSavings < 1) {
                status = `onBestDeal`;
              }
            }
          }
        }
      } else if (bill.pdfs && bill.pdfs.length > 0) {
        if (bill.totalSavings > 0) {
          status = `savingsFound`;
        } else {
          // customer given just PDF
          status = `underNegotiation`;
          if (bill.flags.negotiationCompleted) {
            status = `negotiationCompleted`;
            if (!bill.totalSavings || bill.totalSavings < 1) {
              status = `onBestDeal`;
            }
          }
        }
      } else if (bill.pin) {
        if (bill.totalSavings > 0) {
          status = `savingsFound`;
        } else {
          // customer given just PIN
          status = `customerError`;
          if (bill.flags.negotiationCompleted) {
            status = `negotiationCompleted`;
            if (!bill.totalSavings || bill.totalSavings < 1) {
              status = `onBestDeal`;
            }
          }
        }
      }
      if (bill.rejectType === 0) {
        status = 'inCorrectPin';
      }
    }

    if (status === 'underNegotiation') {
      const today: any = new Date();
      const createdOn: any = new Date(bill.createdOn);
      const billAge: any = (today - createdOn) / (24 * 60 * 60 * 1000);
      if (billAge > 30) {
        status = `onBestDeal`;
      }
    }

    return status;
  }

  startResetPin(data: any): Observable<any> {
    return this.api.post(`accounts/startPinReset`, data);
  }
  validatePin(data: any): Observable<any> {
    return this.api.post(`accounts/validatePins`, data);
  }

  // reviseBillCurrentKeyStatus(){
  //   console.log('revising bill keys')
  //   const bills = this.billsStore.getBills()
  //   for(const bill of bils){
  //     bill.keyStatus = this.helperCurrentKeyStatus(bill);
  //   }
  // }
}
