import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillsService } from '../@core/backend/services/bills.service';
import { BillsStore } from '../@core/stores/bills.store';
import { UserStore } from '../@core/stores/user.store';
import { AppApi } from '../apis/app.api';
import { billsConfig } from '../@config/page';

@Component({
  selector: 'app-all-linked-bills',
  templateUrl: './all-linked-bills.component.html',
  styleUrls: ['./all-linked-bills.component.scss']
})
export class AllLinkedBillsComponent implements OnInit {

  customer: any;
  accounts: any;

  billpayData : any = [];
  linkedPayments : any = [];
  billsConfig: any = {};

  constructor(
    private userStore: UserStore,
    private billsService: BillsService,
    private billsStore: BillsStore,
    private router: Router,
    private appApi: AppApi,


  ) {
    this.billsConfig = billsConfig;

   }

  ngOnInit(): void {

    this.customer = this.userStore.getUser();    
    if (!this.customer) {
      this.router.navigate(['/funnel/initial']);
      return;
    }
    if (window) window.scrollTo(0, 0);
    if (this.customer) {
      this.getLinkedBills();
    }
  }


  getLinkedBills() {
 
    if (!this.customer) return;
    this.appApi.getBillPayEnabledBills(this.customer.id).subscribe(
      (resp: any) => {
        console.log(resp, 'sdb');

        this.billpayData = resp.data.billpayData ? resp.data.billpayData : [];

        let todayDate = new Date();

        this.billpayData.forEach((billObj) => {
          // if (
          //   !(billObj.billDueDate && new Date(billObj.billDueDate).getTime() > todayDate.getTime())
          // ) {
          //   this.linkedPayments.push(billObj); 
          // }
          this.linkedPayments.push(billObj);           
        });

        console.log("this.linkedPayments: ", this.linkedPayments);


      },
      (error) => {}
    );
  }

  
  openBillPayDetails(bill: any) {
    const billType = bill.billTypes && bill.billTypes.length ? bill.billTypes[0] : '';
    if (billType) {
      this.router.navigateByUrl('/bill-details/' + billType + '/' + bill._id);
    } else {
      this.router.navigateByUrl('/bill-details/' + bill.type);
    }
  }
  
  openBillDetails(bill: any) {
    this.router.navigateByUrl('/bill-details/' + bill.type);
  }

  onBack():void {
    this.router.navigateByUrl(`/dashboard`)   
  }

}
