<div class="wrapper h-100">
  <app-header [isback]=true  [backtext]="" ></app-header>
  
  <div class="page-title text-center relative pt-4">
    <!-- <img class="back-btn" src="../../assets/images/fb/arrow_back.svg" [routerLink]="['/fb/dashboard']" /> -->
    <!-- <img class="bill-type-logo" src="../../assets/images/fb/bill-types/cellphone.svg" /> -->
    <!-- <span>Account Settings</span> -->
    <div class="pageBackBtn">
      
      Account Settings
    </div>
  </div>
  <div class="my-5 center-max">
    <div class="cont-hdr my-2">Basic Details</div>
    <div class="d-card-rounded-border">
      <div
        class="d-flex borderBottomGrey py-3 px-3 hand"
        (click)="editProfile()"
        title="click to edit name"
      >
        <img class="mr-3" src="../../assets/images/fb/prof_user.svg" />
        <div class="flex-fill">
          {{ appStore.customer?.firstName + ' ' + appStore.customer?.lastName }}
        </div>
        <img class="chevron" src="../../assets/images/fb/chevron_right.svg" />
      </div>
      <!--edit gender-->
      <div
      class="d-flex borderBottomGrey py-3 px-3 hand"
      (click)="editProfile()"
      title="click to edit gender"
    >
      <img class="mr-3" src="../../assets/images/fb/prof_user.svg" />
      <div class="flex-fill">
       Gender: {{ appStore.customer?.gender }}
      </div>
      <img class="chevron" src="../../assets/images/fb/chevron_right.svg" />
    </div>

      <!--edit gender-->
      <div
      class="d-flex borderBottomGrey py-3 px-3 hand"
      (click)="editProfile()"
      title="click to edit city"
    >
      <img class="mr-3" src="../../assets/images/fb/prof_user.svg" />
      <div class="flex-fill">
       City: {{ appStore.customer?.address.city }}
      </div>
      <img class="chevron" src="../../assets/images/fb/chevron_right.svg" />
    </div>

      <!--edit state-->
      <div
      class="d-flex borderBottomGrey py-3 px-3 hand"
      (click)="editProfile()"
      title="click to edit state"
    >
      <img class="mr-3" src="../../assets/images/fb/prof_user.svg" />
      <div class="flex-fill">
       State: {{ appStore.customer?.address.state }}
      </div>
      <img class="chevron" src="../../assets/images/fb/chevron_right.svg" />
    </div>

      <div
        class="d-flex borderBottomGrey py-3 px-3 hand"
        (click)="editProfile()"
        title="click to edit email"
      >
        <img class="mr-3" src="../../assets/images/fb/prof_email.svg" />
        <div class="flex-fill">{{ appStore.customer?.email }}</div>
      </div>
      <div class="d-flex borderBottomGrey py-3 px-3">
        <img class="mr-3" src="../../assets/images/fb/prof_phone.svg" />
        <div class="flex-fill">{{ formattedPhone }}</div>
      </div>
      <div
        class="d-flex py-3 px-3 hand"
        (click)="editProfile()"
        title="click to edit date of birth"
      >
        <img class="mr-3" src="../../assets/images/fb/prof_bday.svg" />
        <div class="flex-fill">
          {{ appStore.customer?.dob | date: 'longDate' }}
        </div>
        <img class="chevron" src="../../assets/images/fb/chevron_right.svg" />
      </div>
    </div>
  </div>
  <div class="my-5 center-max">
    <div class="cont-hdr my-2">
      Payment methods
      <span
        *ngIf="appStore.customer?.pricingPlanDetails?.planName"
        class="badge badge-pill badge-primary"
        >{{ appStore.customer?.pricingPlanDetails?.planName }}</span
      >
    </div>

    <div
      class="d-card-rounded-border"
      *ngIf="appStore.customer?.creditCard?.exists  && appStore.customer?.pricingPlanDetails?.paymentMethod !== 'ACH'"
    >
      <div class="d-flex py-3 px-3" title="click to update credit/debit card">
        <img class="mr-3" src="../../assets/images/fb/prof_card.svg" />
        <div class="flex-fill">
          •••• •••• •••• {{ appStore.customer?.creditCard?.last4 }}
        </div>
        <button
          class="btn btn-sm mx-auto"
          (click)="updateCard()"
          style="background: #1bd27f !important; color: white"
        >
          Update Card 
        </button>
      </div>
    </div>
   
    <div
    class="d-card-rounded-border"
    *ngIf="
    (appStore.customer?.billPayCustomer &&
      appStore.customer?.paymentMethods?.length == 0) ||
    (!appStore.customer?.billPayCustomer ||
      appStore.customer.pricingPlan === '' || appStore.customer.pricingPlan < 1002)
  "
  >
    <div class="d-flex py-3 px-3" title="click to link bank">
      <img class="mr-3"   src="../../assets/images/fb/bank_icon.svg"  style="height: 20px" />
      <div class="flex-fill">
        Link Bank Account
      </div>
      <button
        class="btn btn-sm mx-auto"
        (click)="addACH()"
        style="white-space: nowrap;background: #1bd27f !important; color: white"
      >
       Add Bank
      </button>
    </div>
  </div>

    <div
     
      *ngIf="
      (appStore.customer?.billPayCustomer && 
      appStore.customer?.paymentMethods?.length > 0) 
      "
    >
      <div *ngFor="let payMethod of appStore.customer?.paymentMethods">
        <div  *ngIf="payMethod?.paymentType == 'ACH'"
        class="d-card-rounded-border mt-2 mb-2">
        <div class="d-flex py-3 px-3">
          <img
            class="mr-3"
            src="../../assets/images/fb/bank_icon.svg"
            style="height: 20px"
          />
          <div class="flex-fill">
            {{ payMethod?.bankName }}
          </div>
          <button
            class="btn btn-sm mx-auto"
            (click)="updateAch()"
            title="click to update the bank to pay your bills"
            style="white-space: nowrap;background: #1bd27f !important; color: white"
          >
            Update Bank
          </button>
        </div>

        <div class="d-flex py-3 px-3" >
          <img
            class="mr-3"
            src="../../assets/images/fb/bill_icon.svg"
            style="height: 20px"
          />
          <div class="flex-fill">
            •••• ••••
            {{ payMethod?.mask }}
          </div>
          <button
            class="btn btn-sm mx-auto"
            (click)="goToBankAccountList()"
            title="click to pick a preferred bank account to pay your bills"
            style="background: #1bd27f !important; color: white"
          >
            Change Preferred Account
          </button>
        </div>
      </div>

      </div>
    </div>

  <!--   <div
      class="d-card-rounded-border mt-2 mb-2"
      *ngIf="!appStore.customer?.creditCard?.exists"
    >
      <div>
        <div class="d-flex py-3 px-3">
          <img
            class="mr-3"
            src="../../assets/images/fb/prof_card.svg"
            style="height: 20px"
          />
          <div class="flex-fill">Credit/Debit Card</div>
          <button
            class="btn btn-sm mx-auto"
            (click)="addCardPage()"
            title="click to add a credit/debit card"
            style="background: #1bd27f !important; color: white"
          >
            Add
          </button>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div class="my-5 center-max">
    <div class="cont-hdr my-2">Notification preferences</div>
    <div class="d-card-rounded-border">
      <div class="d-flex borderBottomGrey py-3 px-3" style="max-height: 60px">
        <div class="flex-fill">Emails</div>
        <div>
          <label class="switch">
            <input
              type="checkbox"
              class="custom-control-input"
              name="emails"
              id="emailNotif"
              [checked]="
                appStore.customer?.notificationPreferences?.email?.betterDeals
              "
              [(ngModel)]="emailNotif"
              (ngModelChange)="updateNotificationPreferences('email')"
            />

            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="d-flex py-3 px-3" style="max-height: 60px">
        <div class="flex-fill">
          Text
          {{ appStore.customer?.notificationPreferences?.text?.betterDeals }}
        </div>
        <div>
          <label class="switch">
            <input
              type="checkbox"
              class="custom-control-input"
              name="text"
              id="phoneNotif"
              [checked]="
                appStore.customer?.notificationPreferences?.phone?.betterDeals
              "
              [(ngModel)]="phoneNotif"
              (ngModelChange)="updateNotificationPreferences('phone')"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </div> -->

  <div class="my-5 center-max" *ngIf="this.invoices.length">
    <div class="cont-hdr my-2">BillTrim Invoices</div>
    <div class="d-card-rounded-border invoices-holder">
      <ng-container *ngFor="let invoice of this.invoices; let last = last">
        <div class="d-flex py-3 px-3 hand" (click)="viewInvoice(invoice)">
          <img class="mr-4" src="../../assets/images/fb/pdf.svg" />
          <div class="flex-fill">
            {{ invoice.createdAt | date: 'MMM y' }} - ${{ invoice.charges }}
          </div>
          <img class="chevron" src="../../assets/images/fb/chevron_right.svg" />
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Footer -->
<app-footer></app-footer>
<!-- Footer -->
