import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, timeout, catchError } from 'rxjs/operators';
import { AppStore } from '../stores/app.store';
import { billsConfig } from '../@config/page';
import { environment } from '../../environments/environment';
import { UserStore } from '../@core/stores/user.store';

@Injectable({
  providedIn: 'root',
})
export class AppApi {
  billsConfig: any = {};
  api_url = environment.API_BASE_URL;
  appnode_api_url = environment.API_OLD_BASE_URL;
  scp_api_url = environment.SCP_BASE_URL;

  constructor(private http: HttpClient,
    private userStore: UserStore,
     private appStore: AppStore) {
    this.billsConfig = billsConfig;
  }

  get(id: string, paymentMethods = false): Observable<any> {
    return this.http
      .post(`${this.api_url}/customers/getCustomer`, {
        custId: id,
        paymentMethods: paymentMethods,
      })
      .pipe(
        map((response: any) => {
          return this.mapResponseToUserInterface(
            response.result.customer,
            undefined
          );
        })
      );
  }

  addUser(data: any): Observable<any> {
    return this.http
      .post(`${this.api_url}/customers/createCustomer`, data)
      .pipe(
        map((response: any) => {
          if (response.status === false || !response.result.customer) {
            return response;
          }
          return this.mapResponseToUserInterface(
            response.result.customer,
            response.result.token
          );
        })
      );
  }

  loginViaPhone(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/customers/custLoginPhone`, data);
  }

  validateOTP(data: any): Observable<any> {
    return this.http
      .post(`${this.api_url}/customers/custLoginVerify`, data)
      .pipe(
        map((response: any) => {
          if (response.status === false || !response?.result?.customer) {
            sessionStorage.removeItem('isLoggedIn');
            return response;
          }
          sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
          sessionStorage.setItem('loggedViaMagicLink', JSON.stringify(false));
          this.mapResponseToUserInterface(
            response.result.customer,
            response.result.token
          );
          return true;
        })
      );
  }


  verifyFirebaseToken(data: any): Observable<any> {
    return this.http
      .post(`${this.api_url}/customers/firebaseTokenVerify`, data)
      .pipe(
        map((response: any) => {
          console.log(response.httpStatus)
          if (response.status === false || !response?.result?.customer) {
            console.log('false',response);
            sessionStorage.removeItem('isLoggedIn');
            return response;
          }
          sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
          sessionStorage.setItem('loggedViaMagicLink', JSON.stringify(false));
          this.mapResponseToUserInterface(
            response.result.customer,
            response.result.token
          );
          return true;
        })
      );
  }

  updateCustomer(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/customers/updateCustomer`, data);
  }
  updateCustomerAttribution(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/customers/save-funnel-info`, data);
  }

  updateAccount(item: any): Observable<any> {
    return this.http.post(`${this.api_url}/accounts/updateAccount`, item);
  }

  linkBill(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/link-bill`, data);
  }
  pickLine(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/send-otp`, data);
  }
  validateSCPOTP(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/validate-otp`, data);
  }
  validateQNA(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/validate-qna`, data);
  }

  getPrimaryDetails(data: any): Observable<any> {
    return this.http.post(
      `${this.scp_api_url}/pdfScraper/get-primary-json`,
      data
    );
  }
  getRawJson(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/get-json`, data);
  }
  getPdfs(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/get-pdf`, data);
  }
  swapCard(data: any): Observable<any> {
    return this.http.post(`${this.scp_api_url}/pdfScraper/swap-vcc`, data);
  }
  getTopProviders(data: any): Observable<any> {
    return this.http.get(
      `${this.api_url}/customers/getTopProviders?billType=${data.billType}&city=${data.city}&zipcode=${data.zipcode}`,
      data
    );
  }
  getEstimatedOverpay(data: any): Observable<any> {
    return this.http.get(
      `${this.api_url}/customers/estimated-overpay/${data.customerId}`
    );
  }
  getProviderNameAndID(data: any): Observable<any> {
    return this.http
      .post(`${this.appnode_api_url}/getProviderNameAndId`, data)
      .pipe(
        map((response: any) => {
          console.log(
            response.data.providerDetails,
            'response.data.providerDetails'
          );
          this.appStore.setStorageState('callerName', response.data.callerName);
          this.appStore.setStorageState(
            'providerDetails',
            response.data.providerDetails
          );
          return response;
        })
      );
  }
  setNotificationPreferences(data: any): Observable<any> {
    return this.http.post(
      `${this.api_url}/customers/setNotificationPreferences`,
      data
    );
  }
  getIPData(): Observable<any> {
    const url = 'https://ipinfo.io/json?token=47e2f1ebfa2707';
    // const url = "http://ip-api.com/json/"
    return this.http.get(url).pipe(
      map((response) => {
        return this.mapIpdata(response);
      })
    );
  }
  // attributing a goal conversion(customer took the action we asked for)
  // Goals include: creditcard | customerreferral | pdfupload | pin | bank | landing
  attributeCustomer(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/customers/attributeCustomer`, data);
  }

  mapIpdata(response) {
    let companyName = '';
    if (response.company) {
      companyName = response.company.name || '';
    }
    let asnName = '';
    if (response.asn && response.asn.name) {
      asnName = response.asn.name;
    } else if (response.isp) {
      asnName = response.isp;
    }
    let carrierName = '';
    if (response.carrier && response.carrier.name) {
      carrierName = response.carrier.name;
    }

    const ipDataObj: any = response || {
      //ip-api.com
      ip: response.query ? response.query : response.ip,
      postal: response.zip ? response.zip : response.postal,
      region: response.regionName ? response.regionName : response.region,
      timezone: response.timezone,
      city: response.city,
      country: response.countryCode ? response.countryCode : response.country,
      company: {
        name: companyName,
      },
      asn: {
        name: asnName,
      },
      carrier: {
        name: carrierName,
      },
    };
    ipDataObj.browser = window.navigator && window.navigator.userAgent;
    ipDataObj.userAgent = window.navigator && window.navigator.userAgent;
    this.appStore.setStorageState('IPDATA', ipDataObj);
    // window.dataLayer.push({ ipDataObj: ipDataObj });
    return ipDataObj;
  }

  getAll(data: any): Observable<any> {
    if (!data?.customerId) return of(null);
    return this.http
      .post(`${this.api_url}/customers/getCustomerAccounts`, data)
      .pipe(
        map((response) => {
          return this.mapResponseToBillsInterface(response);
        })
      );
  }

  getProviders(): Observable<any> {
    return this.http.post(`${this.appnode_api_url}/getProviders`, {}).pipe(
      map((response) => {
        return this.mapProviders(response);
      })
    );
  }

  getSignedURL(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/customers/getSignedURL`, data).pipe(
      map((response: any) => {
        return response.result.signedUrl;
      })
    );
  }

  saveCard(data: any) {
    return this.http
      .post(`${this.api_url}/customers/saveCardDetails`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  verifyCard(data: any) {
    return this.http
      .post(`${this.api_url}/customers/verifyStripeCard`, data)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPlaidLinkToken(): Observable<any> {
    return this.http.get(`${this.api_url}/ach/plaid-link-token`);
  }
  exchangePlaidToken(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/ach/exchange-token`, data);
  }
  selectAchAccount(data: any): Observable<any> {
    return this.http.post(
      `${this.api_url}/billpay/${data.customerId}/opt-for-billpay`,
      data
    );
  }
  fetchBankAccounts(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/ach/bank-accounts`, data);
  }
  selectAchFixedAccount(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/payments/select-pricing-plan`, data);
  }

  setActiveACH(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/ach/set-active-ach`, data);
  }


  getBillPayEnabledBills(customerId: any): Observable<any> {
    return this.http.get(`${this.api_url}/billpay/${customerId}/dashboard`);
  }
  getActiveACH(customerId: any): Observable<any> {
    return this.http.get(
      `${this.api_url}/ach/active-ach?customerId=${customerId}`
    );
  }
  billPayDetails(billPayId: any): Observable<any> {
    return this.http.get(`${this.api_url}/billpay/${billPayId}/details`);
  }
  accountBillPayDetails(accountId: any): Observable<any> {
    return this.http.get(`${this.api_url}/billpay/?accountId=${accountId}`);
  }
  getLinkedBills(data: any): Observable<any> {
    return this.http.get(
      `${this.scp_api_url}/pdfScraper/customer-bills/${data.customerId}`,
      data
    );
  }
  dispatchActiveCampaignEvent(data: any): Observable<any> {
    return this.http.post(`${this.api_url}/common/dispatchEvent`, data);
  }

  getSavings(data: any): Observable<any> {
    return this.http
      .post(`${this.api_url}/customers/getSavingSummary`, data)
      .pipe(
        map((response: any) => {
          if (response.result.savings.savings.length) {
            // fetch & format all paymentsMade
            const paymentsMade = [];
            if (response.result.payments.payments.length) {
              response.result.payments.payments.forEach((payment) => {
                if (payment.charges >= 0) {
                  paymentsMade.push({
                    _id: payment._id,
                    type: payment.type,
                    charges: payment.charges,
                    description: payment.description,
                    scheduleDate: payment.scheduleDate,
                    receipt_url: payment.receipt_url,
                    createdAt: payment.createdAt,
                  });
                }
              });
            }

            // save summary numbers in user store for future purposes
            var oldestCreatedAt = '';
            var longestContractTerm = 0;
            response.result.savings.savings.forEach((saving) => {
              if (saving.totalSavings >= 0) {
                if (
                  oldestCreatedAt === '' ||
                  oldestCreatedAt > saving.createdAt
                ) {
                  oldestCreatedAt = saving.createdAt;
                }
                if (longestContractTerm < saving.contractTerm) {
                  longestContractTerm = saving.contractTerm;
                }
              } // skip looking at savings with negative total_savings
            });
            const summary = {
              totalOldBillAmount:
                response.result?.savings?.total_old_bill_amount.toFixed(2),
              totalNewBillAmount:
                response.result?.savings?.total_new_bill_amount.toFixed(2),
              totalOneTimeCredit:
                response.result?.savings?.total_one_time_credit.toFixed(2),
              totalYearlySavings: Math.round(
                ((response.result.savings.total_savings -
                  response.result.savings.total_one_time_credit) /
                  longestContractTerm) *
                  12
              ),
              totalSavings: response.result.savings.total_savings,
              totalMonthlySavings: Math.round(
                (response.result.savings.total_savings -
                  response.result.savings.total_one_time_credit) /
                  longestContractTerm
              ), // average monthly savings across all bills
              totalNetSavings: Math.round(
                response.result.savings.total_savings * 0.75
              ),
              savings: response.result.savings.savings,
              createdAt: oldestCreatedAt,
              contractTerm: longestContractTerm,
            };
            this.appStore.setSavingSummary(summary);

            // loop through savings and add savings as well as payments to billsStore
            response.result.savings.savings.forEach((saving) => {
              if (saving.totalSavings >= 0) {
                let b = [] as any;
                b = this.appStore.getBill(saving.type);
                if (b) {
                  const index = b.savings.findIndex(
                    (svg) => svg._id === saving._id
                  );
                  if (index === -1) {
                    b.savings.push(saving);
                    b.totalSavings = b.totalSavings + saving.totalSavings;
                    // b.keyStatus = this.helperCurrentKeyStatus(b);
                  }
                  b.payments = paymentsMade;
                  this.appStore.setBill(b);
                }
              }
            });

            this.appStore.sortPaymentsPerBill(); // sort payments from old to new
            this.appStore.sortSavingsPerBill(); // sort savings from old to new
            return true;
          } else {
            return false;
          }
        })
      );
  }

  mapResponseToUserInterface(customerObj: any, respToken: any) {
    let token = '';
    if (respToken) {
      token = respToken;
      sessionStorage.setItem('TKN', JSON.stringify(respToken));
    }
     console.log(customerObj,'customerObj')
    let mappedResponse: any;
    try {
      var oldTS = new Date(customerObj.createdAt).getTime();
      var nowTS = new Date().getTime();
      var numDaysSinceSignup = Math.round((nowTS - oldTS) / (1000 * 3600 * 24));

      mappedResponse = {
        // identifiers and numbers
        id: customerObj._id,
        last4SSN: customerObj.last4_ssn,
        onboardingType: customerObj.onboardingType || '',
        numDaysSinceSignup: numDaysSinceSignup,

        // strings
        authToken: token,
        firstName: customerObj.firstName || '',
        lastName: customerObj.lastName || '',
        dob: customerObj.dob || '',
        gender: customerObj.gender || '',
        phone: customerObj.phone || '',
        email: customerObj.email || '',
        pricingPlan: customerObj.pricingPlan || '', // TODO:
        photoURL: customerObj.photoURL || '',
        referralCode: customerObj.referralCode,
        avatarURL: customerObj.avatarURL || 'assets/images/user/user.png',
        phoneVerified: customerObj.phoneVerified,
        ratings: customerObj.ratings && customerObj.ratings.ratings,
        customerHardShips: customerObj.customerHardShips,
        campaign: customerObj.campaign,
        billPayCustomer: customerObj.billPayCustomer,
        hotlist:customerObj.hotlist,
        // booleans
        flags: {
          isMilitary: customerObj.isMilitary,
          isOwnsHouse: customerObj.isOwnsHouse,
          onboardingCompleted: customerObj.onboarding_completed ? true : false,
          profileCompleted:
            // tslint:disable-next-line:max-line-length
            customerObj.firstName &&
            customerObj.lastName &&
            customerObj.email &&
            customerObj.phone &&
            customerObj.phoneVerified
              ? true
              : false, // this decides whether to lock the card
          phoneVerified: customerObj.phoneVerified || false,
          emailVerified: null, // TODO:
          subscribed: !customerObj.archived || false,
        },

        // dates
        signupDate: customerObj?.createdAt || '',
        lastLoginDate: '', // TODO:

        // objects
        creditCard: {
          exists: customerObj.card ? true : false,
          valid: customerObj.card && customerObj.card_verified ? true : false,
          last4: (customerObj.card && customerObj.card.card_last_four) || '',
        },

        address: {
          addressLine:
            (customerObj.address && customerObj.address.addressLine) || '',
          addressLine2:
            (customerObj.address && customerObj.address.addressLine2) || '',
          city: (customerObj.address && customerObj.address.city) || '',
          state: (customerObj.address && customerObj.address.state) || '',
          zipcode: (customerObj.address && customerObj.address.zipcode) || '',
        },
        pricingPlanDetails: customerObj?.pricingPlanDetails,
        paymentMethods: customerObj?.paymentMethods,
        notificationPreferences: customerObj.notificationPreferences || {},
      };
    } catch (e) {
      console.log(e);
    }

    // save in store for future reference
    this.appStore.setUser(mappedResponse);
    this.userStore.setUser(mappedResponse)
    return mappedResponse;
  }

  mapResponseToBillsInterface(response: any) {
    if (response.result.accounts.length < 1) {
      return [];
    }
    const mappedResponse: any = [];
    let numBillTouched = 0;

    try {
      response.result.accounts.forEach((account: any) => {
        if (!this.billsConfig.billsList.includes(account.type)) {
          return;
        }
        var oldTS = new Date(account.createdAt).getTime();
        var nowTS = new Date().getTime();
        var numDaysSinceBillGiven = Math.round(
          (nowTS - oldTS) / (1000 * 3600 * 24)
        );

        const bill: any = {
          // identifiers and numbers
          id: account._id,
          providerId: account.provider_id,
          pin: account.pin,
          cust_id: account.cust_id,
          totalSavings: 0, // TODO
          estimatedSavings:
            account.estimatedSavingWithinProvider < 1
              ? '0'
              : account.estimatedSavingWithinProvider,
          latestBillAmount: account.billAmount || account.tempBillAmount,
          numDaysSinceBillGiven: numDaysSinceBillGiven,
          latestBillDueDate: account.billDueDate,

          // strings
          type: account.type,
          displayName: this.billsConfig[account.type].displayName,
          icon: this.billsConfig[account.type].icon,
          // colorClass: this.helperGetColorClass(account),
          username: account.username,
          password: account.password,
          providerName:
            account.other_provider_name ||
            this.appStore.getProviderName(account.type, account.provider_id) ||
            '',
          otherProviderName: account.other_provider_name || null,
          currentStatus: '', // gets calculated on the fly. see helperCurrentStatus below
          keyStatus: '',
          rejectType: account.rejectType,

          // booleans
          flags: {
            skipped: account.isSkipped,
            hasCredentials: account.hasCredentials
              ? true
              : !account.username && !account.password
              ? false
              : true,
            hasBillPDF: account.files.length > 0,
            negotiatorAssigned: account.negotiationsCompleted ? true : false,
            negotiationCompleted: account.negotiationsCompleted,
            paidServiceFee: false, // TODO:
          },

          // dates
          lastNegotiatedOn: '', // TODO:
          createdOn: account.createdAt || '',

          // objects
          pdfs: account.files,
          savings: [],
          payments: [],
          accountStatusDetails: [],
          FGSJUSI: {
            billFetched: account.billFetched,
            isTechnicalIssue: account.isTechnicalIssue,
            pin_verified: account.pin_verified,
            hasCredentials: account.hasCredentials,
            hasLatestBillDetails: account.hasLatestBillDetails,
            needCustomerFor2FA: account.needCustomerFor2FA,
            isPrimaryAccount: account.isPrimaryAccount,
            isFGSJUSIRunning: account.isScraperRunning,
            loginAndVerificationSuccess: account.loginAndVerificationSuccess,
            auth_verified: account.auth_verified,
            securtyQuestion_verified: account.securtyQuestion_verified,
            scrapingStuckReason: account.scrapingStuckReason,
          },
        };

        // IMP: alert scraper team (csr?) about bill details missing for bill older then 2 days
        if (!bill.flags.skipped) {
          if (bill.numDaysSinceBillGiven > 2) {
            if (!bill.providerName) {
              // console.log(`Critical: providerName is missing for ${bill.type} bill since ${bill.numDaysSinceBillGiven} days`);
            }
            if (!bill.latestBillAmount) {
              // console.log(`Critical: latestBillAmount is missing for ${bill.type} bill`);
            }
            if (!bill.latestBillDueDate) {
              // console.log(`Critical: latestBillDueDate is missing for ${bill.type} bill`);
            }
          }
          // markLeftNavLink(false, bill.icon); // mark left nav tab as grey
        } else {
          // markLeftNavLink(true, bill.icon); // mark left nav tab as grey
        }

        // calculate current status of each bill
        // bill.currentStatus = this.helperCurrentStatus(bill);
        // bill.keyStatus = this.helperCurrentKeyStatus(bill);

        mappedResponse.push(bill);
        if (bill.colorClass === 'txt-success') {
          numBillTouched = numBillTouched + 1;
        }
      });
    } catch (e) {
      console.log(e);
    }

    // save in store for future reference
    let billList = Array(mappedResponse.length).fill(null);
    mappedResponse.forEach((i: any) => {
      billList[this.billsConfig.billsList.indexOf(i.type)] = i; // Ordering; bills in specific; order;
    });
    billList = billList.filter((i) => i != null);
    this.appStore.setBills(billList);

    // this.appStore.setBillTouched(numBillTouched);
    return mappedResponse;
  }

  mapProviders(response: any) {
    const final: any = {};
    let temp: any = [];
    // filter extra providers we do not care about
    // tslint:disable-next-line:forin
    if (response.data && response.data.provider) {
      response = response.data;
      for (const billType in response.provider) {
        const providers = response.provider[billType];
        if (providers) {
          providers.forEach((provider: any) => {
            if (provider.sequence < 7 || provider.name === 'Other') {
              temp.push(provider);
            }
          });
          final[billType] = temp;
          temp = [];
        }
      }
    }

    // save in store for future reference
    this.appStore.setProviders(final);

    return response.provider;
  }

  isValidEmailDomain(domain: string) {
    const kickBoxUrl = 'https://open.kickbox.com/v1/disposable/';
    return this.http.get(`${kickBoxUrl}${domain}`).pipe(
      timeout(2500),
      catchError((error) => of({ timeout: true, disposable: false })),
      map((response: any) => {
        if (response && typeof response.disposable !== 'undefined') {
          return response;
        }
      })
    );
  }
}
