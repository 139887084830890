import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UsersApi } from '../api/users.api';
import { switchMap, map } from 'rxjs/operators';
import { User } from '../../interfaces/users';

@Injectable({
  providedIn: 'root'
})
// keep it dumb as much as we can
export class UsersService {
  constructor(private api: UsersApi) {}

  get(id: string): Observable<User> {
    return this.api.get(id);
  }

  customerExists(data: any): Observable<any> {
    return this.api.customerExists(data);
  }

  create(user: any): Observable<User> {
    // TODO: append campaign data here
    return this.api.add(user);
  }

  update(user: any): Observable<User> {
    return this.api.update(user);
  }

  queueRule(data: any): Observable<any> {
    return this.api.queueRule(data);
  }
  addRemoveCustomerTags(data: any): Observable<any> {
    return this.api.addRemoveCustomerTags(data);
  }
  getIPData(): Observable<any> {
    return this.api.getIPData();
  }

  getLatestNumbers(): Observable<any> {
    return this.api.getLatestNumbers();
  }

  phoneVerifyAndRegister(data: any): Observable<any> {
    return this.api.phoneVerifyAndRegister(data);
  }

  setNotificationPreferences(data: any): Observable<any> {
    return this.api.setNotificationPreferences(data);
  }
  validateOTP(data: any): Observable<any> {
    return this.api.validateOTP(data);
  }

  verifyMagicID(data: any): Observable<any> {
    return this.api.verifyMagicID(data);
  }

  attributeCustomer(data: any): Observable<any> {
    return this.api.attributeCustomer(data);
  }

  chaserCustomerDisposition(data: any): Observable<any> {
    return this.api.chaserCustomerDisposition(data);
  }

  getReferrerCustomer(data: any): Observable<any> {
    return this.api.getReferrerCustomer(data);
  }
  recommendFriends(data: any): Observable<any> {
    return this.api.recommendFriends(data);
  }
  getReferralLeadDetails(data: any): Observable<any> {
    return this.api.getReferralLeadDetails(data);
  }

  getCustomerFriends(data: any): Observable<any> {
    console.log(data, 'datattatat');
    return this.api.getCustomerFriends(data);
  }


  //getCustomerReferredBy
  getCustomerReferredBy(data: any): Observable<any> {
    console.log(data, 'datattatat');
    return this.api.getCustomerReferredBy(data);
  }

  getCustomer(data: any): Observable<any> {
    return this.api.getReferrerCustomer(data);
  }

  logLandedUser(data: any): Observable<any> {
    return this.api.logLandedUser(data);
  }

  getSupportTickets(data: any): Observable<any> {
    return this.api.getTickets(data);
  }
}
