const BASE_URL = 'https://api.billtrim.fun/';
export const environment = {
  production: false,
  BASE_URL1 : 'https://api.billtrim.fun/',
  API_BASE_URL: `${BASE_URL}customers`,
  API_OLD_BASE_URL: `${BASE_URL}appnode/api`,
  API_GATEWAY_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImlzcyI6IktzOFEwcFRXSXFwNEtYaENYcVZ5cHpRTzJsUFcyRjVKcyJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.HkdHW29JDpnBwxcumRtRPwgssXpy7qDDemQVVSvZB-0',
  AUTOPILOT_BASE_URL: `${BASE_URL}autopilot`,
  AUTOPILOT_GATEWAY_TOKEN:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhMzZjMzA0OWIzNjI0OWEzYzlmODg5MWNiMTI3MjQzYyJ9.U8dOyd1978lmbWNk7gXHf7krDTjYKZanrVpayA0Lhug',
  SCP_BASE_URL: 'https://api.billtrim.fun/billLinker',
  LOGGLY_TOKEN: '0bd70161-f2e6-4046-80d3-022a66776857',
  STRIPE_KEY: 'pk_test_7tDBLYrGn6BqpgNV7hbOXqeC',
  PUBNUB_PUBLISH_KEY: 'pub-c-26cf1874-246b-4917-89fc-2f2294d167ac',
  PUBNUB_SUBSCRIBE_KEY: 'sub-c-b1399a1e-2dc4-11eb-a9aa-e23bcc63a965',
  COOKIE_PREFIX: 'BT_',
  SECRET_CODE: 'billtrim#143@mindyourownbusiness-dev',

  firebaseConfig : {
    apiKey: "AIzaSyCxxQPRzhrLoSlo3i4-hZ3tYmNtkGim9Hg",
    authDomain: "app-billtrim-fun.firebaseapp.com",
    databaseURL: "https://app-billtrim-fun.firebaseio.com",
    projectId: "app-billtrim-fun",
    storageBucket: "app-billtrim-fun.appspot.com",
    messagingSenderId: "786495370671",
    appId: "1:786495370671:web:7bbd528c19d06920486b5a",
    measurementId: "G-B9KJKXDH0V"
  }
};
