<nav *ngIf="!isback" class="navbar navbar-expand-lg navbar-light">
    <div class="image-holder">
      <a class="navbar-brand d-none d-md-block">
        <img src="../../../assets/images/fb/logo.svg" />
      </a>
      <img class="alex" src="../../../../assets/images/fb/alex-2.jpeg" />
    </div>
  </nav>

  <!-- <div  *ngIf="isback"  class="pageBackBtn" (click)="onBack()"><img class="back-btn" src="../../assets/images/fb/arrow_back.svg" />{{backtext}}</div> -->
  <div  *ngIf="isback"  class="header-block">
    <div class="text-center">
      <img
        class="back-btn"
        src="../../../assets/images/fb/arrow_back.svg"
        (click)="onBack()"
      />
      <img src="../../../assets/images/fb/logo.svg" alt="Billtrim" />
    </div>
  </div>
