import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { app } from '../fb/config/app';
import { bills } from '../fb/config/bills';
import { BillsService } from '../@core/backend/services/bills.service';
import { BillsStore } from '../@core/stores/bills.store';
import { UserStore } from '../@core/stores/user.store';
import { AppApi } from '../apis/app.api';
import { Location } from '@angular/common';
/// function to call SDK
declare function initializeSDK(argObj:any)

declare function pingPixel(str: string, pageObj?: any): any;

@Component({
  selector: 'app-bill-details-v2',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss'],
})
export class BillDetailsComponent implements OnInit {
  activeTab: any = null; //'billPay';
  negotiationRemainderInp: Boolean = true;
  queryParams: any = {};
  bill: any;
  customer: any;
  billType: any;
  billPayId: any;
  providerName:string=''
  showfooter:boolean=false
  showSaving:boolean=true
  config = {
    app,
    bills,
  };
  billPayDetails: any;
  billStatus = null; //'savingsFound'; // underNegotiation, inCorrectPin, savingsFound

  billStatusStepper = [
    //stage - 0 not started, 1 ongoing, 2 completed
    {
      title: 'Bill received for negotiation',
      status: '',
      stage: 2,
    },
    {
      title: 'Estimated Savings - $100',
      status: '',
      stage: 2,
    },
    {
      title: 'Negotiator has been assigned',
      status: '',
      stage: 1,
    },
    {
      title: 'PIN is incorrect',
      status: 'error',
      stage: 1,
    },
    // {
    //   title: 'Negotiator found savings $100',
    //   status: '',
    //   stage: 0
    // }
  ];

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  totdisc:any;
  totdiscmon:any

  constructor(
    private userStore: UserStore,
    private activatedRoute: ActivatedRoute,
    private ngbModel: NgbModal,
    private router: Router,
    private billsStore: BillsStore,
    private billsService: BillsService,
    private appApi: AppApi,
    private _location: Location
  ) {}

  ngOnInit(): void {
    if (window) window.scrollTo(0, 0);
    this.activatedRoute.params.subscribe((params) => {
      this.queryParams = params;
      pingPixel('pageBillDetails', this.queryParams);
      if (params.billType) this.billType = params.billType;
      if (params.billPayId) this.billPayId = params.billPayId;
      console.log(this.billPayId, 'billPayId');
      if (!this.config.bills[this.billType]) {
        this.router.navigate(['/dashboard']);
        return;
      }
    });

    this.customer = this.userStore.getUser();
    console.log('==> this.customer: ', this.customer);

    if (!this.customer) {
      this.router.navigate(['/dashboard']);
      return;
    }
    // this.getCustomerDetails();

    // this.bill = this.billsStore.getBill(this.billType);
    console.log(this.billPayId, 'bill');
    if (this.billPayId) {
      this.getBillPayDetails();
      // this.getSavings(this.customer.id)
    } else {
      this.activeTab = 'savings';
      this.showfooter = true;
    }
    setTimeout(() => {
      if(this.bill){
        this.bill.savings = null;
      }
    }, 4000);
  }

  selectTab(tabName: String) {
    this.activeTab = tabName;
  }
  openModal(content) {
    console.log(content);
    if(this.billPayDetails.recentSaving.length>0){
    this.totdiscmon = this.billPayDetails.recentSaving.oldBillAmount -
    this.billPayDetails.recentSaving.newBillAmount 
    this.totdisc = this.totdiscmon * this.billPayDetails.recentSaving.contractTerm
    }
   else
   {
   this.totdiscmon = 0
   this.totdisc = 0
   }

    

    this.ngbModel.open(content, { ariaLabelledBy: 'modal-basic-title' });
    // this.router.navigate([`/savings-breakdown/${this.billPayId}`]);
  }

  openPDF() {
    window.open(this.billPayDetails.pdf);
  }

  openDashBoard() {
    // this.router.navigate(['/dashboard'])

    if (window.history.length > 1) {
      this._location.back();
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  getBillPayDetails() {
    this.appApi.billPayDetails(this.billPayId).subscribe(
      (response: any) => {
      
        this.billPayDetails = response.data;
         if(  this.billPayDetails?.providerName || this.billPayDetails?.displayName)
         {
          
           if(this.billPayDetails.providerName.toLowerCase() == 'other')
           {
             this.providerName = 'Other_Provider_name'
           }
           else
              this.providerName = this.billPayDetails?.providerName || this.billPayDetails?.displayName
         }
        console.log('billPayDetails', this.billPayDetails);
        this.updateBillStatus();
      },
      (error) => {
        console.log('Error in billPayDetails', error);
      }
    );
  }

  updateBillStatus() {
    let today: any = new Date();
    let scraperRunDate: any = new Date(this.billPayDetails.account.lastScraperRun)
    if (['scraperAttempted'].includes(this.billPayDetails.billpayStage) &&
    this.billPayDetails.account.billFetched == 1) {
      this.billStatus = 'gettingBill';
      this.activeTab = 'billPay';
    } else if (['billFetched'].includes(this.billPayDetails.billpayStage) &&
    this.billPayDetails.account.billFetched == 1 ) {
      this.billStatus = 'gotBill';
      this.activeTab = 'billPay';
    } else if (
      ['relinkingRequired'].includes(this.billPayDetails.billpayStage) ||
      this.billPayDetails.account.billFetched == 0 && (today - scraperRunDate) >= 1800000)
     {
      this.billStatus = 'outOfSync';
      this.activeTab = 'billPay';
      //console.log(new Date() - new Date(this.billPayDetails.account.lastScraperRun), this.billPayDetails.billpayStage)
    } else if((today - scraperRunDate) < 1800000 && this.billPayDetails.account.billFetched == 0 ) {
      this.billStatus = 'gettingBill';
      this.activeTab = 'billPay';
    } else if (
      [
        'awaitingCustomerResponse',
        'pdfMissing',
        'negotiationRequired',
        'qaRejected',
      ].includes(this.billPayDetails.billpayStage)
    ) {
      this.billStatus = 'negotiating';
      this.activeTab = 'savings';
    } else if (['qaApproved','customerNotified'].includes(this.billPayDetails.billpayStage)) {
      this.billStatus = 'negotiationCompleted';
      this.activeTab = 'savings';
    } else if (
      [
        'chargeFailed',
        'paymentFailed',
        'cardLoadFailed',
        'vccNotLinked',
      ].includes(this.billPayDetails.billpayStage)
    ) {
      this.billStatus = 'failed';
      this.activeTab = 'billPay';
    } else if (
      ['customerCharged', 'paymentDone'].includes(
        this.billPayDetails.billpayStage
      )
    ) {
      this.billStatus = 'success';
      this.activeTab = 'billPay';
    }
    else if (
      ['chargePending'].includes(
        this.billPayDetails.billpayStage
       

      )
    ) {
      if(this.billPayDetails.recentSaving.length>0)
      this.showSaving= false
      
      this.billStatus = 'success';
      this.activeTab = 'billPay';
    }
     console.log(this.activeTab,'activeTab')
    console.log(this.billStatus,'billstatus')
    this.showfooter = true;
  }

  getSavings(customerId: string) {
    this.appApi.getSavings({ customer_id: customerId }).subscribe(
      (response: any) => {
        console.log('getSavings', response);
      },
      (error) => {
        console.log('Error in getSavings', error);
      }
    );
  }

  buildStepper() {
    console.log(this.bill.keyStatus, this.bill.type, 'this.bill.keyStatus');
    if (this.bill.keyStatus === 'unTouched') {
      this.router.navigateByUrl(`/fb?linkbill=${this.bill.type}`);
    } else if (this.bill.keyStatus === 'underNegotiation') {
      this.billStatus = 'underNegotiation';
      this.billStatusStepper = [
        {
          title: 'Bill received for negotiation',
          status: '',
          stage: 2,
        },
        {
          title: 'Negotiator has been assigned',
          status: '',
          stage: 1,
        },
      ];
    } else if (this.bill.keyStatus === 'negotiationCompleted') {
      this.billStatus = 'negotiationCompleted';
      this.billStatusStepper = [
        {
          title: 'Bill received for negotiation',
          status: '',
          stage: 2,
        },
        {
          title: 'Negotiator has been assigned',
          status: '',
          stage: 2,
        },
        {
          title: 'Negotiator found you savings',
          status: '',
          stage: 2,
        },
      ];
    } else if (this.bill.keyStatus === 'savingsFound') {
      this.billStatus = 'savingsFound';
      let nextYearNow: any;
      try {
        const createdOn =
          this.bill.savings[this.bill.savings.length - 1].createdAt;
        if (createdOn) {
          nextYearNow = new Date(
            new Date(createdOn).setFullYear(
              new Date(createdOn).getFullYear() + 1
            )
          );
          nextYearNow = nextYearNow.toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
          });
        }
      } catch (error) {
        console.log('Stepper savings found error', error);
      }

      this.billStatusStepper = [
        {
          title: 'Bill received for negotiation',
          status: '',
          stage: 2,
        },
        {
          title: 'Negotiator has been assigned',
          status: '',
          stage: 2,
        },
        {
          title: `You saved $${this.bill.totalSavings.toFixed(2)}`,
          status: '',
          stage: 2,
        },
      ];
      if (nextYearNow) {
        this.billStatusStepper.push({
          title: `Next negotiation attempt on ${nextYearNow}`,
          status: '',
          stage: 1,
        });
      }
    } else if (this.bill.keyStatus === 'onBestDeal') {
      this.billStatus = 'onBestDeal';
      this.billStatusStepper = [
        {
          title: 'Bill received for negotiation',
          status: '',
          stage: 2,
        },
        {
          title: 'Negotiator has been assigned',
          status: '',
          stage: 2,
        },
        {
          title: `You are on the best plan`,
          status: '',
          stage: 2,
        },
      ];
    } else if (this.bill.keyStatus === 'inCorrectPin') {
      this.billStatus = 'inCorrectPin';
      this.billStatusStepper = [
        {
          title: 'Bill received for negotiation',
          status: '',
          stage: 2,
        },
        {
          title: 'Negotiator has been assigned',
          status: '',
          stage: 2,
        },
        {
          title: 'PIN is incorrect',
          status: 'error',
          stage: 2,
        },
      ];
    }
  }

  getMonthName(date) {
    let d = new Date(date);
    return this.monthNames[d.getMonth()];
  }

  openLinkBill_old(str) {
    if (str !== 'more') {
      // link a specific bill
      this.router.navigate(['/funnel/bill-linking/credential'], {
        // queryParams: { linkbill: this.bill.type },
        queryParams: { linkbill: this.billPayDetails.billTypes[0],  providerName :this.billPayDetails.providerName  , relink:true },
      });
    } else {
      this.router.navigate(['/funnel/bill-provider'], {
        // queryParams: { type: this.bill.type, pref: 'bill-details' },
        queryParams: { type: this.billPayDetails.billTypes[0], pref: 'bill-details'},
      });
    }
  }

  openLinkBill(str) {
    if (str !== 'more') {
      // link a specific bill
      initializeSDK({
        custid: this.customer.id,
        clientid:
          "8061810322a562f9c6ccb42220dcc8530c2ff711d06f4c4095718421fae82433",
        issandbox: false,
        section: 'FIX_CREDS',
        billtype: this.billPayDetails.billTypes[0],
        onClose: function(){},
        onError : function(err){}
      });
    } else {

      initializeSDK({
        custid: this.customer.id,
        clientid:
          "8061810322a562f9c6ccb42220dcc8530c2ff711d06f4c4095718421fae82433",
        issandbox: false,
        section: 'BILL_LINK',
        billtype: this.billPayDetails.billTypes[0],
        onClose: function(){},
        onError : function(err){}
      });
     
    }
  }

  isDueFuture(dueDate) {
    // var dateCheck = document.getElementById('masterDataFeedDate_Id').value;
    let dueDateParsed = new Date(dueDate);
    let today = new Date();
    if ( dueDateParsed > today ) {
        return true;
    }
    return false;
}
}
