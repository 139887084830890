import { Injectable } from '@angular/core';
import { User } from '../interfaces/users';
import { environment } from 'src/environments/environment';
// The class will be available application wide as a singleton
// with no need to add it to a module's providers array
@Injectable({
  providedIn: 'root'
})
// store class maintains the object so that it can be accessed across any components or pages
export class UserStore {
  private user: User;

  getUser(): User {
    if (!this.user) {
      // get it from local a user has refreshed
      try {
        this.user = JSON.parse(sessionStorage.getItem(environment.COOKIE_PREFIX + 'USER'));
      } catch (e) {
        // console.log(e);
        this.user = null;
      }
    }
    return this.user;
  }

  setUser(paramUser: User) {
    this.user = paramUser;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'USER');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'USER', JSON.stringify(this.user));
  }

  getToken(): string {
    if (this.user) {
  //  console.log(this.user,'user token info')
      return this.user.authToken;
    }
    return '';
  }

  removeUser(): void {
    this.user = null;
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'USER');
  }

  setSavingSummary(summary: any) {
    this.user.savingsSummary = summary;
    // save it on local as well for cases when user refereshes
    sessionStorage.removeItem(environment.COOKIE_PREFIX + 'USER');
    sessionStorage.setItem(environment.COOKIE_PREFIX + 'USER', JSON.stringify(this.user));
  }
}
