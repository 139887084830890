import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from './stores/app.store';
import { AppApi } from './apis/app.api';
import { pageLinks } from './@config/page';


declare function pingPixel(str: string, customObj?: any): any;
@Injectable({
  providedIn: 'root',
})
export class AppService {
  providers: any;
  queryParams: any = {};
  pageLinks: any = {};

  constructor(
    private router: Router,
    private appStore: AppStore,
    private appApi: AppApi,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {      
      this.queryParams = JSON.parse(JSON.stringify(params)) || {};

      /* handling duplicate query params : when we get duplicate keys in query params it will give the array so
        from our end we have to handle the array and take first index value
      */

      //fl
      if(this.queryParams?.fl && Array.isArray(this.queryParams?.fl)) {
        this.queryParams.fl = this.queryParams?.fl[0]
      } 

      //utm_campaign
      if(this.queryParams?.utm_campaign && Array.isArray(this.queryParams?.utm_campaign)) {
        this.queryParams.utm_campaign = this.queryParams?.utm_campaign[0];
      } 

      //utm_content
      if(this.queryParams?.utm_content && Array.isArray(this.queryParams?.utm_content)) {
        this.queryParams.utm_content = this.queryParams?.utm_content[0];
      } 

      //utm_medium
      if(this.queryParams?.utm_medium && Array.isArray(this.queryParams?.utm_medium)) {
        this.queryParams.utm_medium = this.queryParams?.utm_medium[0];
      } 

      //utm_source
      if(this.queryParams?.utm_source && Array.isArray(this.queryParams?.utm_source)) {
        this.queryParams.utm_source = this.queryParams?.utm_source[0];
      } 
      
      // map any landings via AC or OPs to internal params name to save in customer attribution collection correctly
      if (this.queryParams?.utm_source && (this.queryParams?.utm_source === 'ac' 
      || this.queryParams?.utm_source?.toLowerCase() == 'activecampaign')) {        
        this.queryParams.ref = this.queryParams.utm_medium;
        this.queryParams.tv = this.queryParams.utm_content;
      }
      if (!this.queryParams.funnel) this.queryParams.funnel = 1;
    });
    this.pageLinks = pageLinks;
  }

  fetchAccounts() {
    if (!this.providers) {
      this.getProviders();
    }
    const accountQuery = {
      customerId: this.appStore.customer && this.appStore.customer.id,
      category: 'personal',
    };
    this.appApi.getAll(accountQuery).subscribe((resp) => {});
  }

  getProviders() {
    this.providers = this.appStore.getProviders();
    if (!this.providers) {
      this.appApi.getProviders().subscribe((resp: any) => {
        this.providers = this.appStore.getProviders();
      });
    }
    return this.providers;
  }

  getCustomerDetails(paymentMethods = true) {
    this.appApi.get(this.appStore.customer.id, paymentMethods).subscribe(
      (data: any) => {},
      (error) => {
        console.log('getUserDetails', error);
      }
    );
  }


  //// function to add AC event in local storage until user is created
   addACEventtoStorage(eventName:string)
   {
    let arrAcEv = JSON.parse(sessionStorage.getItem('SaveACEvent')) || [];
    if(arrAcEv.length>0)
    {
          if(!arrAcEv.includes(eventName))
          {
             arrAcEv.push(eventName)
          }
    }
    else{
      arrAcEv.push(eventName)
    }
    sessionStorage.setItem('SaveACEvent', JSON.stringify(arrAcEv));
    console.log(arrAcEv,'acevent')
   }


  dispatchActiveCampaignEvent(eventName: string, eventData: any) {
    const params: any = {
      eventName: eventName,
      eventData: eventData,
      email:
        eventData.email ||
        (this.appStore.customer && this.appStore.customer.email),
    };
    if(!params?.email) 
    {
      //// to save events if user is not registered
     
      this.addACEventtoStorage(eventName)
      return;
    }
    this.appApi.dispatchActiveCampaignEvent(params).subscribe(
      (data: any) => {},
      (error) => {
        console.log('dispatchActiveCampaignEvent', error);
      }
    );
    params.activeCampaignEvents = true;
    pingPixel(eventName, params);
  }

  gotoNextPage(preferredPage?: string) {
    if (preferredPage) {
      this.router.navigate([preferredPage]);
    } else {
      let links = this.pageLinks[`funnel${this.queryParams.funnel}`];
      console.log(links, 'links', this.router.url);
      if (!links) links = [];
      let index = links.indexOf(this.router.url);
      index = index + 1;
      console.log(index, 'index + 1');
      const next = links[index + 1];
      if (next) {
        console.log(next, 'next');

        // this.router.navigate([next]);
      }
    }
  }

  // attributing customer action to AC template or Ops agent
  attributeCustomer(category: any, account?: any) {
    console.log('attributing customer', this.queryParams.ref);
    if (!this.queryParams.ref) return;

    const data: any = {
      customerId: this.appStore?.customer?.id,
      category: category,
      by: this.queryParams.ref,
      queryParams: this.queryParams,
    };

    if (['pin', 'credentials', 'pdfupload'].includes(category)) {
      data.accountType = account?.type || '';
    }

    if (this.queryParams.ref === 'email') {
      data.type = 'email'; // some email template id
    } else if (this.queryParams.ref === 'text') {
      data.type = 'text'; // some text template id
    } else if (this.queryParams.ref) {
      data.type = 'ops'; // some agent
    }

    this.appApi.attributeCustomer(data).subscribe(
      (data: any) => {},
      (error) => {
        console.log('attributeCustomer', error);
      }
    );
  }


   // attributing customer action to AC template or Ops agent
   attributeCustomer_ref(category: any, customer?: any,by? :any) {
    console.log('attributing customer', this.queryParams.ref);
   
    const data: any = {
      customerId:customer,
      category: category,
      by: by,
      queryParams: this.queryParams,
    };

   
    
      data.type = 'referral'; // some email template id
    

    this.appApi.attributeCustomer(data).subscribe(
      (data: any) => {},
      (error) => {
        console.log('attributeCustomer', error);
      }
    );
  }
  // updateBill(currBill: any) {
  //   let findIndex = this.bills.findIndex((bill: any) => bill.type === currBill.type);
  //   if (findIndex != -1) {
  //     this.bills[findIndex].open = false;
  //     if (this.bills[findIndex + 1]) {
  //       this.bills[findIndex + 1].open = true;
  //     } else {
  //       this.router.navigate(['/finish']);
  //     }
  //   }
  // }

  // toggleBill(currBill: any) {
  //   let findIndex = this.bills.findIndex((bill: any) => bill.type === currBill.type);
  //   if (findIndex != -1) {
  //     this.bills[findIndex].open = !this.bills[findIndex].open;
  //   }
  // }
}
