import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
// base clas which will be used by all other API classes
export class HttpService {
  API_OLD_BASE_URL = environment.API_OLD_BASE_URL;
  get apiBaseUrl(): string {
    return environment.API_BASE_URL;
  }

  constructor(private http: HttpClient) {}

  get(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${endpoint}`, options);
  }

  post(endpoint: string, data, options?): Observable<any> {
    if (endpoint.includes('http')) {
      return this.http.post(`${endpoint}`, data, options);
    }
    return this.http.post(`${this.apiBaseUrl}/${endpoint}`, data, options);
  }

  postLocal(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`http://localhost:3000/${endpoint}`, data, options);
  }

  put(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${endpoint}`, data, options);
  }

  delete(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/${endpoint}`, options);
  }

  postAppNode(endpoint: string, data, options?): Observable<any> {
    return this.http.post(`${this.API_OLD_BASE_URL}/${endpoint}`, data, options);
  }
}
