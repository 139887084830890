import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WindowService } from '../window.service';
import * as firebase from 'firebase';
import { HelperService } from '../../@core/backend/services/helper.service';
import { environment } from '../../../environments/environment';



@Component({
  selector: 'app-firebase-login',
  templateUrl: './firebase-login.component.html',
  styleUrls: ['./firebase-login.component.scss']
})
export class FirebaseLoginComponent implements OnInit {

  isVerifyOtph:boolean = false;
  windowRef: any;

  ///// phone fields
  phoneNumberInp:any='' ;
  phoneNumberError: string = '';
  verificationCode: string;
  btnLoading:boolean = false;
  iserror:boolean =false;
  errtext:string=''
  /////otp fields

  phoneOTP: any = null;
  phoneOTPErrorh: string = '';
  showLoader: boolean = false;
  OTPLoader1: boolean = false;
  isMoreThanNumber =false;
  lastOTPCode=[];

  ////
  user: any;
  public recaptchaVerifier: firebase.auth.RecaptchaVerifier;

 //////data flow between child and parent
 @Input() isVerifyOtp: boolean=false;
 @Input() OTPLoader: boolean=false;
 @Input() phoneOTPError: string='';
 @Output() gotoAddUser = new EventEmitter<string>();
 @Output() gotoVerifyUser = new EventEmitter<string>();
 @Output() gotoEditNum = new EventEmitter<string>();
 @Input() phoneVal: string='';


  constructor(private win: WindowService,
    public helperService: HelperService, ) { 

   
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }

  }

  ngOnInit(): void {
   
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    
    this.phoneNumberInp = this.phoneVal
    this.windowRef = this.win.windowRef
   // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        console.log(response);
      },
      'expired-callback': () => {
      }
    });
   
   
    this.windowRef.recaptchaVerifier.render()
  }

  sendLoginCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = "+1"+ this.phoneNumberInp;
    if (this.helperService.validatePhoneFirstThreeDigits(this.phoneNumberInp)) {
      this.phoneNumberError = 'Please enter a valid phone';
     
    }


    firebase.auth().signInWithPhoneNumber(num, appVerifier).then(result => {
      this.windowRef.confirmationResult = result;
      this.gotoAddUser.emit(this.phoneNumberInp);
      //this.isVerifyOtp = true;
    })
    .catch( error => {
      this.phoneNumberError = 'Please enter a valid phone';
      console.log(error)} );
  }

  verifyLoginCode() {

    if(this.phoneOTP?.length < 6)  {
      this.phoneOTPError = '6-digit code is invalid.';
      return;
    }    
    this.windowRef.confirmationResult.confirm(this.phoneOTP).then( result => {
      this.user = result.user;
      this.phoneOTPError = '';
      this.OTPLoader = true;
      console.log( this.user);
       let that = this
      firebase.auth().currentUser.getIdToken(false).then(function (idToken) {

        //Here got idToken, and then what????
        console.log( idToken);
        that.gotoVerifyUser.emit(idToken);
        }).catch(function (error) {
        console.log('currentUser ',error);
        });
     /*  expiresIn: "3600"
idToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6ImJlYmYxMDBlYWRkYTMzMmVjOGZlYTU3ZjliNWJjM2E2YWIyOWY1NTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2FydGlnby0zMzIzMDciLCJhdWQiOiJjYXJ0aWdvLTMzMjMwNyIsImF1dGhfdGltZSI6MTY1MjM2ODEwOCwidXNlcl9pZCI6IkhhSmpZZ09uUjhWenpCYnNFdkhmSkVQbm9aeDEiLCJzdWIiOiJIYUpqWWdPblI4Vnp6QmJzRXZIZkpFUG5vWngxIiwiaWF0IjoxNjUyMzY4MTA4LCJleHAiOjE2NTIzNzE3MDgsInBob25lX251bWJlciI6IisxOTc5NTcxMjE2MiIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzE5Nzk1NzEyMTYyIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGhvbmUifX0.fhY-1iRD6VuPb8oU9Laz3OK9EF1fYZna6GIQH03I_WHErxdzoQ1ZbXPdGSyMOHguMcUYjH5Ziz6TGUoFU7NiRyfy3N-ilIFo-uDWHjbIwqlhafFdqX3ZnLCpOclaEpS3HzSCnmG_nRwikqGhoUnuT80mp3eqVeW4g4dbeLlMzODJb3hAuDuXBy9YDfZWTeLkIXfeQuq-DUlPd7WjLnp1CL57d45I-3Ea5UtVji1EotzMy7QG2s0WRsKfW8wGSKygK-RYMY_KQshNrX5IjXYFOJZ3UqcORiRVRx0F0SxooRMCiD0hfIOynzaSuCjIvrHGdIY-lRjPVwMN8dWVbymUsg"
isNewUser: false
localId: "HaJjYgOnR8VzzBbsEvHfJEPnoZx1"
phoneNumber: "+19795712162"
refreshToken: "AIwUaOn1X3i-3zO1z-zZf3slAoQDnBX5q8kKFlHT9jWMaifzxq4f82vrVeH-3fcfmHPi8dTb9F4SYP39ItUu5XCU6yClenLqS1ujMvFkSeOHb4D__kZqx_MrRD4iAYvF19e_FVjkTo145nLeoQenRjr-Ekq0b5tmoQ50qakopxS9c68RSP6yakcVmXpc1GRmo7osamL0fcH6"
       */
    //  this.gotoVerifyUser.emit(this.user.idToken);
    })
    .catch( error =>
      {
        console.log(error, "Incorrect code entered?")
        this.phoneOTPError = 'verification code is invalid'
      } );
       
  }

  onPhoneModelChange() {
    console.log('ph')
    this.phoneNumberInp = this.phoneNumberInp.replace(/[^0-9]/g, '');
    if (this.phoneNumberInp[0] === '1') {
      this.phoneNumberInp = this.phoneNumberInp.substr(1);
    }
  }

  moveToPhoneNumber()
  {
    this.isVerifyOtp = false
    this.gotoEditNum.emit();
  }


  allowInput(e: any) {           
    var charCode = (e.which) ? e.which : e.keyCode;         
    if ((charCode < 48 || charCode > 57)) {   
      e.preventDefault();
      return false;
    } else if(this.phoneOTP?.length >= 6) {
      this.isMoreThanNumber = true;
      e.preventDefault();
      return false;
    } 
    this.isMoreThanNumber = false;
    return true;
  }

  otpChange(e: any) {          
    let str = e.target.value;
    var reg =/^[0-9]{6}$/;
    if(reg.test(str) && !this.isMoreThanNumber && (this.lastOTPCode.findIndex((val) => val == this.phoneOTP)) == -1) {
     // if (str.length === 6) 
     this.phoneOTPError = ''
     // this.verifyLoginCode();   
    }
  }

}
