import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserStore } from '../stores/user.store';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userStore: UserStore,
    private snackser: SnackbarService,
     private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('getProvider') || req.url.includes('addRemoveCustomerTags') || req.url.includes('chaserDisposition')) {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: `token ${JSON.parse(sessionStorage.getItem('TKN'))}`
        }
      });
    } else if (req.url.includes('/autopilot')) {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: `Bearer ${environment.AUTOPILOT_GATEWAY_TOKEN}`,
          BT_Authorization: `${this.userStore.getToken() || JSON.parse(sessionStorage.getItem('TKN')) || ''}
            `
        }
      });
    } else if(req.url.includes('open.kickbox.com/v1/disposable')) {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json'         
        }
      });
    } else {
     
    //  console.log(this.userStore.getToken(),'user token')
  console.log(JSON.parse(sessionStorage.getItem('TKN')) ,'sess token')
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: `Bearer ${environment.API_GATEWAY_TOKEN}`,
          BT_Authorization: `${this.userStore.getToken() || JSON.parse(sessionStorage.getItem('TKN')) || ''}
            `
        }
      });
    }

    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
      
          // refresh token
          // this.router.navigateByUrl('/fb?page=login');
          this.snackser.show(error.error?.error);
          return [];
          //return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
