

 


  <div [hidden]="isVerifyOtp" class=" container-small">
    <input
      type="tel"
      id="inputNewPhoneNumber"
      class="floating__input"
      placeholder="CELL PHONE"
      mask="000-000-0000"
      required
      pattern="\d*"
      maxlength="12"
      [(ngModel)]="phoneNumberInp"
      (ngModelChange)="onPhoneModelChange()"
    />
   

    <label for="emailaddress" class="floating__label" data-content="Cell Phone">
      <span class="hidden--visually">Cell Phone</span>
    </label>
    <span class="clear-input" *ngIf="phoneNumberInp" (click)="phoneNumberInp = ''"
      ><img src="/assets/images/icons/icon-input-clear.svg" alt="clear"
    /></span>

    <div class="m-3" required id="recaptcha-container"></div>


    <div *ngIf="phoneNumberError" class="alert-class p-b-10 form-error"><i class="fa fa-exclamation-circle"></i> {{ phoneNumberError }}</div>
 
    <input name="submit" class="btn btn-primary" (click)="sendLoginCode()" [disabled]="phoneNumberInp.length < 10"
    accesskey="l" value="Sign In" tabindex="3" type="submit" />

 
  </div>
 






<div *ngIf="isVerifyOtp" class="form-group">
  
  <div class="form-wrapper">
    <div class="section-heading text-center">
      <h2 class="heading">
        Please enter the code sent to<br />
        <span
          >xxx-xxx-{{ (phoneNumberInp || appStore?.customer?.phone)?.substr(6, 10) }}
          <img src="../../../assets/images/icons/icon-edit.svg" alt="edit" class="edit-btn" (click)="moveToPhoneNumber()"
        /></span>
      </h2>
      <p class="sub-text">Heads up, we’ve sent you a 6 digit code, it’s top<br />secret to keep it between us.</p>
    </div>
    <div class="container-small">
      <div class="floating">
        <input
          type="text"
          maxlength="6"           
          pattern= "[0-9]"        
          id="inputPhoneOTP"
          class="floating__input"
          name="otpcode"
          placeholder="Verification code"
          required
          autocomplete="one-time-code"
          (keypress)="allowInput($event)"
          (keyup)="otpChange($event)"
          inputmode="numeric"
          [(ngModel)]="phoneOTP"
        />
        <label for="otpcode" class="floating__label" data-content="CODE">
          <span class="hidden--visually">CODE</span>
        </label>
        <span class="clear-input" *ngIf="phoneOTP" (click)="phoneOTP = ''"><img src="../../../assets/images/icons/icon-input-clear.svg" alt="clear"/></span>
      </div>
      <!-- <div class="text-center py-2" *ngIf="OTPLoader">
        <div class="spinner-border spinner-border-otp" role="status"></div>
      </div> -->
      <div *ngIf="phoneOTPError" class="alert-class p-b-10 form-error"><i class="fa fa-exclamation-circle"></i> {{ phoneOTPError }}</div>

      <button
        class="btn-primary"
        class="btn-primary"
        (click)="verifyLoginCode()"
        [disabled]="phoneOTP?.length < 6 || phoneOTPError"
        id="verify-otp-cta"
      >
        <span class="btn-text">Continue</span>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="OTPLoader"></div>
      </button>
    </div>
    <!-- <button class="btn-link">Change email</button> -->
  </div>

</div>

          
    
