import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppApi } from '../apis/app.api';
import { AppStore } from '../stores/app.store';
import { AppService } from '../app.service';
declare function pingPixel(str: string, customObj?: any): any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  formattedPhone = '';
  queryParams: any = {};
  emailNotif = true;
  phoneNotif = true;
  invoices: any = [];

  constructor(
    private appApi: AppApi,
    public appStore: AppStore,
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    if (window) window.scrollTo(0, 0);
    if (!this.appStore.customer) {
      this.router.navigate(['/funnel']);
      return;
    }
   
      this.getCustomerDetails();
 
   
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    // this.formatInvoices();
  }

  onBack() {
    this.router.navigate(['dashboard']);
  }

  // formatInvoices() {
  //   const bills = this.billsStore.getBills();
  //   bills.forEach(bill => {
  //     this.invoices = this.invoices.concat(bill.payments);
  //   });
  //   // console.log("invoices", this.invoices);
  // }
  getCustomerDetails() {
    this.appService.getCustomerDetails(true);
    if (!this.appStore.customer) return;
    this.formattedPhone =
      this.appStore.customer.phone.substring(0, 3) +
      '-' +
      this.appStore.customer.phone.substring(3, 6) +
      '-' +
      this.appStore.customer.phone.substring(6, 10);

    if (this.appStore.customer.notificationPreferences.email) {
      this.emailNotif =
        this.appStore.customer.notificationPreferences.email.betterDeals;
    }

    if (this.appStore.customer.notificationPreferences.phone) {
      this.phoneNotif =
        this.appStore.customer.notificationPreferences.phone.betterDeals;
    }

    console.log(this.appStore.customer, 'getCustomerDetails');
    
  }

  getCustomerACH()
  {
    
   // payMethod of appStore.customer?.paymentMethods
   if(this.appStore.customer?.paymentMethods && this.appStore.customer?.paymentMethods.length>0)
   {
     let i=0;
    this.appStore.customer?.paymentMethods.forEach(payMethod => {
 
        if(payMethod?.paymentType == 'ACH')
        {
         
          return true
        }

     });
    }
    else
    return false;

    return false

  }

  editProfile() {
    this.router.navigateByUrl('/funnel/bill-linking?page=profile&pref=profile');
  }
  addCardPage() {
    this.router.navigateByUrl('/funnel/add-card-detail?pref=dashboard');
  }
  updateCard() {
    this.router.navigateByUrl('/funnel/add-card-detail?pref=dashboard');
  }

  updateAch() {
    this.router.navigateByUrl(`/funnel/bank-link?pref=bank`);
  }


  addACH() {
    this.router.navigateByUrl(`/funnel/bank-link?pref=bank`);
  }

  public updateNotificationPreferences(field) {
    const data = {
      customerId: '',
      email: {},
      push: {},
      phone: {},
    };
    data.customerId = this.appStore.customer.id;
    if (field === 'email') {
      data.email = this.notificationFormat(this.emailNotif);
    } else if (field === 'phone') {
      data.phone = this.notificationFormat(this.phoneNotif);
    }
    this.appApi.setNotificationPreferences(data).subscribe(
      (response: any) => {},
      (error) => {
        console.log('updateNotificationPreferences error', error);
      }
    );
  }
  private notificationFormat(val: boolean) {
    const data = {
      negotiationUpdates: val,
      billAlerts: val,
      increaseInCharges: val,
      betterDeals: val,
      productNews: val,
      specialOffers: val,
    };
    return data;
  }

  viewInvoice(invoice: any) {
    if (invoice && invoice.receipt_url) {
      window.open(invoice.receipt_url, '_blank');
    }
  }

  goToBankAccountList() {
    this.router.navigateByUrl(`/funnel/bank-accounts?pref=profile`);
  }
}
