import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-previous-payments',
  templateUrl: './previous-payments.component.html',
  styleUrls: ['./previous-payments.component.scss']
})
export class PreviousPaymentsComponent implements OnInit {


  allPayments: any = [];
  previousPayments: any = [];

  activeTab: any = "all";

  constructor(

    private router: Router,

  ) { }

  ngOnInit(): void {
    this.getPreviousPaymentList();
  }


  selectTab(tabId){
    this.activeTab = tabId;

    if(this.activeTab == 'failed'){
      this.previousPayments = this.allPayments.filter((ele)=>{
        if(!ele.isPaid){
          return ele
        }
      });

    }
    else{
      this.previousPayments = this.allPayments;

    }
  }

  getPreviousPaymentList() {


    this.allPayments = [

      {
        "hasCredentials": true,
        "needCustomerFor2FA": false,
        "loginAndVerificationSuccess": false,
        "auth_verified": -1,
        "billFetched": 0,
        "_id": "6253c1e20669ef00195f68f4",
        "type": "auto",
        "provider_id": "5af9a9019b1f40176c989afa",
        "username": "rss1@optonline.net",
        "providerName": "Geico",
        "billPaidDate": new Date(),
        "billAmount": 10.99,
        "isSync": true,
        "isPaid": true,

      },

      {
        "hasCredentials": true,
        "needCustomerFor2FA": false,
        "loginAndVerificationSuccess": false,
        "auth_verified": -1,
        "billFetched": 0,
        "_id": "6253c1e10669ef00195f68d6",
        "type": "cable",
        "provider_id": "5af9a9019b1f40176c98998e",
        "username": "rss1@optonline.net",
        "providerName": "Comcast Xfinity",
        "billPaidDate": new Date(),
        "billAmount": 10.99,
        "isSync": true,
        "isPaid": true,

      },


      {
        "hasCredentials": true,
        "needCustomerFor2FA": false,
        "loginAndVerificationSuccess": false,
        "auth_verified": -1,
        "billFetched": 0,
        "_id": "6253c1e10669ef00195f68cc",
        "type": "internet",
        "provider_id": "5af9a9019b1f40176c989baa",
        "username": "rss1@optonline.net",
        "providerName": "AT&T U-verse",
        "billPaidDate": new Date(),
        "billAmount": 10.99,
        "isSync": true,
        "isPaid": false,


      },
    ];

    this.previousPayments = this.allPayments;
  }



  onBack(): void {
    this.router.navigateByUrl(`/dashboard`)
  }

}
