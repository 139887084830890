export const app = {
  contactNumber: "855-226-4747",
  lockedCardTooltipText:
    "Please fill in the profile details to unlock using this card",
  onboardingCompletedHTML: `Good Job! 🎉 Alex will text you within 48 hrs with your savings!`,
  whyCCNeeded:
    // tslint:disable-next-line:max-line-length
    "Adding your credit card allows us to proceed with negotiating your bills. Once we get you savings we will inform you and unless you are delighted by our services we will not charge. Upon your approval, we will charge a small amount(25% of the savings) for our services.",
  pricingPlans: { 
    1: {
      displayName: "1-3 Bills",
      tagLine1: "50% of savings",
      tagLine2: "50% of savings",
      tagLine3: "1-3 bills",
      tagLine4: "Yearly Negotiation",
      tagLine5: "Chat support",
    },
    2: {
      displayName: "4-8 Bills",
      tagLine1: "75% of savings",
      tagLine2: "25% of savings",
      tagLine3: "4-8 bills",
      tagLine4: "Quarterly Negotiation",
      tagLine5: "Premium call support",
    },
  },
  authKey: "@billtrimu823sb72gwysg7323B$", // authKey For checking if customer exists in db
  referralQualify:
    // tslint:disable-next-line:max-line-length
    "Earn up to $25 for every friend who signs up with BillTrim. Your friends must link at least two bills and should also have a valid payment setup to qualify.",
  accountManagers: [
    {
      imageURL: "assets/images/Alex.jpg",
      name: "Alex",
      rating: "4.5",
      served: "300",
      saved: "20", // in Ks
    },
  ],
  notificationRedirectUrls: {
    "Pin Invalid": "onboarding",
    "Credentials Invalid": "onboarding",

    "Got Savings": "my-savings",
    Charged: "bills/view/cellphone",

    Bill_Fetched: "statements",
    "Latest Bill/PDF Available": "statements",

    Onboarding: "onboarding",
    Payment: "onboarding",
    "CC Missing": "onboarding",
    account: "/bills/view/{{billType}}",
    Survey: "https://forms.gle/LoofdZBQhXoiwpKt8",
  },
  validFirstThreeDigits: [
    201,
    202,
    203,
    204,
    205,
    206,
    207,
    208,
    209,
    210,
    212,
    213,
    214,
    215,
    216,
    217,
    218,
    219,
    220,
    223,
    224,
    225,
    226,
    228,
    229,
    231,
    234,
    236,
    239,
    240,
    242,
    246,
    248,
    249,
    250,
    251,
    252,
    253,
    254,
    256,
    260,
    262,
    264,
    267,
    268,
    269,
    270,
    272,
    276,
    279,
    281,
    284,
    289,
    301,
    302,
    303,
    304,
    305,
    306,
    307,
    308,
    309,
    310,
    312,
    313,
    314,
    315,
    316,
    317,
    318,
    319,
    320,
    321,
    323,
    325,
    326,
    330,
    331,
    332,
    334,
    336,
    337,
    339,
    340,
    341,
    343,
    345,
    346,
    347,
    351,
    352,
    360,
    361,
    364,
    365,
    367,
    380,
    385,
    386,
    401,
    402,
    403,
    404,
    405,
    406,
    407,
    408,
    409,
    410,
    412,
    413,
    414,
    415,
    416,
    417,
    418,
    419,
    423,
    424,
    425,
    430,
    431,
    432,
    434,
    435,
    437,
    438,
    440,
    441,
    442,
    443,
    445,
    450,
    456,
    458,
    463,
    469,
    470,
    473,
    475,
    478,
    479,
    480,
    484,
    500,
    501,
    502,
    503,
    504,
    505,
    506,
    507,
    508,
    509,
    510,
    512,
    513,
    514,
    515,
    516,
    517,
    518,
    519,
    520,
    530,
    531,
    533,
    534,
    539,
    540,
    541,
    544,
    548,
    551,
    559,
    561,
    562,
    563,
    564,
    566,
    567,
    570,
    571,
    573,
    574,
    575,
    577,
    579,
    580,
    581,
    585,
    586,
    587,
    600,
    601,
    602,
    603,
    604,
    605,
    606,
    607,
    608,
    609,
    610,
    612,
    613,
    614,
    615,
    616,
    617,
    618,
    619,
    620,
    623,
    626,
    628,
    629,
    630,
    631,
    636,
    639,
    640,
    641,
    646,
    647,
    649,
    650,
    651,
    657,
    658,
    659,
    660,
    661,
    662,
    664,
    667,
    669,
    670,
    671,
    672,
    678,
    680,
    681,
    682,
    684,
    689,
    700,
    701,
    702,
    703,
    704,
    705,
    706,
    707,
    708,
    709,
    710,
    712,
    713,
    714,
    715,
    716,
    717,
    718,
    719,
    720,
    721,
    724,
    725,
    726,
    727,
    731,
    732,
    734,
    737,
    740,
    743,
    747,
    754,
    757,
    758,
    760,
    762,
    763,
    765,
    767,
    769,
    770,
    772,
    773,
    774,
    775,
    778,
    779,
    780,
    781,
    782,
    784,
    785,
    786,
    787,
    800,
    801,
    802,
    803,
    804,
    805,
    806,
    807,
    808,
    809,
    810,
    812,
    813,
    814,
    815,
    816,
    817,
    818,
    819,
    820,
    825,
    828,
    829,
    830,
    831,
    832,
    833,
    838,
    839,
    843,
    844,
    845,
    847,
    848,
    849,
    850,
    854,
    855,
    856,
    857,
    858,
    859,
    860,
    862,
    863,
    864,
    865,
    866,
    867,
    868,
    869,
    870,
    872,
    873,
    876,
    877,
    878,
    888,
    900,
    901,
    902,
    903,
    904,
    905,
    906,
    907,
    908,
    909,
    910,
    911,
    912,
    913,
    914,
    915,
    916,
    917,
    918,
    919,
    920,
    925,
    928,
    929,
    930,
    931,
    934,
    936,
    937,
    938,
    939,
    940,
    941,
    947,
    949,
    951,
    952,
    954,
    956,
    959,
    970,
    971,
    972,
    973,
    978,
    979,
    980,
    984,
    985,
    986,
    989,
  ],
};
