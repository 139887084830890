
    <app-header [isback]=true  [backtext]="" ></app-header>

<!-- <nav class="navbar navbar-expand-lg navbar-light">
 
  <ul class="nav navbar-nav ml-auto pr-1">
    <li title="Ask us anything!">
      <img
        class="hand d-none"
        src="../../../assets/images/fb/question-mark.svg"
        onclick="openFrontAppChat()"
      />
    </li>
  </ul>
  <i
    class="fa fa-undo pl-2 hand startOver"
    *ngIf="!isProd"
    (click)="startOver()"
    title="Start over"
  ></i>
</nav> -->
<div style="margin-top: 3px;" class="container h-100 d-flex justify-content-center">
  <div
    id="page-selectTopic"
    *ngIf="page.name === 'selectTopic'"
    class="page"
    [@enterAnimation]
  >
    <div class="title">
      Hi
      {{
        this.customer && this.customer.firstName
          ? this.customer.firstName + '!'
          : ' there!'
      }}
      Let me help you file a ticket 🎟️<br />
    </div>
    <div class="font-20 text-center m-3">You have an issue with?</div>
    <div class="page-form pt-2 col-12 col-md-7">
      <label
        class="input-radio switching providerItems"
        *ngFor="let topic of topics"
        ><input
          type="radio"
          name="topic"
          [value]="topic"
          [(ngModel)]="selectedTopic" />
        <div class="option-inner">
          <span>{{ topic.name }}</span>
          <i></i></div
      ></label>
    </div>
    <div class="cta">
      <button
        type="button"
        class="btn {{
          selectedTopic?.id ? 'btn-primary' : 'btn-disabled'
        }} mx-auto"
        [disabled]="!selectedTopic?.id"
        (click)="submitCta()"
      >
        NEXT
      </button>
    </div>
  </div>
  <div
    id="page-selectCategory"
    *ngIf="page.name === 'selectCategory'"
    class="page"
    [@enterAnimation]
  >
    <div class="go-back d-block" (click)="gotoPreviousPage()">
      <i class="fa fa-angle-left"></i>
    </div>
    <div class="title">{{ selectedTopic.name }} issue, Ok! <br /></div>
    <div class="font-20 text-center m-3">
      Please pick the best "specific" option that describe this issue further?
    </div>
    <div class="page-form pt-2 col-12 col-md-7">
      <label
        class="input-radio switching providerItems"
        *ngFor="let category of filteredCategories"
        ><input
          type="radio"
          name="selectedCategory"
          [value]="category"
          [(ngModel)]="selectedCategory" />
        <div class="option-inner">
          <span>{{ category.name }}</span>
          <i></i></div
      ></label>
    </div>
    <div class="cta">
      <button
        type="button"
        class="btn {{
          selectedCategory?.id ? 'btn-primary' : 'btn-disabled'
        }} mx-auto"
        [disabled]="!selectedCategory?.id"
        (click)="submitCta()"
      >
        NEXT
      </button>
    </div>
  </div>
  <div
    id="page-submitFeedback"
    *ngIf="page.name === 'submitFeedback'"
    class="page"
    [@enterAnimation]
  >
    <div class="go-back d-block" (click)="gotoPreviousPage()">
      <i class="fa fa-angle-left"></i>
    </div>
    <div class="title">{{ selectedCategory.problemTitle }}, Got it!</div>
    <div class="font-20 text-center m-3">
      Please add any more details, that can help me better assist you.
    </div>
    <div class="page-form col-12 mt-4 mb-5">
      <div class="form-group" *ngIf="selectedCategory.showSelectAccount">
        <!-- <label class="col-form-label">Account</label> -->
        <select class="form-control" [(ngModel)]="selectAccountInput">
          <option value="0" selected>Pick an Account</option>
          <option value="cellphone">Cellphone</option>
          <option value="cable">Cable</option>
          <option value="internet">Internet</option>
          <option value="homePhone">Home Phone</option>
          <option value="home">Home Insurance</option>
          <option value="auto">Auto Insurance</option>
          <option value="homeSecurity">Home Security</option>
          <option value="electricity">Electricity</option>
        </select>
      </div>
      <div class="input-group component textbox">
        <div class="form-label-group">
          <textarea
            id="inputSubmitFeedback"
            class="form-control"
            placeholder="Add more details regarding this issue..."
            [(ngModel)]="feedbackInput"
            cols="30"
            rows="4"
            autofocus
          ></textarea>
        </div>
      </div>
    </div>
<div class="alert-class">{{tktErr}}</div>
    <div class="cta">
      <button
        type="button"
        class="btn {{ (feedbackInput ) ? 'btn-primary' : 'btn-disabled' }} mx-auto"
        [disabled]="!feedbackInput "
        (click)="submitCta()"
      >
        CONTINUE
      </button>
    </div>
  </div>

  <div
    id="page-ticketRaised"
    *ngIf="page.name === 'ticketRaised'"
    class="page"
    [@enterAnimation]
  >
    <div class="title">Thank you.</div>
    <div class="font-20 text-center m-3">
      I’ve send you a text with the ticket number.<br />
      Please give us 24 hrs to address your concern.
    </div>

    <div class="asset asset-top">
      <img src="../../../../assets/images/fb/billsuccess_2.svg" />
    </div>

    <div class="asset asset-2 d-none"></div>
    <div class="page-form text-center mt-5 mb-4">
      <div>Ticket Number: {{ ticketNumber }}</div>
    </div>

    <div class="cta">
      <button
        type="button"
        class="btn btn-primary mx-auto"
        (click)="submitCta()"
      >
        back to dashboard
      </button>
    </div>
  </div>
</div>
