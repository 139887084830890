import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const loadScripts = script => {
  const s = document.createElement('script');
  s.src = script;
  s.async = true;
  s.defer = true;
  document.body.appendChild(s);
};
setTimeout(() => {
  if (environment.production) {
    enableProdMode();
    // Add Pixels
    // setTimeout(() => {
    // loadScripts('assets/js/pixels/google-tag.js');
    // loadScripts('assets/js/pixels/frontapp.js');
    loadScripts('assets/js/pixels/facebook.js');
    loadScripts('assets/js/pixels/smartlook.js');
    loadScripts('assets/js/pixels/clarity.js');
    loadScripts('assets/js/pixels/taboola.js');
    loadScripts('assets/js/pixels/active_campaign.js');
    loadScripts('assets/js/pixels/mixpanel.js');
    loadScripts('https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js');
    loadScripts('https://js.stripe.com/v3/');
    loadScripts('https://cdn.jsdelivr.net/npm/canvas-confetti@1.4.0/dist/confetti.browser.min.js');
    // loadScripts('assets/js/pixels/moengage.js');
    // }, 5000);
    // loadScripts('https://js.stripe.com/v3/');
    // loadScripts('https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.js');
    if (window) {
      window.console.log = function() {}; // block all console logs in prod
    }
  } else {
    // loadScripts('assets/js/pixels/facebook.js');
    loadScripts('https://js.stripe.com/v3/');
    loadScripts('https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js');
    // loadScripts('assets/js/pixels/active_campaign.js');
    // loadScripts('assets/js/pixels/moengage.js');
    // loadScripts('https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.js');
    loadScripts('https://cdn.jsdelivr.net/npm/canvas-confetti@1.4.0/dist/confetti.browser.min.js');
    loadScripts('assets/js/pixels/clarity-dev.js');
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}, 2000);
